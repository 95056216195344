import { z } from 'zod';

export const StoreSchema = z.object({
  address: z.string(),
  country_code: z.string(),
  id: z.number(),
  name: z.string(),
  recreational: z.boolean(),
  state: z.string(),
});

const MetaDataSchema = z.object({
  number_of_pages: z.number(),
  page: z.number(),
  page_total: z.number(),
  per_page: z.number(),
  total: z.number(),
});

export const FetchJamStoresResponseSchema = z.object({
  meta: MetaDataSchema,
  stores: z.array(StoreSchema),
});

export type FetchJamStoresResponse = z.infer<
  typeof FetchJamStoresResponseSchema
>;

export type JamStore = z.infer<typeof StoreSchema>;

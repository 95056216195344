const COUNTRY = 'en-US';

export const formatCurrency = (value: number, significantDigits = 2) =>
  new Intl.NumberFormat(COUNTRY, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: significantDigits,
    minimumFractionDigits: significantDigits,
  }).format(value);

export const formatPercent = (value: number, significantDigits = 2) =>
  new Intl.NumberFormat(COUNTRY, {
    style: 'percent',
    maximumFractionDigits: significantDigits,
    minimumFractionDigits: significantDigits,
  }).format(value);

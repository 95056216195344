import { localizations } from '@iheartjane/jane-iso-3166';

interface LocalizationOption {
  label: string;
  value: string;
}

interface Subdivision {
  category: string;
  code: string;
  displayName: {
    [key: string]: string;
  };
}

const US_LOCALIZATION_OPTIONS =
  countrySubdivisionsToOptions('US').sort(sortByLabel);

const CA_LOCALIZATION_OPTIONS =
  countrySubdivisionsToOptions('CA').sort(sortByLabel);

export const LOCALIZATION_OPTIONS: LocalizationOption[] = [
  ...US_LOCALIZATION_OPTIONS,
  ...CA_LOCALIZATION_OPTIONS,
];

/**
 * Given a country code this will return an array of LocalizationOptions if
 * that country is supported. If the country code is not supported it will throw
 * an error.
 */
function countrySubdivisionsToOptions(countryCode: string) {
  const countrySubdivisions =
    localizations.subdivisions[countryCode.toLowerCase()];

  if (countrySubdivisions === undefined) {
    throw new Error(
      `The country code "${countryCode}" is not supported. Supported countries are found in the jane-iso-3166 package.`
    );
  }

  return countrySubdivisions.map((subdivision) =>
    subdivisionToOption(subdivision, countryCode)
  );
}

/**
 * Takes a subdivision and country code and returns a LocalizationOption with
 * the subdivisions display name as the `label` prop and the country code &
 * subdivision code concatenated as the value. The value will be in the format
 * XX-YY where XX is the uppercase two letter country code and YY will be the
 * uppercase two letter subdivision code.
 */
function subdivisionToOption(
  subdivision: Subdivision,
  countryCode: string
): LocalizationOption {
  return {
    label: subdivision.displayName['en'],
    value: countryCode.toUpperCase() + '-' + subdivision.code.toUpperCase(),
  };
}

/**
 * Sort function that will sort in ascending alphabetical order based on the item.label
 */
function sortByLabel(a: LocalizationOption, b: LocalizationOption) {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();

  if (labelA < labelB) return -1;
  if (labelB > labelA) return 1;
  return 0;
}

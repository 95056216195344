import { Flex, Link, Typography } from '@jane/shared/reefer';

import { TitleValuePair } from '../TitleValuePair';

export interface ProductNoticeSummaryProps {
  activeProduct?: { id: number; name: string };
  comment?: string;
  handleCancelReport: () => void;
  reportedTime: string;
  status: string;
}

export const ProductNoticeSummary = ({
  activeProduct,
  comment,
  handleCancelReport,
  reportedTime,
  status,
}: ProductNoticeSummaryProps) => {
  return (
    <Flex flexDirection="column" gap={24}>
      <Typography as="h4" variant="body-bold">
        Report summary
      </Typography>
      <Flex flexDirection="row">
        <TitleValuePair title="Product status" value={status} />
      </Flex>
      <Flex flexDirection="row">
        <TitleValuePair title="Reported on" value={formatDate(reportedTime)} />
        {activeProduct && status.match(/duplicate/i) ? (
          <TitleValuePair
            title="Primary product duplicate"
            value={`${activeProduct.name} (#${activeProduct.id})`}
          />
        ) : null}
      </Flex>
      {comment && (
        <Flex flexDirection="row">
          <TitleValuePair title="Comment" value={comment} />
        </Flex>
      )}
      <Flex flexDirection="row">
        <Link onClick={handleCancelReport}>Cancel report</Link>
      </Flex>
    </Flex>
  );
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(date);
};

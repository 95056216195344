import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { Weekday, WeekdayMin } from '@jane/ad-manager/types';

export const PACIFIC_TIMEZONE = 'America/Los_Angeles';
export const UTC_TIMEZONE = 'UTC';
const DATEPICKER_DATE_FORMAT = 'YYYY-MM-DD';
export const RFC3339_FORMAT_STRING = 'YYYY-MM-DDTHH:mm:ss[Z]' as const;
const TIME_FORMAT = 'hh:mm:ss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// last monday start
export const weekStart = () => {
  const currentTime = nowInPacificTime();

  if (currentTime.day() === 0) {
    return currentTime // If it's Sunday...
      .subtract(1, 'week')
      .startOf('week')
      .add(1, 'day'); // ...return previous monday
  }
  // Otherwise return this week's monday
  return currentTime.startOf('week').add(1, 'day');
};
export const monthStart = () => nowInPacificTime().startOf('month');

export const nowInPacificTime = () => dayjs().tz(PACIFIC_TIMEZONE);

export const nowInUTC = () => dayjs().tz(UTC_TIMEZONE);

export const parseUTCDateTimeInPT = (dateTimeString: string) =>
  dayjs.tz(dateTimeString, UTC_TIMEZONE).tz(PACIFIC_TIMEZONE);

export const parsePacificTime = (timeString: string) =>
  dayjs.tz(timeString, TIME_FORMAT, PACIFIC_TIMEZONE);

export const parsePacificDate = (dateString: string) =>
  dayjs.tz(dateString, DATEPICKER_DATE_FORMAT, PACIFIC_TIMEZONE);

export const formatPacificTimeToUtcEod = (dateString: string) =>
  dayjs
    .tz(dateString, PACIFIC_TIMEZONE)
    .endOf('day')
    .endOf('second')
    .tz(UTC_TIMEZONE, false)
    .format(RFC3339_FORMAT_STRING);

export const formatPacificTimeToUtc = (dateString: string) =>
  dayjs
    .tz(dateString, PACIFIC_TIMEZONE)
    .tz(UTC_TIMEZONE, false)
    .format(RFC3339_FORMAT_STRING);

export const formatDatepickerString = (time: Dayjs) =>
  time.format(DATEPICKER_DATE_FORMAT);

export const isDatepickerDateStringValid = (dateString: string | null) =>
  dayjs(dateString, DATEPICKER_DATE_FORMAT, true).isValid();

const WEEKDAY_FORMAT_STRING = 'dddd';
export const dayNameFromIndex = (index: number) =>
  dayjs().day(index).format(WEEKDAY_FORMAT_STRING) as Weekday;
const WEEKDAY_ABBREVIATION_STRING = 'dd';
export const dayAbbreviationFromIndex = (index: number) =>
  dayjs()
    .day(index)
    .format(WEEKDAY_ABBREVIATION_STRING)
    .toLocaleUpperCase() as WeekdayMin;

// end of day in pacific time
export const endOfDayInPacificTime = () =>
  nowInPacificTime().endOf('day').endOf('second');

// end of day for pacific time formatted for utc timezone
export const endOfDayInPacificTimeWithUTCTimezone = () =>
  endOfDayInPacificTime().tz(UTC_TIMEZONE, false).format(RFC3339_FORMAT_STRING);

export const isFutureDate = (dateString: string) => {
  const date = dayjs(dateString, DATEPICKER_DATE_FORMAT);
  const now = nowInUTC().format(DATEPICKER_DATE_FORMAT);
  return date.isAfter(now);
};

export const isPastDate = (dateString: string) => {
  const date = dayjs(dateString, DATEPICKER_DATE_FORMAT);
  const now = nowInUTC().format(DATEPICKER_DATE_FORMAT);
  return date.isBefore(now);
};

export const isToday = (dateString: string) => {
  const date = dayjs(dateString, DATEPICKER_DATE_FORMAT);
  const today = nowInUTC().format(DATEPICKER_DATE_FORMAT);
  return date.isSame(today, 'day');
};

export const getFutureDate = (additionalDay: number) => {
  if (additionalDay < 1) return nowInUTC().format(DATEPICKER_DATE_FORMAT);
  return nowInUTC().add(additionalDay, 'day').format(DATEPICKER_DATE_FORMAT);
};

import type { ProductsSearchItem } from '@jane/catalog-cms/data-access';
import { Box, Button, Flex, Image, Typography } from '@jane/shared/reefer';

export interface DuplicateProductPreviewProps {
  /** Function that will clear the selected product */
  clearSelected: () => void;
  product: Pick<ProductsSearchItem, 'imageUrl' | 'name' | 'brand' | 'category'>;
}

export const DuplicateProductPreview = ({
  product,
  clearSelected,
}: DuplicateProductPreviewProps) => {
  return (
    <Flex gap={24} mb={24} alignItems={'center'}>
      <Box>
        <Image
          borderRadius="12px"
          src={product.imageUrl || ''}
          width="64px"
          height="64px"
          border
          altText={product.name}
        />
      </Box>
      <Box width={'100%'}>
        <Flex gap={24} alignItems={'center'}>
          <Flex basis="33.3333%">
            <Typography variant={'body-bold'}>{product.name}</Typography>
          </Flex>
          <Flex basis="33.3333%">
            <Typography>{product.brand}</Typography>
          </Flex>
          <Flex basis="33.3333%">
            <Typography>{product.category}</Typography>
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Button variant="secondary" label="Change" onClick={clearSelected} />
      </Box>
    </Flex>
  );
};

import styled from '@emotion/styled';
import { useState } from 'react';

import { Hide } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { CategoryCheckbox } from './CategoryCheckbox';
import type { CategoryOption } from './CategorySelectInputModal';

const CategoryDivider = styled.hr(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.colors.grays.light,
  height: 1,
  margin: '10px 0',
}));

export const StyledCategoryList = styled.ul<{
  query?: string;
  subList?: boolean;
}>(({ theme, query, subList }) => ({
  maxHeight: '360px',
  overflow: 'auto',
  position: 'relative',
  paddingLeft: '20px',
  '> li:first-of-type > div > div > div': {
    ...spacing({ pt: subList || query ? 0 : 12 }),
  },
  '> li:first-of-type > div > span': {
    top: '24px',
  },
  '> li:first-of-type > button': {
    ...spacing({ pt: 20 }),
  },
  '> li:last-of-type > button': {
    ...spacing({ pb: 20 }),
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '37px',
    top: '12px',
    bottom: '12px',
    width: '1px',
    backgroundColor: theme.colors.grays.light,
  },
}));

export const CategoryList = ({
  category,
  selectedCategories,
  onChange,
  query,
  isLastCategory,
}: {
  category: CategoryOption;
  isLastCategory: boolean;
  onChange: (value: string) => void;
  query: string;
  selectedCategories: CategoryOption[];
}) => {
  const { label, subItems, value } = category;
  const [showSubItems, setShowSubItems] = useState(true);
  const hasSubItems = subItems && subItems.length > 0;

  const isIndeterminate = subItems
    ?.map((subItem: CategoryOption) =>
      selectedCategories.some(
        (category: CategoryOption) => category.value === subItem.value
      )
    )
    .includes(true);

  const isTopLevelChecked =
    hasSubItems &&
    selectedCategories.some(
      (category: CategoryOption) => category.value === value
    );

  const renderedItem = (children?: React.ReactNode) => {
    return (
      <>
        <CategoryCheckbox
          category={category}
          children={children}
          onChange={onChange}
          selectedCategories={selectedCategories}
          hasSubItems={hasSubItems}
          indeterminate={isIndeterminate}
          showSubcategories={showSubItems}
          setShowSubcategories={setShowSubItems}
          subCategoryValues={subItems}
        />
        {!isLastCategory && <CategoryDivider />}
      </>
    );
  };

  const renderedSubItems = subItems?.map((item: CategoryOption) => {
    return (
      <CategoryCheckbox
        key={item.label}
        isParentCategoryChecked={isTopLevelChecked}
        category={item}
        onChange={onChange}
        isSubItem
        parentCategoryValue={value}
        selectedCategories={selectedCategories}
      />
    );
  });

  if (hasSubItems) {
    return renderedItem(
      <Hide isHidden={!showSubItems}>
        <StyledCategoryList
          aria-label={`${label}: sub items`}
          query={query}
          subList
        >
          {renderedSubItems}
        </StyledCategoryList>
      </Hide>
    );
  }

  return renderedItem();
};

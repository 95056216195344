import type {
  BrandTheme,
  CustomImage,
  CustomVideo,
} from '@jane/ad-manager/types';
import { isAssetIdTemporary } from '@jane/ad-manager/util';
import type { Brand } from '@jane/shared/models';
import { parseData } from '@jane/shared/util';

import type {
  ApiBrandThemeAndMediaTagsIncoming,
  ApiBrandThemeIncoming,
  ApiBrandThemeOutgoing,
  ApiCustomImageIncoming,
  ApiCustomImageOutgoing,
  ApiCustomVideoIncoming,
  ApiCustomVideoOutgoing,
} from './types';
import {
  ApiBrandThemeIncomingSchema,
  ApiBrandThemeOutgoingSchema,
} from './types';

const customVideoFromApi = (fromApi: ApiCustomVideoIncoming): CustomVideo => ({
  id: fromApi.id,
  brandMediaTags: fromApi.brand_media_tags ?? [],
  overlayImageUrl: fromApi.overlay_image_url,
  overlayText: fromApi.overlay_text ?? undefined,
  position: fromApi.position ?? 0,
  videoUrl: fromApi.video_url,
  _mediaType: 'video',
});

const customVideoToApi = ({
  toApi,
  creatingAsset,
}: {
  creatingAsset: boolean;
  toApi: CustomVideo;
}): ApiCustomVideoOutgoing => {
  return {
    id: creatingAsset ? null : toApi.id,
    brand_media_tags: toApi.brandMediaTags,
    overlay_image_url: toApi.overlayImageUrl,
    overlay_text: toApi.overlayText ?? null,
    position: toApi.position,
    video_url: toApi._destroy ? 'DELETED' : toApi.videoUrl,
    _destroy: toApi._destroy || false,
  };
};

const customImageFromApi = (
  fromApi: ApiCustomImageIncoming,
  incrementPosition: boolean
): CustomImage => ({
  brandMediaTags: fromApi.brand_media_tags ?? [],
  ctaText: fromApi.cta_text ?? undefined,
  ctaUrl: fromApi.cta_url ?? undefined,
  description: fromApi.description ?? undefined,
  id: fromApi.id ?? undefined,
  imageUrl: fromApi.image_url,
  position: incrementPosition ? fromApi.position + 1 : fromApi.position,
  _mediaType: 'image',
});

const customImageToApi = ({
  toApi,
  creatingAsset,
}: {
  creatingAsset: boolean;
  toApi: CustomImage;
}): ApiCustomImageOutgoing => {
  return {
    brand_media_tags: toApi.brandMediaTags,
    cta_text: toApi.ctaText ?? null,
    cta_url: toApi.ctaUrl ?? null,
    description: toApi.description ?? null,
    id: creatingAsset ? null : toApi.id,
    image_url: toApi.imageUrl,
    position: toApi.position,
    _destroy: toApi._destroy || false,
  };
};

const lintMediaPosition = (
  medias: (CustomImage | CustomVideo | undefined)[]
) => {
  medias
    .filter((media): media is CustomImage | CustomVideo => !!media)
    .sort((i, j) => i.position - j.position)
    .forEach((media, index) => {
      media.position = index;
    });
};

export const fromApi = (fromApi: ApiBrandThemeIncoming): BrandTheme => {
  parseData(ApiBrandThemeIncomingSchema, fromApi);

  // if pbt_custom_video is present but has no position, assign it 0 and increment image positions
  const isCustomVideoPositionNull = Boolean(
    fromApi.pbt_custom_video && fromApi.pbt_custom_video.position == null
  );

  const customImages = fromApi.pbt_custom_images.map((customImage) =>
    customImageFromApi(customImage, isCustomVideoPositionNull)
  );
  const customVideo = fromApi.pbt_custom_video
    ? customVideoFromApi(fromApi.pbt_custom_video)
    : undefined;

  lintMediaPosition([...customImages, customVideo]);

  return {
    colorHex: fromApi.color_hex ?? '#ffffff',
    customImageUrl: fromApi.custom_image_url ?? undefined,
    customImages,
    customVideo,
    description: fromApi.description ?? undefined,
    draft: fromApi.draft,
    logoUrl: fromApi.logo_url ?? undefined,
    productBrandId: fromApi.product_brand_id,
    productBrandName: fromApi.product_brand.name,
    productBrandNormalizedName:
      fromApi.product_brand.normalized_name ?? undefined,
    rank: fromApi.rank || undefined,
    updatedAt: new Date(fromApi.updated_at),
  };
};

export const fromApiWithMediaTags = (
  fromApiWithMediaTags: ApiBrandThemeAndMediaTagsIncoming
) => {
  return {
    productBrandTheme: fromApi(fromApiWithMediaTags.product_brand_theme),
    mediaTags: fromApiWithMediaTags.media_tags,
  };
};

export const toApi = (toApi: BrandTheme): ApiBrandThemeOutgoing => {
  const outgoing: ApiBrandThemeOutgoing = {
    color_hex: toApi.colorHex,
    custom_image_url: toApi.customImageUrl ?? null,
    description: toApi.description ?? null,
    logo_url: toApi.logoUrl ?? null,
    pbt_custom_images_attributes: toApi.customImages.map((customImage) =>
      customImageToApi({
        toApi: customImage,
        creatingAsset: !toApi.draft || isAssetIdTemporary(customImage.id),
      })
    ),
    pbt_custom_video_attributes: toApi.customVideo
      ? customVideoToApi({
          toApi: toApi.customVideo,
          creatingAsset:
            !toApi.draft || isAssetIdTemporary(toApi.customVideo.id),
        })
      : null,
    product_brand_id: toApi.productBrandId,
    rank: toApi.rank || null,
  };
  return parseData(ApiBrandThemeOutgoingSchema, outgoing);
};

export const toBrandPageApi = (toBrandPageApi: BrandTheme): Brand => {
  return {
    color_hex: toBrandPageApi.colorHex ?? null,
    custom_image_url: toBrandPageApi.customImageUrl ?? null,
    description: toBrandPageApi.description ?? null,
    logo_url: toBrandPageApi.logoUrl ?? null,
    custom_images: toBrandPageApi.customImages.map((customImage) =>
      customImageToApi({ toApi: customImage, creatingAsset: false })
    ),
    custom_video: toBrandPageApi.customVideo
      ? customVideoToApi({
          toApi: toBrandPageApi.customVideo,
          creatingAsset: false,
        })
      : null,
    id: toBrandPageApi.productBrandId,
    name: toBrandPageApi.productBrandName,
    brand_media_tags: [],
  };
};

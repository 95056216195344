import type { CustomThemeConfig } from '@jane/shared/reefer';
import { BASE_CONFIG } from '@jane/shared/reefer';

export const BRAND_PORTAL_THEME: CustomThemeConfig = {
  borderRadius: {
    sm: '12px',
  },
  components: {
    Button: {
      shared: { borderRadius: 'sm' },
      variants: {
        tertiary: {
          backgroundColor: 'grays-ultralight',
          borderColor: 'grays-ultralight',
          typographyColor: 'grays-black',
          typographyVariant: 'body-bold',
        },
      },
    },
    Card: {
      styles: {
        borderRadius: 'lg',
      },
    },
    Typography: {
      variants: {
        'header-bold': {
          ...BASE_CONFIG.components.Typography.variants['header-bold'],
          branded: true,
        },
      },
      fonts: {},
    },
  },
};

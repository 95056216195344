import { z } from 'zod';

import { PLACEMENTS_TO_ZONES_MAP } from '@jane/ad-manager/util';

export const AdSubmissionSchema = z.object({
  details: z.object({
    adPlacement: z.array(z.string()),
    adType: z.string(),
    menuRowTitle: z.string(),
    displayPlatforms: z.string(),
  }),
  pricing: z.object({
    isPriority: z.boolean(),
    budget: z.number(),
    cpmBid: z.number(),
    cpcBid: z.number(),
  }),
  products: z.object({
    categories: z.optional(z.array(z.string())),
    productIds: z.optional(z.array(z.string())),
  }),
  schedule: z.object({
    startDate: z.string(),
    endDate: z.string(),
    dayParting: z.optional(
      z.record(
        z.object({
          endTime: z.string(),
          startTime: z.string(),
        })
      )
    ),
    impressions: z.number(),
    timePeriod: z.number(),
    timeUnit: z.string(),
  }),
  targeting: z.object({
    customerSegments: z.optional(z.array(z.string())),
    states: z.optional(z.array(z.string())),
    storeIds: z.optional(z.array(z.string())),
    excludedStoreIds: z.optional(z.array(z.string())),
  }),
});

export type AdSubmission = z.infer<typeof AdSubmissionSchema>;

export const JamApiBidModifierSchema = z.object({
  match: z.object({
    zone: z.nativeEnum(PLACEMENTS_TO_ZONES_MAP),
  }),
  multiplier: z.number().optional(),
  price: z.number().optional(),
});

export type JamApiBidModifier = z.infer<typeof JamApiBidModifierSchema>;

export const JamApiAdSchema = z.object({
  ad_model: z.enum(['flat', 'cpc', 'cpm']),
  ad_type: z.enum(['priority', 'programmatic']),
  bid: z.number().nullable(),
  bidModifiers: z.array(JamApiBidModifierSchema),
  budget: z.number(),
  categories: z.array(z.string()).nullable(),
  credit_percent: z.number(),
  days: z
    .array(
      z.object({
        end_time: z.string().nullable(),
        start_time: z.string().nullable(),
        weekdays: z.array(z.string()).nullable(),
      })
    )
    .nullable(),
  end_date: z.string().datetime(),
  excluded_stores: z.array(z.number()).nullable(),
  impressions: z.number().nullable(),
  platforms: z.array(z.string()).nullable(),
  products: z.array(z.number()).nullable(),
  segment_id: z.number().nullable(),
  start_date: z.string().datetime(),
  states: z.array(z.string()).nullable(),
  stores: z.array(z.number()).nullable(),
  time_period: z.number().nullable(),
  time_unit: z
    .union([z.literal(0), z.literal(1), z.literal(2), z.literal(3)])
    .nullable(),
  title: z.string().nullable(),
  zones: z.array(z.string()).nullable(),
});

export type JamApiAd = z.infer<typeof JamApiAdSchema>;

export interface JamApiError {
  message: string;
}

import styled from '@emotion/styled';

import type { ProductBrand } from '@jane/ad-manager/types';
import { Flex, PartnerLogo, Typography } from '@jane/shared/reefer';

const Container = styled.div(({ theme }) => ({
  cursor: 'pointer',
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
}));

interface BrandSearchRowProps {
  brand: ProductBrand;
  isSelected: boolean;
  selectBrand: (brand: ProductBrand) => void;
}
export const BrandSearchRow = ({
  brand,
  selectBrand,
  isSelected,
}: BrandSearchRowProps) => {
  return (
    <Container
      aria-label={`set brand to ${brand.name}`}
      onClick={() => selectBrand(brand)}
      role="button"
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        py={16}
      >
        <Flex flexDirection="row" alignItems="center" gap={16}>
          <PartnerLogo
            name={brand.name}
            variant="brand"
            size="sm"
            image={brand.logoUrl}
          />
          <Typography variant="body-bold">{brand.name}</Typography>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          {isSelected && (
            <Typography variant="body-bold" color="purple">
              Selected
            </Typography>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

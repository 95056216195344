import flatten from 'lodash/flatten';
import zip from 'lodash/zip';

import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { Flex, Typography } from '@jane/shared/reefer';

export type CategoryDetailsProps = Pick<
  EditFormProduct,
  'category' | 'subcategory' | 'categoryLabel'
>;

export const CategoryDetails = ({
  category,
  subcategory,
  categoryLabel,
}: CategoryDetailsProps) => {
  // category, subcategory and categoryLabel are all optional we need to build
  // the '/' separated list of categories with any combination of properties
  // being present without extra separators.

  const details = [category, subcategory, categoryLabel]
    .filter((el) => typeof el === 'string')
    .map((str, i) => <Typography key={'detail-' + i}>{str}</Typography>);

  const dividers = Array.from({ length: details.length - 1 }).map((_el, i) => {
    return (
      <Typography color="grays-light" key={'divider-' + i}>
        /
      </Typography>
    );
  });

  const categoryDetails = flatten(zip(details, dividers));
  return (
    <Flex flexDirection={'row'} gap={8}>
      {categoryDetails}
    </Flex>
  );
};

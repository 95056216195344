// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kjXV9Fi1PVwnJkLLarJ0 {\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n.kjXV9Fi1PVwnJkLLarJ0 li {\n  list-style: none;\n  cursor: inherit;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/breadcrumbs/breadcrumbs.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".breadcrumbs {\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n.breadcrumbs li {\n  list-style: none;\n  cursor: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "kjXV9Fi1PVwnJkLLarJ0"
};
export default ___CSS_LOADER_EXPORT___;

import styled from '@emotion/styled';

import {
  Button,
  Card,
  Flex,
  Image,
  SearchIcon,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const CARDS = 6;
const IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO89R8AArkB2ygWfsQAAAAASUVORK5CYII=';

const StyledMenuContainer = styled.div<{ forceMobile: boolean }>(
  ({ forceMobile }) => ({
    ...spacing({ mt: forceMobile ? -72 : -32 }),
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: forceMobile ? '-24px 0 0 0' : '0 auto',
  })
);

const StyledFilterContainer = styled(Flex)<{ forceMobile: boolean }>(
  ({ forceMobile }) => ({
    '& div': {
      fontSize: forceMobile ? '15px' : undefined,
    },
  })
);

const StyledMenuWrapper = styled(Flex)<{ forceMobile: boolean }>(
  ({ forceMobile }) => ({
    overflow: 'hidden',
    width: forceMobile ? '100%' : '80%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginLeft: forceMobile ? '24px' : undefined,
    marginRight: forceMobile ? '24px' : undefined,
  })
);

const StyledGridCard = styled(Card)({
  maxWidth: '245px',
  minWidth: '150px',
  flex: '1 1 30%',
});

const StyledListCard = styled(Flex)({
  width: '100%',
  flexWrap: 'wrap',
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
});

const StyledBtn = styled(Button)({
  background: '#f7f7f7',
  color: 'black',
  opacity: 1,
  flexShrink: 0,
});

const StyledMobileMenuBtn = styled(Button)({
  ...spacing({ mt: 20, mb: 24 }),
  background: '#f7f7f7',
  color: 'black',
  opacity: 1,
});

const StyledBtnIcon = styled(Button.Icon)({
  background: '#f7f7f7',
  color: 'black',
  opacity: 1,
});

const StyledHeader = styled(Typography)<{ forceMobile: boolean }>(
  ({ forceMobile }) => ({
    '&&': {
      fontSize: forceMobile ? '20px' : '24px',
    },
  })
);

const StyledAllCardsWrapper = styled(Flex)({
  ...spacing({ mb: 40, pl: 2, pr: 2 }),
  width: '100%',
  flexWrap: 'wrap',
  columnGap: '24px',
  rowGap: '24px',
});

const allProductsHeader = (forceMobile: boolean) => (
  <StyledHeader variant="header-bold" as="h3" mt={4} forceMobile={forceMobile}>
    All products
  </StyledHeader>
);

const allProductsFilter = (forceMobile: boolean) => (
  <StyledFilterContainer forceMobile={forceMobile}>
    <StyledBtnIcon
      icon={<SearchIcon />}
      variant="tertiary"
      mr={16}
      disabled={true}
    />
    <StyledBtn label="Filter" variant="tertiary" mr={16} disabled={true} />
    <StyledBtn label="Filter" variant="tertiary" mr={16} disabled={true} />
    <StyledBtn label="Filter" variant="tertiary" mr={16} disabled={true} />
  </StyledFilterContainer>
);

const desktopProductCard = (index: number) => (
  <StyledGridCard key={index}>
    <Card.Image alt="card-image" src={IMAGE} />
    <Card.Content>
      <Skeleton>
        <Skeleton.Bone mt={4} mb={4} height="16px" width="60%" />
        <Skeleton.Bone mt={4} mb={4} height="16px" />
        <Skeleton.Bone mt={4} mb={4} height="16px" width="70%" />
        <Skeleton.Bone mt={4} mb={4} height="16px" width="60%" />
      </Skeleton>
    </Card.Content>
  </StyledGridCard>
);

const mobileProductCard = (index: number) => (
  <StyledListCard key={index}>
    <Image
      altText="card-image"
      src={IMAGE}
      width="96px"
      height="96px"
      borderRadius="rounded"
    />
    <Flex grow={1} pl={16}>
      <Skeleton>
        <Skeleton.Bone mt={4} mb={4} height="16px" width="60%" />
        <Skeleton.Bone mt={4} mb={4} height="16px" width="70%" />
        <Skeleton.Bone mt={4} mb={4} height="16px" />
        <Skeleton.Bone mt={4} mb={4} height="16px" width="70%" />
      </Skeleton>
    </Flex>
    <Flex width="100%">
      <StyledMobileMenuBtn disabled={true} variant="tertiary" full={true} />
    </Flex>
  </StyledListCard>
);

export const SkeletonMenu = ({ forceMobile }: { forceMobile: boolean }) => {
  return (
    <StyledMenuContainer forceMobile={forceMobile}>
      <StyledMenuWrapper forceMobile={forceMobile}>
        <Flex flexDirection="column">
          <Flex mb={24}>
            {forceMobile
              ? allProductsFilter(forceMobile)
              : allProductsHeader(forceMobile)}
          </Flex>
          <Flex mb={24}>
            {forceMobile
              ? allProductsHeader(forceMobile)
              : allProductsFilter(forceMobile)}
          </Flex>
        </Flex>
        <StyledAllCardsWrapper>
          {[...Array(CARDS).fill(1)].map((_, i) =>
            forceMobile ? mobileProductCard(i) : desktopProductCard(i)
          )}
        </StyledAllCardsWrapper>
      </StyledMenuWrapper>
    </StyledMenuContainer>
  );
};

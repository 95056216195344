import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { hidden } from '@jane/shared/reefer-emotion';

const Hidden = styled.div(() => ({ ...hidden(true) }));

export const SrOnly = ({
  as,
  children,
}: {
  as: React.ElementType | keyof JSX.IntrinsicElements;
  children: ReactNode;
}) => {
  return <Hidden as={as}>{children}</Hidden>;
};

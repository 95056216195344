import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  scheduleForWeek,
  useGetFlightDetail,
  useUpdateFlight,
} from '@jane/ad-manager/data-access';
import type { DayPart, Weekday } from '@jane/ad-manager/types';
import {
  endOfDayInPacificTimeWithUTCTimezone,
  parsePacificTime,
  parseUTCDateTimeInPT,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Loading,
  Tag,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { useNavigateToAdBuilder } from './AdBuilder/AdBuilder';
import { transformBidModifiersFromApi } from './AdBuilder/Pricing/BidModifiers';
import {
  ORDERED_DAYS,
  WEEKDAY_TO_WEEKDAY_MIN,
} from './AdBuilder/Scheduling/constants';
import {
  PricingDetails,
  ProductDetails,
  ScheduleDetails,
  TargetingDetails,
  TypeDetails,
} from './FlightDetails';
import { StatisticsDisplay } from './StatisticsDisplay';

export const FlightDetail = () => {
  const { id } = useParams();
  const toast = useToast();
  const flightId = Number(id) || undefined;

  const { data: getFlightResult, isLoading } = useGetFlightDetail(
    flightId ? { flightId } : undefined
  );

  const {
    data: updatedFlight,
    isLoading: isActiveToggleLoading,
    mutate: mutateFlight,
    error: updateError,
    isError: isUpdateError,
  } = useUpdateFlight();
  useEffect(() => {
    if (isUpdateError) {
      toast.add({
        label: `Failed to update flight: ${updateError.message}`,
        variant: 'error',
      });
    }
  });
  const handleClickActiveToggle = () => {
    if (flightId === undefined) {
      return;
    }
    const endDate = endOfDayInPacificTimeWithUTCTimezone();
    mutateFlight({
      id: flightId,
      is_active: !flight?.active,
      end_date: endDate,
      is_read_only: !!flight?.active,
    });
  };
  const flight = updatedFlight || getFlightResult;

  const storeDisplayFromEntry = ({
    description,
    id,
  }: {
    description: string;
    id: number;
  }) => ({
    id: id.toString(),
    name: description,
  });

  const formatISODate = (isoDate: string) =>
    parseUTCDateTimeInPT(isoDate).format('M/D/YYYY');

  const timeFormat = 'h:mm a';

  const START_OF_DAY = '00:00:00';
  const END_OF_DAY = '23:59:00';
  const isDayPartAllDay = (dayPart: DayPart) =>
    dayPart.startTime === START_OF_DAY &&
    (dayPart.endTime === START_OF_DAY || dayPart.endTime === END_OF_DAY);

  const dayParts = flight?.schedule;
  const schedule = dayParts ? scheduleForWeek(dayParts) : {};
  const manualDays =
    dayParts && dayParts.length > 0
      ? ORDERED_DAYS.map((dayName) => {
          // start with monday, end with sunday
          const dayAbbreviation = WEEKDAY_TO_WEEKDAY_MIN[dayName];
          const scheduleForDay = schedule[dayAbbreviation];
          if (!scheduleForDay) {
            return undefined;
          }
          if (isDayPartAllDay(scheduleForDay)) {
            return { name: dayName, value: 'All day' };
          }
          return {
            name: dayName,
            value: `${parsePacificTime(scheduleForDay.startTime).format(
              timeFormat
            )} - ${parsePacificTime(scheduleForDay.endTime).format(
              timeFormat
            )}`,
          };
        }).filter((item): item is { name: Weekday; value: string } => !!item)
      : undefined;
  const navigateToAdBuilder = useNavigateToAdBuilder();

  const onDuplicate = () => {
    if (!flight) return;

    navigateToAdBuilder({ adBuilderPrefill: flight.id });
  };

  const bidModifiers = transformBidModifiersFromApi(flight?.bidModifiers);

  const brandFlightsStatistics = [
    {
      label: 'ROAS',
      valueDisplay: formatCurrency(flight ? flight.roas : 0),
    },
    {
      label: 'Clicks',
      valueDisplay: flight ? flight.clicks : 0,
    },
    {
      label: 'Customers Impressed',
      valueDisplay: flight ? flight.customersImpressed : 0,
    },
    {
      label: 'Total billable',
      valueDisplay: formatCurrency(flight ? flight.totalBillable : 0),
    },
    {
      label: 'Unique stores',
      valueDisplay: flight ? flight.uniqueStores : 0,
    },
  ];

  return (
    <>
      {isLoading && <Loading color="purple" />}
      {flight && (
        <Flex flexDirection="column" gap={24} mx={40} mb={40}>
          <Box mt={40}>
            <Breadcrumbs>
              <Link to={brandsPaths.digitalMerchandising()}>All flights</Link>
              <Typography>Flight {flightId}</Typography>
            </Breadcrumbs>
          </Box>
          <Flex mt={40}>
            <Box>
              <Flex alignItems="center">
                <Typography variant="header-bold" as="h1">
                  Flight {flightId}
                </Typography>
                <Box ml={8}>
                  {flight.active && (
                    <Tag background="palm-light" color="palm" label="Active" />
                  )}
                  {!flight.active && (
                    <Tag
                      background="grays-light"
                      color="grays-dark"
                      label="Inactive"
                    />
                  )}
                </Box>
              </Flex>
              <Typography data-testid="header-flight-dates" color="text-light">
                {formatISODate(flight.start)} – {formatISODate(flight.end)}
              </Typography>
              <Typography color="text-light">
                Last updated by {flight.editedBy.email} on{' '}
                {formatISODate(flight.updatedAt)}
              </Typography>
            </Box>
            <Flex ml="auto">
              <Button
                onClick={() => handleClickActiveToggle()}
                loading={isActiveToggleLoading}
                variant="secondary"
                label={flight.active ? 'Deactivate' : 'Activate'}
                disabled={flight.readOnly}
              />
              <Button
                variant="secondary"
                label="Duplicate"
                onClick={() => onDuplicate()}
                ml={16}
              />
            </Flex>
          </Flex>
          <Flex mt={40}>
            <StatisticsDisplay entries={brandFlightsStatistics} />
          </Flex>
          <TypeDetails
            placements={flight.zones}
            displaysOn={flight.displaysOn || ''}
            model={flight.type}
            menuRowTitle={flight.title || undefined}
          />
          <ProductDetails
            {...(flight.keywords && flight.keywords.length > 0
              ? {
                  mode: 'category',
                  categories: flight.keywords.map((keyword) => keyword.name),
                }
              : flight.selectedProducts && flight.selectedProducts.length > 0
              ? {
                  mode: 'manual',
                  productNames: flight.selectedProducts.map(({ name }) => name),
                }
              : { mode: 'auto' })}
          />
          {flight.priority === 'premium' && (
            <>
              <PricingDetails
                priority
                totalBudget={`$${flight.bid}`}
                bidModifiers={bidModifiers}
                creditPercent={flight.creditPercent || 0}
              />
              <TargetingDetails
                priorityEnabled
                stores={flight.stores.map(storeDisplayFromEntry)}
              />
            </>
          )}
          {flight.priority === 'auction' && (
            <>
              <PricingDetails
                dailyBudget={`$${flight.budget}`}
                cpmBid={`$${flight.bid}`}
                bidModifiers={bidModifiers}
                creditPercent={flight.creditPercent || 0}
              />
              <TargetingDetails
                segmentCategory={flight.segment?.description || ''}
                states={flight.states || []}
                stores={flight.stores.map(storeDisplayFromEntry)}
                storesExcluded={flight.excludedStores.map(
                  storeDisplayFromEntry
                )}
              />
            </>
          )}
          <ScheduleDetails
            frequencyCapping={{
              frequency: flight.frequencyCap?.frequency
                ? `${flight.frequencyCap.frequency}`
                : undefined,
              periodLength: flight.frequencyCap?.periodLength
                ? `${flight.frequencyCap.periodLength}`
                : undefined,
              periodUnit: flight.frequencyCap?.periodUnit
                ? flight.frequencyCap.periodUnit
                : undefined,
            }}
            spendAllocation={
              !manualDays || manualDays.length === 0
                ? { mode: 'consistent' } // empty array === consistent schedule
                : { mode: 'manual', manualDays }
            }
            start={formatISODate(flight.start)}
            end={formatISODate(flight.end)}
          />
        </Flex>
      )}
    </>
  );
};

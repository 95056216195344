import type * as ReactTable from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useInView } from 'react-intersection-observer';

import type { Product } from '@jane/ad-manager/data-access';
import { Table } from '@jane/shared/reefer-table';

export type ProductTableWithMeta = ReactTable.Table<Product>;

export const ProductTable = ({
  fetchMore,
  table,
}: {
  fetchMore: () => void;
  table: ProductTableWithMeta;
}) => {
  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView) fetchMore();
    },
  });

  return (
    <>
      <Table scrollable>
        <Table.Head fixed>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeaderCell
                  key={header.id}
                  width={`${header.getSize()}px`}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              data-testid={`select-products-table-row-${row.id}`}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div ref={fetchMoreRef}></div>
    </>
  );
};

import { useController, useFormContext } from 'react-hook-form';

import { Flex, Form, Typography } from '@jane/shared/reefer';

import type { AdType, FlatAdSubmissionForm } from '../useAdBuilderForm';
import {
  DEFAULT_PRIORITY_FLAT_IMPRESSIONS,
  DEFAULT_PRIORITY_FLAT_TIME_PERIOD,
  DEFAULT_PROGRAMMATIC_CPC_IMPRESSIONS,
  DEFAULT_PROGRAMMATIC_CPC_TIME_PERIOD,
  DEFAULT_PROGRAMMATIC_CPM_IMPRESSIONS,
  DEFAULT_PROGRAMMATIC_CPM_TIME_PERIOD,
  defaultAdSubmissionFormData,
} from '../useAdBuilderForm';

export const IsPriority = () => {
  const {
    field: { onChange: onChangeAdPriority },
  } = useController<FlatAdSubmissionForm>({
    name: 'isPriority',
  });
  const { getValues, getFieldState, resetField } =
    useFormContext<FlatAdSubmissionForm>();

  const getDefaultImpressions = ({
    isPriority,
    adType,
  }: {
    adType: AdType;
    isPriority: boolean;
  }) => {
    if (isPriority) return DEFAULT_PRIORITY_FLAT_IMPRESSIONS;
    if (adType === 'cpc') return DEFAULT_PROGRAMMATIC_CPC_IMPRESSIONS;
    return DEFAULT_PROGRAMMATIC_CPM_IMPRESSIONS;
  };

  const getDefaultTimePeriod = ({
    isPriority,
    adType,
  }: {
    adType: AdType;
    isPriority: boolean;
  }) => {
    if (isPriority) return DEFAULT_PRIORITY_FLAT_TIME_PERIOD;
    if (adType === 'cpc') return DEFAULT_PROGRAMMATIC_CPC_TIME_PERIOD;
    return DEFAULT_PROGRAMMATIC_CPM_TIME_PERIOD;
  };

  const handleOnChangePriority = (isPriority: boolean) => {
    const adType = getValues('adType');

    if (!getFieldState('impressions').isTouched) {
      resetField('impressions', {
        defaultValue: getDefaultImpressions({ adType, isPriority }),
      });
    }

    if (!getFieldState('timePeriod').isTouched) {
      resetField('timePeriod', {
        defaultValue: getDefaultTimePeriod({ adType, isPriority }),
      });
    }

    const { bid: defaultBid, budget: defaultBudget } =
      defaultAdSubmissionFormData;
    resetField('bid', { defaultValue: defaultBid });
    resetField('budget', { defaultValue: defaultBudget });

    onChangeAdPriority(isPriority);
  };

  return (
    <Flex gap={16} alignItems="center" mt={16}>
      <Form.CheckboxField
        label="Prioritize your ad over others"
        labelHidden
        name="isPriority"
        /**
         * We use 'onClick' here instead of 'onChange' because of a reefer bug that causes the onChange function to be called
         * when duplicating an isPriority=true flight, which was resetting the bid and budget values.
         */
        onClick={() => handleOnChangePriority(!getValues('isPriority'))}
        shouldUnregister={false}
      />
      <Flex flexDirection="column">
        <Typography variant="body">Prioritize your ad over others</Typography>
        <Typography color="grays-mid">
          Highlight specials or product drops. Guarantee your ad will be seen on
          load, with flat fee pricing.
        </Typography>
      </Flex>
    </Flex>
  );
};

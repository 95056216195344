import type {
  BoxProps,
  LoadingProps as ReeferLoadingProps,
} from '@jane/shared/reefer';
import { Box, Loading as ReeferLoading } from '@jane/shared/reefer';

export interface LoadingProps {
  /** Background color of the loading container */
  background?: BoxProps['background'];
  /** Color of the loading animation */
  color?: ReeferLoadingProps['color'];
  /** Test id to apply to loading container */
  'data-testid'?: string;
  /** Set true when loading should take up full height of browser */
  fullPage?: boolean;
  /** Size of the loading animation */
  size?: ReeferLoadingProps['size'];
}

/**
 * Loading component that will expand to fill the parent component. For normal usage the parent component must have a height. Can also be used as a full page loading screen by setting `fullPage` to `true`.
 */
export const Loading = ({ fullPage, ...loaderProps }: LoadingProps) => {
  if (!fullPage) {
    return <Loader {...loaderProps} />;
  }
  return (
    <div style={{ minHeight: '100vh', height: '1px' }}>
      <Loader {...loaderProps} />
    </div>
  );
};

const Loader = ({
  background,
  'data-testid': testId,
  size,
  color,
}: LoadingProps) => {
  return (
    <Box
      background={background || 'grays-white'}
      position="relative"
      width="100%"
      height="100%"
      data-testid={testId}
    >
      <ReeferLoading size={size || 'lg'} color={color || 'purple'} />
    </Box>
  );
};

import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';

import { trackBrandEvent } from '@jane/brands/util';
import type { ProductsSearchItem } from '@jane/catalog-cms/data-access';
import {
  useDuplicateProductSearch,
  useProductId,
} from '@jane/catalog-cms/hooks';
import {
  Box,
  Flex,
  TypeAhead,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { DuplicateProductPreview } from './DuplicateProductPreview';

export const DuplicateProductSearch = ({
  onChange,
}: {
  onChange: (vale: string) => void;
}) => {
  const [query, setQuery] = useState<string | undefined>();
  const { data: products } = useDuplicateProductSearch(query);
  const [selectedProduct, setSelectedProduct] = useState<
    ProductsSearchItem | undefined
  >();
  const { clearErrors } = useFormContext();
  const duplicateProductId = useProductId();

  // Updates the form state with product uuid when selectedProduct changes
  useEffect(() => {
    if (selectedProduct) {
      onChange(selectedProduct.id);
      clearErrors();
      return;
    }
    onChange('');
    clearErrors();
  }, [clearErrors, onChange, selectedProduct]);

  const handleOptionClick = useMemo(() => {
    return (product: ProductsSearchItem) => {
      const trimmedQuery = query?.trim();
      const search_type = trimmedQuery?.match(/^[0-9]+$/)
        ? 'numeric'
        : 'alphanumeric';
      const matches_product_id = product.productId.toString() === trimmedQuery;

      trackBrandEvent({
        eventName: 'Duplicate Product Search',
        matches_product_id,
        search_term: trimmedQuery || 'undefined',
        search_type,
      });

      setSelectedProduct(product);
    };
  }, [query]);

  const options = useMemo(() => {
    if (!products) {
      return [];
    }

    const found = products
      .filter((product) => {
        return product.id !== duplicateProductId;
      })
      .slice(0, 5);

    if (found.length === 0) {
      return [
        <Box px={24} py={12}>
          No products found.
        </Box>,
      ];
    }

    return found.map((product) => (
      <Option
        key={product.id}
        product={product}
        onClick={() => handleOptionClick(product)}
      />
    ));
  }, [duplicateProductId, handleOptionClick, products]);

  return (
    <>
      <Typography variant="body-bold" mb={16}>
        Primary product of this duplicate
      </Typography>
      {selectedProduct && (
        <DuplicateProductPreview
          product={selectedProduct}
          clearSelected={() => setSelectedProduct(undefined)}
        />
      )}

      {!selectedProduct && (
        <TypeAhead
          labelHidden
          ariaLabel="Primary product of this duplicate"
          listAriaLabel="Search by product name or ID"
          width="100%"
          customOptions={options}
          onChange={setQuery}
          mb={24}
        />
      )}
    </>
  );
};

const OptionContainer = styled.div(({ theme }) => ({
  ...spacing({ px: 24, py: 12 }),
  display: 'flex',
  gap: 12,
  width: '100%',
  '&:hover': {
    background: theme.colors.grays.light,
    cursor: 'pointer',
  },
}));

const Option = ({
  product,
  onClick,
}: {
  onClick: (product: ProductsSearchItem) => void;
  product: ProductsSearchItem;
}) => {
  return (
    <OptionContainer
      onClick={() => onClick(product)}
      data-testid={`product-opt-${product.productId}`}
    >
      <Box width="100%">
        <Flex justifyContent={'space-between'}>
          <Box minWidth={200}>
            <Typography variant="body">{product.name}</Typography>
          </Box>
          <Box pl={12}>
            <Typography color="grays-mid">#{product.productId}</Typography>
          </Box>
        </Flex>
      </Box>
    </OptionContainer>
  );
};

import clsx from 'clsx';
import type { CSSProperties, ElementType } from 'react';

import type { TableCellProps } from '../table.types';
import { useTableSettings } from '../useTableSettings';
import styles from './tableCellBase.module.css';

interface TableCellBaseProps extends TableCellProps {
  element: ElementType;
}

export function TableCellBase({
  checkbox,
  children,
  className,
  'data-testid': testId,
  element: Element,
  height = '72px',
  id,
  onClick,
  style,
  width = 'auto',
}: TableCellBaseProps) {
  const { freezeFirstColumn, freezeLastColumn } = useTableSettings();

  return (
    <Element
      className={clsx(className, styles.tableCell, {
        [styles.tableCell__clickable]: !!onClick,
        [styles.tableCell__freezeColumn__first__noCheckbox]:
          freezeFirstColumn && !checkbox,
        [styles.tableCell__freezeColumn__first__checkbox]:
          freezeFirstColumn && checkbox,
        [styles.tableCell__freezeColumn__last]: freezeLastColumn,
      })}
      data-testid={testId}
      id={id}
      onClick={onClick}
      style={
        {
          '--tableCell-height': height,
          '--tableCell-width': width,
          ...style,
        } as CSSProperties
      }
    >
      {children}
    </Element>
  );
}

import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type FileDropContext = {
  isActive?: boolean;
};

export const FileDropContext = createContext<FileDropContext | null>(null);

/**
 * This provider sets all the drag and drop listeners for the full page file
 * drop. It should be given a function `onFilesAdded` which will be called with
 * the list of files dropped in the window by the user.
 */
export const FileDropProvider = ({
  children,
  onFilesAdded,
}: {
  children: ReactNode;
  onFilesAdded: (fileList: FileList | null) => void;
}) => {
  // Count dragenter events to distinguish entering/leaving children vs document
  const [dragEnterCount, setDragEnterCount] = useState<number>(0);

  const context = useMemo(() => {
    const isActive = dragEnterCount > 0;
    return {
      isActive,
    };
  }, [dragEnterCount]);

  const handleDrag = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter') {
      setDragEnterCount((n) => n + 1);
    } else if (event.type === 'dragleave') {
      setDragEnterCount((n) => n - 1);
    }
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      setDragEnterCount(0);
      if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        onFilesAdded(event.dataTransfer.files);
      }
    },
    [onFilesAdded]
  );

  useEffect(() => {
    document.addEventListener('drop', handleDrop, false);
    document.addEventListener('dragenter', handleDrag, false);
    document.addEventListener('dragleave', handleDrag, false);
    document.addEventListener('dragover', handleDrag, false);
    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragenter', handleDrag);
      document.removeEventListener('dragleave', handleDrag);
      document.removeEventListener('dragover', handleDrag);
    };
  }, [handleDrag, handleDrop]);

  return (
    <FileDropContext.Provider value={context}>
      {children}
    </FileDropContext.Provider>
  );
};

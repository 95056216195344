import omit from 'lodash/omit';
import { useController } from 'react-hook-form';

import type { Weekday } from '@jane/ad-manager/types';
import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  SwitchField,
} from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';
import { TimeSlotSelector } from './TimeSlotSelector';
import {
  ALL_DAY_SCHEDULE,
  ORDERED_DAYS,
  WEEKDAY_TO_WEEKDAY_MIN,
} from './constants';

export type DailySchedule = {
  [day: string]: {
    endTime: string;
    startTime: string;
  };
};

const FULL_SCHEDULE = ORDERED_DAYS.reduce(
  (pV, cV) => ({ ...pV, [cV]: ALL_DAY_SCHEDULE }),
  {} as Record<Weekday, { endTime: string; startTime: string }>
);

export const DailySchedule = () => {
  const {
    field: { onChange: onChangeSchedule, value: schedule },
  } = useController<FlatAdSubmissionForm, 'dayParting'>({
    name: 'dayParting',
  });

  return (
    <>
      <Flex gap={16} pb={24} pt={16}>
        <Button
          data-testid="select-all"
          variant="tertiary"
          label="Select all"
          onClick={() => {
            const updatedSchedule = { ...FULL_SCHEDULE, ...schedule };
            onChangeSchedule(updatedSchedule);
          }}
        />
        <Button
          data-testid="select-none"
          variant="tertiary"
          label="Deselect all"
          onClick={() => onChangeSchedule({})}
        />
      </Flex>
      {ORDERED_DAYS.map((day, idx) => (
        <div key={day}>
          <ScheduleDayRow day={day} />
          {idx < ORDERED_DAYS.length - 1 && (
            <Modal.ContentDivider color="grays-light" padding={false} />
          )}
        </div>
      ))}
    </>
  );
};

const ScheduleDayRow = ({ day }: { day: Weekday }) => {
  const {
    field: { onChange: onChangeSchedule, value: schedule },
  } = useController<FlatAdSubmissionForm, 'dayParting'>({
    name: 'dayParting',
  });

  const isDaySelected = day in schedule;

  const {
    field: { value: daysWithScheduling, onChange: setDaysWithScheduling },
  } = useController<FlatAdSubmissionForm, '_manualScheduleForDays'>({
    name: '_manualScheduleForDays',
  });

  const dayMin = WEEKDAY_TO_WEEKDAY_MIN[day];
  const enableDailySchedule = daysWithScheduling[dayMin];
  const setDayWithSchedule = (enabled: boolean) =>
    setDaysWithScheduling({ ...daysWithScheduling, [dayMin]: enabled });

  return (
    <>
      <Flex justifyContent="space-between" mt={24}>
        <CheckboxField
          label={day}
          name={day}
          mb={20}
          checked={isDaySelected}
          onChange={(checked) => {
            if (checked) {
              onChangeSchedule({
                ...schedule,
                [day]: { endTime: '11:59 PM', startTime: '12:00 AM' },
              });
            } else {
              onChangeSchedule(omit(schedule, day));
            }
          }}
        />
        {isDaySelected && (
          <SwitchField
            key={day}
            checked={enableDailySchedule}
            data-testid={`enableDailySchedule-${day}`}
            label="Enable daily schedule"
            name={`enableDailySchedule-${day}`}
            onChange={setDayWithSchedule}
          />
        )}
      </Flex>
      {enableDailySchedule && isDaySelected && <TimeSlotSelector day={day} />}
    </>
  );
};

import styled from '@emotion/styled';

import { AddIcon, EditIcon, Flex, Typography } from '@jane/shared/reefer';

interface WidgetLabels {
  addText: string;
  assetCount?: number;
  editText: string;
}

export interface SidebarWidgetProps {
  id: number;
  isEmpty: boolean;
  labels: WidgetLabels;
  onWidgetClick: () => void;
}

const WidgetWrapper = styled.div<{
  isEmpty: boolean;
}>(({ theme, isEmpty }) => ({
  background: theme.colors.brand.purple.light,
  padding: '20px 24px',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.borderRadius.sm,
  cursor: 'pointer',
  ...(!isEmpty && {
    backgroundColor: theme.colors.grays.white,
  }),
}));

const NumberIcon = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grays.mid,
  width: 24,
  height: 24,
  borderRadius: '50%',
  p: {
    lineHeight: '22px',
    textAlign: 'center',
  },
}));

export const SidebarWidget = ({
  labels,
  onWidgetClick,
  isEmpty,
}: SidebarWidgetProps) => {
  const { addText, editText, assetCount } = labels;
  const hasAssetCount = typeof assetCount === 'number';

  return (
    <WidgetWrapper isEmpty={isEmpty} onClick={onWidgetClick}>
      <Typography color={isEmpty ? 'purple' : 'grays-black'}>
        {isEmpty ? addText : editText}
      </Typography>
      <Flex gap={16}>
        {hasAssetCount && (
          <NumberIcon>
            <Typography variant="body-bold" color="grays-white">
              {assetCount}
            </Typography>
          </NumberIcon>
        )}
        {isEmpty ? <AddIcon color="purple" /> : <EditIcon />}
      </Flex>
    </WidgetWrapper>
  );
};

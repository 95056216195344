// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TRnN0jZwTM0t3tRCbYCO {\n  justify-self: flex-end;\n  box-shadow: var(--shadows-divider-top);\n}\n\n.ErFM8qMYz3gCaTFnoY0x {\n  padding-left: var(--drawer-mobile-padding-horiz);\n  padding-right: var(--drawer-mobile-padding-horiz);\n  padding-top: var(--drawer-padding-vert);\n  padding-bottom: var(--drawer-padding-vert);\n}\n\n@media (min-width: 768px) {\n  .ErFM8qMYz3gCaTFnoY0x {\n    padding-left: var(--drawer-desktop-padding-horiz);\n    padding-right: var(--drawer-desktop-padding-horiz);\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/drawer/drawerFooter/drawerFooter.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,gDAAgD;EAChD,iDAAiD;EACjD,uCAAuC;EACvC,0CAA0C;AAC5C;;AAEA;EACE;IACE,iDAAiD;IACjD,kDAAkD;EACpD;AACF","sourcesContent":[".footer {\n  justify-self: flex-end;\n  box-shadow: var(--shadows-divider-top);\n}\n\n.footer__withPadding {\n  padding-left: var(--drawer-mobile-padding-horiz);\n  padding-right: var(--drawer-mobile-padding-horiz);\n  padding-top: var(--drawer-padding-vert);\n  padding-bottom: var(--drawer-padding-vert);\n}\n\n@media (min-width: 768px) {\n  .footer__withPadding {\n    padding-left: var(--drawer-desktop-padding-horiz);\n    padding-right: var(--drawer-desktop-padding-horiz);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "TRnN0jZwTM0t3tRCbYCO",
	"footer__withPadding": "ErFM8qMYz3gCaTFnoY0x"
};
export default ___CSS_LOADER_EXPORT___;

import { Popover } from '@jane/shared/reefer';

import { Heading } from './Heading';
import { Localizations } from './Localizations';
import { NavigationDropdown as BaseDropdown } from './NavigationDropdown';

export type { NavigationDropdownProps } from './NavigationDropdown';

export const NavigationDropdown = Object.assign(BaseDropdown, {
  Divider: Popover.Divider,
  ListItem: Heading,
  Localizations: Localizations,
});

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Alert24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Alert 24">
      <g className="Union">
        <path
          fill="#0E0E0E"
          d="M11 7a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0V7Zm1 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const Alert64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <g className="Alert 64">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32Zm-4 0c0 15.464-12.536 28-28 28S4 47.464 4 32 16.536 4 32 4s28 12.536 28 28Z"
        className="Union"
        clipRule="evenodd"
      />
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M31.5 20a2 2 0 0 0-2 2v14a2 2 0 1 0 4 0V22a2 2 0 0 0-2-2Zm0 20a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
        className="Union"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Alert24,
  sm: Alert24,
  xxl: Alert64,
  xl: Alert64,
  lg: Alert64,
  xxxl: Alert64,
};

export const AlertIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'alert-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

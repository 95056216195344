import { useMemo } from 'react';

import {
  useCategoryLabels,
  useProductFilters,
  useSelectedBrands,
} from '@jane/catalog-cms/hooks';

import Filters from '../Filters';

export const BrandCategory = () => {
  const { filters, updateFilters } = useProductFilters();
  const { selectedBrandIds } = useSelectedBrands();

  const { data, isLoading } = useCategoryLabels(selectedBrandIds);

  const options = useMemo(() => {
    if (data === undefined) return [];

    const limitedData = data;

    return limitedData.map((categoryLabel) => ({
      label: categoryLabel,
      value: categoryLabel,
    }));
  }, [data]);

  return (
    <Filters.Multiselect
      label="Brand category"
      options={options}
      optionsLoading={isLoading}
      currentValue={filters.categoryLabel || []}
      handleChange={(filterValue) =>
        updateFilters('categoryLabel', filterValue)
      }
      searchable
    />
  );
};

import type { PropsWithChildren, ReactNode } from 'react';

import { useUser } from '@jane/brands/hooks';

import type { BrandPermission, BrandRole } from './permissions';
import { BRAND_PERMISSION_MAP } from './permissions';

export type RenderIfAuthorizedProps = PropsWithChildren<{
  fallback?: ReactNode;
  requiredPermission: BrandPermission;
}>;

/**
 * Use this component to conditionally render a component based on a permission.
 * Be default if the user does NOT have the required permission `null` will be
 * rendered. An optional `fallback` component can be provided to be rendered
 * instead of `null` for users without the required permission.
 * @example
 * <RenderIfAuthorized requiredPermission="accessBrands" fallback={<AccessDenied />}>
 *   <Brands />
 * </RenderIfAuthorized>
 */
export const RenderIfAuthorized = ({
  children,
  fallback,
  requiredPermission,
}: RenderIfAuthorizedProps) => {
  const { roles } = useUser();

  const isAuthorized = checkRolesForPermission(roles, requiredPermission);

  if (isAuthorized) return <>{children} </>;

  return fallback ? <>{fallback} </> : null;
};

/**
 * Returns true if any role has the given permission
 */
const checkRolesForPermission = (
  roles: string[],
  permission: BrandPermission
) => {
  return roles.some((role) => {
    // typecast role to allow for looser typing of string[] for roles on the user object
    const brandRole = role as BrandRole;

    if (brandRole in BRAND_PERMISSION_MAP) {
      return BRAND_PERMISSION_MAP[brandRole].includes(permission);
    }

    return false;
  });
};

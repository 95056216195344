/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Play24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Play 24">
      <path
        fill="#0E0E0E"
        d="M17.286 10.304a2 2 0 0 1 0 3.392L7.06 20.087C5.728 20.92 4 19.962 4 18.391V5.608c0-1.57 1.728-2.528 3.06-1.696l10.226 6.392Z"
        className="Polygon 2"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Play24,
  sm: Play24,
  lg: Play24,
  xl: Play24,
  xxl: Play24,
  xxxl: Play24,
};

export const PlayIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'play-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

import { Flex } from '@jane/shared/reefer';
import type { ReeferBaseProps } from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

export const SectionWrapper = styled.div(({ theme }) => ({
  boxShadow: shadows.hard,
  backgroundColor: theme.colors.grays.white,
  borderRadius: theme.borderRadius.xl,
}));

export const AdBuilderSectionContainer = ({
  children,
  ...props
}: PropsWithChildren & ReeferBaseProps) => {
  return (
    <SectionWrapper {...props}>
      <Flex px={40} py={40} flexDirection="column">
        {children}
      </Flex>
    </SectionWrapper>
  );
};

import type { QueryFunctionContext } from '@tanstack/react-query';
import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

const CategoryLabelsSchema = z.array(z.string());
const CategoryLabelsBodySchema = z.object({ data: CategoryLabelsSchema });

export type CategoryLabels = z.infer<typeof CategoryLabelsSchema>;
export type CategoryLabelsBody = z.infer<typeof CategoryLabelsBodySchema>;

export type CategoryLabelParams = { brandUuids?: string[] };

export const fetchCategoryLabels = async ({
  queryKey,
}: QueryFunctionContext<[string, CategoryLabelParams]>) => {
  const params = queryKey[1];
  const searchString = makeSearchString(params);
  const path = searchString
    ? `${brandServerPaths.categoryLabels()}?${searchString}`
    : brandServerPaths.categoryLabels();

  const { data } = await brandsApi.get(path);

  const parsedData = parseData(CategoryLabelsBodySchema, data);

  return parsedData.data;
};

const makeSearchString = (params: CategoryLabelParams) => {
  const urlParams = new URLSearchParams();
  const { brandUuids } = params;

  brandUuids?.forEach((uuid) => urlParams.append('brand_uuids[]', uuid));

  return urlParams.toString();
};

import parseInt from 'lodash/parseInt';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const BRAND_ID_SEARCH_PARAM_KEY = 'brand_id';
const extractBrandIdFromSearchParams = (searchParams: URLSearchParams) => {
  const brandIdFromParams = searchParams.get(BRAND_ID_SEARCH_PARAM_KEY);
  if (!brandIdFromParams) return;
  const parsedBrandId = parseInt(brandIdFromParams);
  if (Number.isNaN(parsedBrandId)) return;
  return parsedBrandId;
};

export const useBrandIdSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const brandIdFromSearchParams = extractBrandIdFromSearchParams(searchParams);

  const setBrandIdInSearchParams = useCallback(
    (brandId: number) => {
      setSearchParams((old) => {
        old.set(BRAND_ID_SEARCH_PARAM_KEY, brandId.toString());
        return old;
      });
    },
    [setSearchParams]
  );
  return { brandIdFromSearchParams, setBrandIdInSearchParams };
};

import styled from '@emotion/styled';

import { useLocalBrandSpecials } from '@jane/shared-ecomm/data-access';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { Brand } from '@jane/shared/models';
import {
  Box,
  Card,
  Flex,
  PartnerLogo,
  ReadMore,
  Typography,
} from '@jane/shared/reefer';

import { LAYOUT_MAX_WIDTH } from '../pageLayout';
import { JaneGoldSubheader } from './janeGoldSubheader';

export const DesktopBrandDetail = ({
  brand,
  isGuestUser,
  isJaneGoldMember,
}: {
  brand: Brand;
  isGuestUser?: boolean;
  isJaneGoldMember?: boolean;
}) => {
  const { name, logo_url, description, custom_images, custom_video } = brand;
  const { userLocation, userPreferences } = useUserPreferences();
  const { localBrandSpecials: brandSpecials = [] } = useLocalBrandSpecials({
    enabled: !!brand,
    brandIds: [brand.id as number],
    userLocation,
    userPreferences,
  });

  const shouldShowGold = useShouldShowGold({ brandSpecials });

  const numberOfAssets =
    (custom_images?.length || 0) + (custom_video == null ? 0 : 1);

  const DesktopBrandContainer = styled.div({
    position: 'relative',
    bottom: numberOfAssets > 0 ? 70 : 96,
    marginBottom: numberOfAssets > 0 ? -70 : -96,
    zIndex: 1,
  });

  return (
    <DesktopBrandContainer data-testid="brand-detail-desktop">
      <Flex width="100%" justifyContent="center">
        <Box maxWidth={LAYOUT_MAX_WIDTH} px={24} width={'100%'}>
          <Card width="100%" flat border="grays-light">
            <Card.Content>
              <Flex p={32}>
                <PartnerLogo variant="brand" name={name} image={logo_url} />
                <Flex ml={24} width={'calc(100% - 96px)'} alignItems="center">
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Typography variant="title-bold">{name}</Typography>
                    {description && (
                      <Typography as="h2" mt={24}>
                        <ReadMore
                          content={description}
                          more="View more"
                          less="View less"
                        />
                      </Typography>
                    )}
                    {shouldShowGold && (
                      <JaneGoldSubheader
                        brandName={name}
                        isGuestUser={isGuestUser}
                        isJaneGoldMember={isJaneGoldMember}
                      />
                    )}
                  </div>
                </Flex>
              </Flex>
            </Card.Content>
          </Card>
        </Box>
      </Flex>
    </DesktopBrandContainer>
  );
};

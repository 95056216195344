import styled from '@emotion/styled';

import { Box, Button, ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

const TitleText = styled(Typography)({
  fontSize: '32px',
});

export const AnalyticsError = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: 'white' }}>
      <Flex width="432px" mx="auto" justifyContent="center">
        <Flex
          my={128}
          mx={40}
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
          width="432px"
        >
          <ErrorIcon size="xxl" mb={24} color={'error'} />
          <Flex flexDirection="column" width="432px" alignItems="center">
            <TitleText mb={16} variant="title-bold" textAlign="center">
              Something went wrong
            </TitleText>
            <Box width="330px">
              Try refreshing this page. If the problem persists:
            </Box>
            <Box width="320px" mb={16}>
              <Typography>&bull; clear your browser cookies</Typography>
              <Typography>
                &bull; use a supported browser (Chrome or Firefox)
              </Typography>
              <Typography>&bull; refresh this page</Typography>
            </Box>
            <Button
              full
              variant="primary"
              onClick={refreshPage}
              label="Refresh"
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

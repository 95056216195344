import { Flex, Typography } from '@jane/shared/reefer';

import type { FlightDetailsCell } from './FlightDetailSection';
import { FlightDetailSection } from './FlightDetailSection';

const StoresList = ({ stores }: { stores: { id: string; name: string }[] }) => (
  <>
    {stores.map(({ id, name }) => (
      <Flex key={id}>
        {name}
        <Typography ml={4} color="grays-mid">
          (#{id})
        </Typography>
      </Flex>
    ))}
  </>
);

type Props =
  | {
      priorityEnabled?: false;
      segmentCategory: string;
      states: string[];
      stores?: { id: string; name: string }[];
      storesExcluded?: { id: string; name: string }[];
    }
  | {
      priorityEnabled: true;
      stores: { id: string; name: string }[];
    };
export const TargetingDetails = (props: Props) => {
  if (props.priorityEnabled) {
    return (
      <FlightDetailSection
        headerLabel="Target stores"
        data-testid="store-targeting-details"
        rows={[
          [
            {
              name: 'Stores',
              value: <StoresList stores={props.stores} />,
            },
          ],
        ]}
      />
    );
  }

  const { states, stores, storesExcluded, segmentCategory } = props;

  let firstRowItems: FlightDetailsCell[] = [];
  if (states) {
    firstRowItems = [{ name: 'States', value: states?.join(', ') }];
  }
  if (segmentCategory && segmentCategory.length > 0) {
    firstRowItems = [
      ...firstRowItems,
      {
        name: 'Customers',
        value: segmentCategory,
      },
    ];
  }
  let secondRowItems: typeof firstRowItems = [];
  if (stores && stores.length > 0) {
    secondRowItems = [
      {
        name: 'Stores',
        value: <StoresList stores={stores} />,
      },
    ];
  }
  if (storesExcluded && storesExcluded.length > 0) {
    secondRowItems = [
      ...secondRowItems,
      {
        name: 'Stores Excluded',
        value: <StoresList stores={storesExcluded} />,
      },
    ];
  }
  return (
    <FlightDetailSection
      data-testid="targeting-details"
      headerLabel="Target states, stores, and customers"
      rows={[firstRowItems, secondRowItems]}
    />
  );
};

import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSelectedBrands = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedBrandIds = searchParams.getAll('brand');

  const updateSelectedBrands = useCallback(
    (brandIds: string[]) => {
      setSearchParams((searchParams) => {
        const updated = new URLSearchParams(searchParams);

        updated.delete('brand');

        if (brandIds.length > 0) {
          brandIds.forEach((id) => updated.append('brand', id));
        }

        return updated;
      });
    },
    [setSearchParams]
  );

  return {
    selectedBrandIds,
    updateSelectedBrands,
  };
};

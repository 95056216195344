/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const SortUp24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Sort Up 24">
      <path
        fill="#0E0E0E"
        d="M16.707 14.293A1 1 0 0 1 16 16H8a1 1 0 0 1-.707-1.707l4-4a1 1 0 0 1 1.414 0l4 4Z"
        className="Union"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: SortUp24,
  sm: SortUp24,
  lg: SortUp24,
  xl: SortUp24,
  xxl: SortUp24,
  xxxl: SortUp24,
};

export const SortUpIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sort-up-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { Children, cloneElement } from 'react';

import { Flex } from '../../flex';
import type { GridContainerProps } from '../grid.types';
import styles from './gridContainer.module.css';

/**
 * The `Grid` component is used to display a series of divs in a grid layout.
 *
 * Each individual item within the `Grid.Container` component is defined using the `Grid.Item` compoennt.
 */
export function GridContainer({
  ariaLabel,
  center = false,
  children,
  className,
  'data-testid': testId,
  direction = 'row',
  id,
  spacing: spacingProp = 16,
  style,
}: GridContainerProps) {
  const spacing =
    typeof spacingProp === 'number' ? `${spacingProp}px` : spacingProp;
  const margin = `-${spacing}`;

  return (
    <Flex
      ariaLabel={ariaLabel}
      className={clsx(className, styles.gridContainer)}
      data-testid={testId}
      flexDirection={direction === 'row' ? 'row' : 'column'}
      flexWrap="wrap"
      height="100%"
      id={id}
      justifyContent={center ? 'center' : 'normal'}
      style={
        {
          '--grid-container-margin-bottom': margin,
          '--grid-container-margin-left': margin,
          ...style,
        } as CSSProperties
      }
      width={`calc(100% + ${spacing})`}
    >
      {Children.map(
        children,
        (child) =>
          child &&
          cloneElement(child, {
            direction,
            spacing,
          })
      )}
    </Flex>
  );
}

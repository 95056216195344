import type { ZodTypeAny } from 'zod';

import { config } from '@jane/shared/config';

/**
 * This is a wrapper utility for parsing data using zod.
 *
 * The `parse` method will throw a `ZodError` if the data being parsed is not
 * valid, which is what we want in development but in production we don't want
 * to throw. Instead we want to log the error and let the application try to use
 * the invalid data. This utility implements that logic. It will apply the
 * schema type to the returned data.
 * @example
 * const userSchema = z.object({name: z.string(), id: z.string()})
 *
 * const user = getUser()
 * const parsedUser = parseData(userSchema, user)
 *
 */
export function parseData<T extends ZodTypeAny>(
  schema: T,
  data: unknown
): T['_output'] {
  if (config.nodeEnv === 'production') {
    const result = schema.safeParse(data);
    if (result.success) {
      return result.data;
    }
    return data;
  }
  return schema.parse(data);
}

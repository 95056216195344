import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@jane/brands/hooks';
import { brandsPaths } from '@jane/brands/util';

import { Loading } from '../util/Loading';

/**
 * This component is mean to be used in a router and should wrap any components
 * that should be restricted to only authenticated users. Unauthenticated users
 * will be redirected to the login page
 *
 * It also adds a 'from' property to the location state which can be accessed from
 * the 'useLocation' hook to then redirect back to the user's originally intended
 * page.
 * @example
 * // RequireAuth route object wrapping a protected route to '/products'
 * {
 *  element: <RequireAuth />,
 *  children: [
 *    {
 *      path: '/products',
 *      element: <Products />,
 *    },
 *  ],
 * },
 * // In the 'Login' Component access the 'from' state like this:
 * const location = useLocation()
 * const locationState = location.state
 * const fromLocation = locationState.from // This will have the Location object
 * const fromPath = fromLocation.pathname
 */

export const RequireAuth = () => {
  const { userQuery } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const user = userQuery.data;

  useEffect(() => {
    if (userQuery.isFetched && !user) {
      navigate(brandsPaths.login(), {
        replace: true,
        state: { from: location },
      });
    }
  }, [location, navigate, user, userQuery.isFetched]);

  return user && userQuery.isFetched ? (
    <Outlet />
  ) : (
    <Loading data-testid="loader" fullPage />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Edit24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Edit 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M19.121 3.707a3 3 0 0 0-4.242 0L4.627 13.96a3 3 0 0 0-.725 1.173l-1.85 5.552a1 1 0 0 0 1.264 1.265l5.552-1.851a3 3 0 0 0 1.173-.725L20.293 9.121a3 3 0 0 0 0-4.242L19.12 3.707Zm-2.828 1.414a1 1 0 0 1 1.414 0l1.172 1.172a1 1 0 0 1 0 1.414L9.5 17.086 6.914 14.5l9.379-9.379ZM5.687 16.101 4.581 19.42 7.9 18.313 5.687 16.1Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Edit24,
  sm: Edit24,
  lg: Edit24,
  xl: Edit24,
  xxl: Edit24,
  xxxl: Edit24,
};

export const EditIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'edit-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useProductFilters } from '@jane/catalog-cms/hooks';
import { SearchField } from '@jane/shared/reefer';

export const SearchTerm = () => {
  const { filters, updateFilters } = useProductFilters();
  return (
    <SearchField
      label="Product name or i.d."
      name="searchTerm"
      placeholder="Search name or I.D."
      isDebounced={true}
      debounceDelay={300}
      clearIcon={true}
      defaultValue={filters.searchTerm}
      onChange={(value) => updateFilters('searchTerm', value)}
      labelHidden={true}
    />
  );
};

import clsx from 'clsx';

import { CheckboxField, SortUpIcon } from '@jane/shared/reefer';

import type { TableHeaderCellProps } from '../table.types';
import { TableCellBase } from '../tableCellBase/tableCellBase';
import styles from './tableHeaderCell.module.css';

export function TableHeaderCell({
  checkbox,
  children,
  className,
  'data-testid': testId,
  height = '72px',
  id,
  sort,
  style,
  width = 'auto',
}: TableHeaderCellProps) {
  const onClick = sort
    ? () =>
        sort.onClick(
          sort.key,
          sort.active && sort.direction === 'asc' ? 'desc' : 'asc'
        )
    : undefined;

  return (
    <TableCellBase
      checkbox={checkbox}
      className={clsx(className)}
      data-testid={testId}
      element="th"
      height={height}
      id={id}
      onClick={onClick}
      style={style}
      width={width}
    >
      {checkbox && (
        <CheckboxField
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          label=""
          name="cell-checkbox"
          {...(checkbox.indeterminate !== undefined && {
            indeterminate: checkbox.indeterminate,
          })}
        />
      )}
      {!checkbox && (
        <div
          className={clsx(styles.tableHeaderCell_label, {
            [styles.tableHeaderCell_label__sorted]: sort?.active,
          })}
        >
          {children}
          {sort && (
            <SortUpIcon
              color={sort.active ? 'purple-dark' : 'purple-light'}
              rotate={sort.active && sort.direction === 'asc' ? 'up' : 'down'}
              altText={`sort-by`}
            />
          )}
        </div>
      )}
    </TableCellBase>
  );
}

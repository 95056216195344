import styled from '@emotion/styled';

import { BrandThemes } from '@jane/ad-manager/components';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Loading } from '@jane/shared/reefer';

const Grid = styled.div(() => {
  const marginBottom = 68;
  const footerHeight = 96;
  const finalRow = footerHeight + marginBottom;
  return {
    display: 'grid',
    gap: 24,
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: `112px 1fr ${finalRow}px`,
  };
});

export const BrandThemesPage = () => {
  const { isLoading, isSuccess, selectedBrand } = useSelectedBrandContext();

  return (
    <Grid>
      {isLoading && <Loading color="purple" />}
      {isSuccess && selectedBrand && <BrandThemes />}
    </Grid>
  );
};

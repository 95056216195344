import isEmpty from 'lodash/isEmpty';

import type { BidModifier, BidModifiers } from '@jane/ad-manager/types';
import { formatCurrency, formatPercent } from '@jane/ad-manager/util';
import { Box, Flex, Modal, Typography } from '@jane/shared/reefer';

interface FlightDetailSectionProps {
  bidModifiers?: BidModifiers;
  'data-testid'?: string;
  headerLabel: string;
  rows: FlightDetailsCell[][];
}

const formatBidModifier = (
  target: string,
  bidModifier: BidModifier
): string => {
  switch (bidModifier.type) {
    case '%': {
      const amount = bidModifier.amount / 100;
      return `${formatPercent(amount, 0)} for Placement: ${target}`;
    }
    case '$':
      return `${formatCurrency(bidModifier.amount)} for Placement: ${target}`;
    default:
      return '';
  }
};

const BidModifierRow = ({
  bidModifier,
  target,
}: {
  bidModifier: BidModifier;
  target: string;
}) => (
  <Flex grow flexDirection="column" key={target}>
    <Typography>{formatBidModifier(target, bidModifier)}</Typography>
  </Flex>
);

const BidModifiersSection = ({
  bidModifiers,
}: {
  bidModifiers?: BidModifiers;
}) => {
  if (bidModifiers === undefined || isEmpty(bidModifiers)) {
    return null;
  }

  return (
    <>
      <Modal.ContentDivider color="grays-light" />
      <Typography color="grays-mid" variant="caps">
        Bid Modifiers
      </Typography>
      {Object.keys(bidModifiers).map((target) => (
        <BidModifierRow
          key={target}
          target={target}
          bidModifier={bidModifiers[target]}
        />
      ))}
    </>
  );
};

export interface FlightDetailsCell {
  name: string;
  value: string | JSX.Element;
}
export const FlightDetailSection = ({
  'data-testid': testId,
  headerLabel,
  rows,
  bidModifiers,
}: FlightDetailSectionProps) => (
  <Box
    py={40}
    px={40}
    border="grays-light"
    borderRadius={'lg'}
    background={'grays-white'}
    data-testid={testId}
  >
    <Typography variant="header-bold">{headerLabel}</Typography>
    {rows.map((rowItems) => (
      <Box mt={24} key={rowItems.reduce((acc, { name }) => acc + name, '')}>
        <Flex>
          {rowItems.map(({ name, value }) => (
            <Flex grow flexDirection="column" key={name}>
              <Typography color="grays-mid" variant="caps">
                {name}
              </Typography>
              {value}
            </Flex>
          ))}
        </Flex>
      </Box>
    ))}
    <BidModifiersSection bidModifiers={bidModifiers} />
  </Box>
);

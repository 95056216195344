import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  formatDatepickerString,
  nowInPacificTime,
} from '@jane/ad-manager/util';
import { Flex, Form } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';

export const FlightDateRange = () => {
  const { trigger, watch, getFieldState } =
    useFormContext<FlatAdSubmissionForm>();

  const [endDate, startDate] = watch(['endDate', 'startDate']);
  const today = formatDatepickerString(nowInPacificTime());

  useEffect(() => {
    const { isTouched: isStartDateTouched } = getFieldState('startDate');
    if (isStartDateTouched) trigger('startDate');

    const { isTouched: isEndDateTouched } = getFieldState('endDate');
    if (isEndDateTouched) trigger('endDate');
  }, [endDate, getFieldState, startDate, trigger]);

  return (
    <Flex gap={24} data-testid="date-range-section">
      <Form.DateTimeField
        label="Start date"
        name="startDate"
        min={today}
        width="100%"
        shouldUnregister={false}
      />
      <Form.DateTimeField
        label="End date"
        name="endDate"
        min={today}
        width="100%"
        shouldUnregister={false}
      />
    </Flex>
  );
};

import type { ReactNode } from 'react';

import { List } from '@jane/shared/reefer';

export interface NavigationDropdownHeadingProps {
  children: ReactNode;
}

export const Heading = ({ children }: NavigationDropdownHeadingProps) => {
  return <List.Item>{children}</List.Item>;
};

import styled from '@emotion/styled';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SrOnly } from '@jane/brands/components';
import { uploadImageToS3 } from '@jane/catalog-cms/util';
import { ImageEditor } from '@jane/shared-b2b/components';
import type { ValidateImageFileOptions } from '@jane/shared-b2b/util';
import { validateImageFile } from '@jane/shared-b2b/util';
import { config } from '@jane/shared/config';
import {
  Banner,
  ErrorIcon,
  Flex,
  InfoIcon,
  Link,
  List,
  Typography,
} from '@jane/shared/reefer';

const BANNER_TEXT = [
  'PNG or JPEG file up to 4MB · Square dimensions · 1000px minimum size\n',
  'By uploading photos you represent that you own or otherwise control the ',
  'copyrights to the photos',
].join('');

const MIN_DIMENSION = 1000; // 1000px
const MAX_FILE_SIZE = 4 * (1024 * 1024); // 4MB
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg'];

const VALIDATION_OPTIONS: ValidateImageFileOptions = {
  aspectRatio: {
    allowedRatios: ['1:1'],
    message: 'Image must have square dimensions.',
  },
  dimensions: {
    message: 'Image must be at least 1000 pixels.',
    min: MIN_DIMENSION,
  },
  fileSize: {
    maxSize: MAX_FILE_SIZE,
    message: 'Image is larger than the allowed 4MB.',
  },
  fileType: {
    allowedTypes: ALLOWED_FILE_TYPES,
    message: 'Image is not a PNG or JPEG.',
  },
};

const s3BucketUrl = () => `${config.brandServerUrl}/s3/params`;

interface ImagesProps {
  defaultValue: string[];
  revertToImageUrls?: string[];
}

/**
 * Image editing component for the product and localization forms. On
 * localization pages to enable the revert to product values feature pass the
 * product's images url array to `revertToImageUrls`.
 */

export const Images = ({ defaultValue, revertToImageUrls }: ImagesProps) => {
  const [uploadCount, setUploadCount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { watch } = useFormContext();

  const makeOnFilesAdded = (onChange: (newState: string[]) => void) => {
    return async (fileList: FileList | null) => {
      setErrorMessages([]);

      if (fileList) {
        setUploadCount(fileList.length);

        Array.from(fileList).forEach(async (file) => {
          const error = await validateImageFile(file, VALIDATION_OPTIONS);

          if (!error) {
            // No validation errors, upload file to s3
            try {
              const url = await uploadImageToS3(s3BucketUrl(), file, {
                credentials: 'include',
              });

              setUploadCount((c) => c - 1);
              const imageURLs = watch('imageURLs');

              onChange([...imageURLs, url]);
            } catch (error) {
              setUploadCount((c) => c - 1);

              setErrorMessages((oldState) => [
                ...oldState,
                `${file.name} - Encountered an error while uploading, please try again.`,
              ]);
            }
          } else {
            // update error messages
            const { filename, errors } = error;
            const messages = errors.map((e) => `${filename} - ${e.message}`);

            setErrorMessages((oldState) => [...oldState, ...messages]);
            setUploadCount((c) => c - 1);
          }
        });
      }
    };
  };

  return (
    <Flex flexDirection="column" gap={16}>
      <Flex justifyContent="space-between">
        <Typography variant="body-bold">Images</Typography>
        <RevertImages productImageURLs={revertToImageUrls} />
      </Flex>
      {errorMessages.length > 0 ? (
        <ErrorBanner
          closeBanner={() => setErrorMessages([])}
          messages={errorMessages}
        />
      ) : null}
      {uploadCount ? (
        <Banner
          icon={<InfoIcon />}
          typography="body"
          variant="info"
          label={`Uploading ${uploadCount} files...`}
          full
        />
      ) : null}
      <Controller
        defaultValue={defaultValue}
        name="imageURLs"
        render={({ field: { onChange, value } }) => (
          <ImageEditor
            images={value}
            onChange={onChange}
            isUploading={uploadCount > 0}
            onFilesAdded={makeOnFilesAdded(onChange)}
          />
        )}
      />
      <Banner
        icon={<InfoIcon />}
        typography="body"
        variant="info"
        label={<BannerText>{BANNER_TEXT}</BannerText>}
        full
      />
    </Flex>
  );
};

const BannerText = styled('p')({ whiteSpace: 'pre-wrap' });

const ErrorBanner = ({
  closeBanner,
  messages,
}: {
  closeBanner: () => void;
  messages: string[];
}) => {
  return (
    <Banner
      icon={<ErrorIcon />}
      variant="error"
      full
      onDismiss={closeBanner}
      label={
        <>
          <Typography variant="body-bold">Image upload errors</Typography>
          <List label="image upload errors" p={0}>
            {messages.map((m) => (
              <li style={{ listStyleType: 'disc' }} key={m}>
                <Typography variant="body">{m}</Typography>
              </li>
            ))}
          </List>
        </>
      }
    />
  );
};

const RevertImages = ({
  productImageURLs,
}: {
  productImageURLs?: string[];
}) => {
  const { setValue, watch } = useFormContext();

  if (
    productImageURLs &&
    JSON.stringify(watch('imageURLs')) !== JSON.stringify(productImageURLs)
  ) {
    return (
      <Link
        onClick={() => {
          setValue('imageURLs', productImageURLs, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }}
      >
        Revert <SrOnly as="span">images</SrOnly>
      </Link>
    );
  }
  return null;
};

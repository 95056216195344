import { useRouteError } from 'react-router-dom';

import { useBrandsNavigate } from '@jane/brands/hooks';
import { useProductId } from '@jane/catalog-cms/hooks';
import { ApiRequestError } from '@jane/shared/data-access';
import { Button, ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

export const ProductError = () => {
  const brandsNavigate = useBrandsNavigate();
  const productId = useProductId();

  const error = useRouteError();
  let title = 'Oops!';
  let subtitle = 'There was an unexpected error when retrieving this product';
  let action =
    'We could be experiencing a temporary problem or you may be having network issues. You can try again later by refreshing the page or return to the products list.';
  let help = "If this issue doesn't resolve itself please contact support.";

  if (error instanceof ApiRequestError && error.response.status === 404) {
    title = 'Product not found';
    subtitle = `We couldn't find a product with ID "${productId}".`;
    action = 'Try selecting another product by returning to the product list.';
    help = '';
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m={40}
    >
      <ErrorIcon size="xxl" mb={20} color="error" />
      <Typography mb={8} variant="title-bold" as="h2">
        {title}
      </Typography>
      <Typography
        variant="body-bold"
        mb={2}
        color="grays-mid"
        textAlign="center"
      >
        {subtitle}
      </Typography>
      <Typography variant="body" mb={20} color="grays-mid" textAlign="center">
        {action}
      </Typography>
      <Flex width="100%" justifyContent="center" mb={16}>
        <Flex width="50%" justifyContent="flex-end">
          <Button
            label="Go to product list"
            variant="secondary"
            mr={8}
            onClick={() => brandsNavigate.productList()}
          />
        </Flex>
        <Flex width="50%" justifyContent="flex-start">
          <Button
            label="Refresh Page"
            ml={8}
            onClick={() => window.location.reload()}
          />
        </Flex>
      </Flex>
      <Typography variant="body" color="grays-mid">
        {help}
      </Typography>
    </Flex>
  );
};

import type { Dispatch, ReactNode, SetStateAction } from 'react';
import React from 'react';

interface Props {
  children: ReactNode;
  forceMobile: boolean;
  setForceMobile: Dispatch<SetStateAction<boolean>>;
}

export const BrandThemeEditorContext = React.createContext<
  Omit<Props, 'children'>
>({
  forceMobile: false,
  setForceMobile: () => null,
});

export const BrandThemeEditorProvider = ({
  children,
  forceMobile,
  setForceMobile,
}: Props) => (
  <BrandThemeEditorContext.Provider
    value={{
      forceMobile,
      setForceMobile,
    }}
  >
    {children}
  </BrandThemeEditorContext.Provider>
);

import { useEffect, useState } from 'react';

import type { AuthUser } from '@jane/brands/data-access';
import { getFlagContext, useFlagClient } from '@jane/shared/feature-flags';

export const useIdentifyLDContext = (user?: Pick<AuthUser, 'id' | 'email'>) => {
  const flagClient = useFlagClient();
  const [isIdentifying, setIsIdentifying] = useState(true);

  //  Updates the LaunchDarkly context
  useEffect(() => {
    const identifyAsync = async () => {
      if (flagClient && user) {
        return flagClient.identify({
          kind: 'user',
          key: user.id,
          email: user.email,
        });
      }

      return flagClient?.identify(getFlagContext());
    };

    let ignore = false;
    setIsIdentifying(true);

    identifyAsync().then(() => {
      if (!ignore) {
        setIsIdentifying(false);
      }
    });

    // cleanup function sets ignore = true to prevent async state updates if
    // component unmounts
    return () => {
      ignore = true;
    };
  }, [flagClient, user]);

  return { isIdentifying };
};

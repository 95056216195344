import { AlertIcon, Box, Button, Flex, Typography } from '@jane/shared/reefer';

interface FlightEmptyStateProps {
  isError: boolean;
  onClick: (isError: boolean) => void;
}

export const FlightEmptyState: React.FC<FlightEmptyStateProps> = ({
  isError,
  onClick,
}) => {
  return (
    <Box borderRadius="lg" p={128} m={24} border="grays-light">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        gap={24}
        p={72}
        data-testid="flight-empty-state"
      >
        <AlertIcon size="xxl" />
        <Typography variant="header-bold" textAlign="center">
          {isError ? "Couldn't load flights" : 'No results'}
        </Typography>
        {!isError && (
          <Typography variant="body" color="grays-dark" textAlign="center">
            No flights match the filters you have selected
          </Typography>
        )}
        <Button
          variant="secondary"
          label={isError ? 'Try again' : 'View all flights'}
          onClick={() => onClick(isError)}
          data-testid="flight-empty-state-button"
        />
      </Flex>
    </Box>
  );
};

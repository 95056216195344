import type { QueryFunctionContext } from '@tanstack/react-query';

import { brandServerApi } from '../brandServerApi';
import type { ApiSegments } from './schemas';
import { ApiSegmentsSchema } from './schemas';

export const fetchSegments = async (context: QueryFunctionContext) => {
  const resp = await brandServerApi.get<ApiSegments>('/jam/segments');
  ApiSegmentsSchema.parse(resp);
  return resp;
};

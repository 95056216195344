import { useParams } from 'react-router-dom';

import { useBrandsNavigate } from '@jane/brands/hooks';
import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { getLocalizationName } from '@jane/catalog-cms/util';
import { Link, List, usePopoverContext } from '@jane/shared/reefer';

import { NavigationDropdown } from '.';

export interface NavigationDropdownLocalizationsProps {
  localizations: EditFormProduct['localizations'];
  productId: string;
}

export const Localizations = ({
  localizations,
  productId,
}: NavigationDropdownLocalizationsProps) => {
  const brandsNavigate = useBrandsNavigate();
  const { localizationId } = useParams();
  const { closePopover } = usePopoverContext();

  return (
    <>
      {localizations.length > 0 ? <NavigationDropdown.Divider /> : null}
      {localizations.map((localization) => {
        if (localizationId && localizationId === localization.id) {
          return null;
        }

        return (
          <LocalizationLink
            productId={productId}
            localization={localization}
            onClick={() => {
              brandsNavigate.editLocalization(productId, localization.id);
              closePopover();
            }}
            key={localization.id}
          />
        );
      })}
    </>
  );
};

interface LocalizationLinkProps {
  localization: EditFormProduct['localizations'][number];
  onClick: () => void;
  productId: string;
}

const LocalizationLink = ({
  productId,
  localization,
  onClick,
}: LocalizationLinkProps) => {
  return (
    <List.Item key={localization.id}>
      <Link variant="minimal" onClick={onClick}>
        {getLocalizationName(localization.country, localization.subdivision)}
      </Link>
    </List.Item>
  );
};

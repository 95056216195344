import type * as ReactTable from '@tanstack/react-table';

import type { Product } from '@jane/ad-manager/data-access';
import { SearchField } from '@jane/shared/reefer';

export const ProductTableSearch = ({
  column,
}: {
  column: ReactTable.Column<Product> | undefined;
}) => {
  if (!column) return null;
  return (
    <SearchField
      placeholder="Search product name"
      label={`search-product-${column.id}`}
      name={`search-product-${column.id}`}
      onChange={(value) => column.setFilterValue(value)}
    />
  );
};

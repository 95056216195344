import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { DismissIcon, Flex, useDrawerContext } from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

type DrawerHeaderProps = { children: ReactNode; showDismiss?: boolean };

export const DrawerHeader = ({ children, showDismiss }: DrawerHeaderProps) => {
  const { onRequestClose } = useDrawerContext();

  return (
    <StyledDrawerHeader minHeight={80} px={24} py={16} alignItems="center">
      {showDismiss && (
        <DismissIcon
          mr={24}
          altText="Close Drawer"
          color="inherit"
          onClick={onRequestClose}
          data-testid="drawer-header-close-icon"
        />
      )}
      {children}
    </StyledDrawerHeader>
  );
};

const StyledDrawerHeader = styled(Flex)({
  boxShadow: shadows.divider,
});

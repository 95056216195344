import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { searchAlgoliaProducts } from '@jane/search/data-access';
import type { SearchAlgoliaProductsParams } from '@jane/search/types';
import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';
import type { Id, PurchasedProduct } from '@jane/shared/types';

import { queryKeys, urls } from './products.util';

const getProducts = async (params: SearchAlgoliaProductsParams) => {
  const products = await searchAlgoliaProducts(params);
  return products;
};

export const useProducts = (
  { enabled, ...params }: SearchAlgoliaProductsParams,
  key = ['products']
) => {
  return useQuery({
    enabled,
    queryFn: () => getProducts({ ...params }),
    queryKey: [...key, params],
  });
};

export const useInfiniteProducts = (
  { enabled, ...params }: SearchAlgoliaProductsParams,
  key = ['infiniteProducts']
) => {
  return useInfiniteQuery({
    enabled,
    queryFn: async ({ pageParam = 0 }) => {
      const data = await getProducts({ ...params, page: pageParam });
      return {
        ...data,
        pageParam,
      };
    },
    queryKey: [...key, params],
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.page < lastPage.nbPages - 1;
      return hasNextPage ? lastPage.pageParam + 1 : undefined;
    },
  });
};

export interface UsePurchasedProducts {
  limit?: number;
  showUnavailable?: boolean;
  storeId?: Id;
  userId?: Id;
}

const enableWithStoreId = ({
  storeId,
}: Pick<UsePurchasedProducts, 'storeId'>) => !!storeId;

export const fetchPurchasedProducts = async ({
  limit,
  showUnavailable,
  storeId,
}: Omit<UsePurchasedProducts, 'userId'>) => {
  return await janeApi
    .get<{ purchased: PurchasedProduct[] }>(
      urls.purchasedProducts({ limit, showUnavailable, storeId }),
      getJwt()
    )
    .then((res) => res.purchased);
};

export const usePurchasedProducts = createUseQueryHook<
  UsePurchasedProducts,
  PurchasedProduct[]
>(fetchPurchasedProducts, queryKeys.purchasedProducts, enableWithStoreId);

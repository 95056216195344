import { useState } from 'react';

import { useMediaFormUtil } from '@jane/ad-manager/hooks';
import type { BrandMediaTag, MediaTagOption } from '@jane/ad-manager/types';
import { Button, Flex, Link, Modal, Typography } from '@jane/shared/reefer';

import { MaybeHidden } from './MaybeHidden';

interface Props {
  isSelected: boolean;
  mediaKey: string;
  mediaTagOptions: BrandMediaTag[];
  updateMediaTags: (mediaKey: string, tags: MediaTagOption[]) => void;
}

export const ImageComplianceSection = ({
  updateMediaTags,
  mediaKey,
  isSelected,
  mediaTagOptions,
}: Props) => {
  const { getMediaTags } = useMediaFormUtil();

  const selectedTags = getMediaTags(mediaKey).map(
    (selectedTag) => selectedTag.tag
  );

  const initialMediaTags = mediaTagOptions.map((tagOption) => {
    return {
      id: tagOption.id,
      tag: tagOption.tag,
      selected: selectedTags.includes(tagOption.tag),
    };
  });
  const [tags, setTags] = useState<MediaTagOption[]>(initialMediaTags);

  const calculateNewTags = (tagOption: MediaTagOption) => {
    return tags.map((item: MediaTagOption) =>
      item.tag === tagOption.tag
        ? {
            ...item,
            selected: !item.selected,
          }
        : item
    );
  };
  const toggleSelection = (tagOption: MediaTagOption) => {
    const newTags = calculateNewTags(tagOption);
    setTags(newTags);
    updateMediaTags(mediaKey, newTags);
  };

  return (
    <MaybeHidden isHidden={!isSelected}>
      <Modal.ContentDivider />
      <Flex flexDirection="column" gap={16}>
        <div>
          <Typography>Image compliance</Typography>
          <Typography color="grays-mid">
            Tag any of the following subjects or themes are present in this
            image.
            <Link href="https://docs.iheartjane.com/brand-partner-success/brand-registration-1/image-compliance-tags-+-faq">
              Learn more
            </Link>
          </Typography>
        </div>
        <Flex gap={16}>
          {tags.map((option: MediaTagOption) => (
            <Button
              key={option.tag}
              onClick={() => toggleSelection(option)}
              variant={option.selected ? 'tertiary-selected' : 'tertiary'}
              label={option.tag}
            ></Button>
          ))}
        </Flex>
      </Flex>
    </MaybeHidden>
  );
};

import { createContext, useContext } from 'react';

import type { TableProps } from './table.types';

export type TableSettingsContextProps = Pick<
  TableProps,
  'freezeFirstColumn' | 'freezeLastColumn'
>;

export const TableSettingsContext = createContext<TableSettingsContextProps>({
  freezeFirstColumn: false,
  freezeLastColumn: false,
});

export const useTableSettings = () => useContext(TableSettingsContext);

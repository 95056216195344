import { Button } from '@jane/shared/reefer';

interface Props {
  openAdBuilder: (open: boolean) => void;
}
export const AdActions = ({ openAdBuilder }: Props) => {
  return (
    <Button
      variant="primary"
      label="Create Ad"
      data-testid="create-ad"
      onClick={() => openAdBuilder(true)}
    />
  );
};

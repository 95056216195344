import { createContext, useContext } from 'react';

import type { TypeAheadContextProps } from './typeAhead.types';

export const TypeAheadContext = createContext<TypeAheadContextProps>({
  listAriaLabel: '',
  onSelection: () => {
    /** */
  },
  options: [],
});

export const useTypeAheadContext = () => useContext(TypeAheadContext);

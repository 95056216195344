import { Action } from '@thoughtspot/visual-embed-sdk';
import {
  LiveboardEmbed,
  useEmbedRef,
} from '@thoughtspot/visual-embed-sdk/react';

export const Dashboard = ({ liveboardId }: { liveboardId: string }) => {
  const embedRef = useEmbedRef<typeof LiveboardEmbed>();

  const visibleActions = [
    Action.Download,
    Action.DownloadAsPdf,
    Action.DownloadAsPng,
    Action.DownloadAsCsv,
    Action.DownloadAsXlsx,
  ];

  return (
    <LiveboardEmbed
      visibleActions={visibleActions}
      liveboardId={liveboardId}
      ref={embedRef}
      frameParams={{
        height: 'calc(100vh - 80px)',
        width: 'calc(100vw - 320px)',
      }}
    />
  );
};

import React from 'react';

import type { PopoverContextProps, PopoverProps } from '@jane/shared/reefer';
import {
  Button,
  ChevronDownIcon,
  DateTimeField,
  Link,
  List,
  Popover,
} from '@jane/shared/reefer';

import { toKebabCase } from './utils/toKebabCase';

export interface DateRangeProps {
  /** Current value of date range as a tuple */
  currentValue: Date[];
  /** Callback that is called when date range values change. Receives the
   * updated value after the field change has been applied */
  handleChange: (updatedValue: Date[]) => void;
  /** Text displayed on the filter */
  label: PopoverProps['label'];
  /** When true, will disable the end date input until there is a start date value. Defaults to false */
  requireStart?: boolean;
}

/**
 * Creates a popover with two date pickers to set a date range. Works like a
 * controlled input where the currentValues are used to set the field values and
 * the supplied handleChange function is called anytime the fields are updated.
 */

export const DateRange = ({
  label,
  currentValue,
  handleChange,
  requireStart = false,
}: DateRangeProps) => {
  const [start, end] = currentValue;
  const startDate = toDateString(start);
  const endDate = toDateString(end);

  const disableEndDate = requireStart && !start ? true : false;

  const handleDateChange = (dateString: string, index: 0 | 1) => {
    const updatedValue = [start, end];
    updatedValue[index] = new Date(dateString);
    handleChange(updatedValue);
  };

  return (
    <Popover
      target={
        <Button
          label={label}
          variant={currentValue.length === 0 ? 'tertiary' : 'tertiary-selected'}
          endIcon={<ChevronDownIcon color="inherit" size="sm" />}
        />
      }
      label={label}
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content>
          <List label={`${label} date range inputs`}>
            <List.Item>
              <DateTimeField
                label={'Start date'}
                name={toKebabCase(`${label}-start`)}
                onChange={(value) => {
                  handleDateChange(value, 0);
                }}
                defaultValue={startDate}
              />
            </List.Item>
            <List.Item>
              <DateTimeField
                label={'End date'}
                name={toKebabCase(`${label}-end`)}
                onChange={(value) => {
                  handleDateChange(value, 1);
                }}
                defaultValue={endDate}
                min={startDate}
                disabled={disableEndDate}
              />
            </List.Item>
            <List.Item>
              <Link
                onClick={() => {
                  handleChange([]);
                  closePopover();
                }}
              >
                Clear dates
              </Link>
            </List.Item>
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};

// Converts Date object to date string in formate expected by DateTimeField 'YYYY-MM-DD'
const toDateString = (date?: Date) =>
  date ? date.toISOString().slice(0, 10) : '';

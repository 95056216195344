import { useCallback, useState } from 'react';

import { useSaveDraft } from '@jane/ad-manager/data-access';
import type { BrandTheme } from '@jane/ad-manager/types';
import { sanitizeDescription } from '@jane/ad-manager/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { Form, Modal } from '@jane/shared/reefer';

interface Props {
  brandTheme: BrandTheme;
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const DescriptionModal = ({ open, setOpen, brandTheme }: Props) => {
  const { mutate: saveDraft } = useSaveDraft();
  const [isSaving, setIsSaving] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const { description } = brandTheme;
  const fieldName = 'brandDescription';

  const onSubmit = async (data: Record<string, unknown>) => {
    const description = sanitizeDescription(data[fieldName] as string);
    const newBrandTheme = { ...brandTheme, description };
    setIsSaving(true);
    saveDraft(newBrandTheme, {
      onSettled: () => setIsSaving(false),
      onSuccess: () => setOpen(false),
    });
  };

  const onRequestClose = useCallback(() => {
    if (!isSaving) setOpen(false);
  }, [isSaving, setOpen]);

  return (
    <ConfirmDiscardWrapper
      appId="root"
      open={open}
      setOpen={onRequestClose}
      variant="standard-dialogue"
      hasChanges={formIsDirty}
    >
      <Form
        name="brand-theme-assets"
        onSubmit={onSubmit}
        onDirty={setFormIsDirty}
      >
        <Modal.Header
          title="Edit description"
          actions={
            <Form.SubmitButton
              variant="primary"
              label="Save"
              loading={isSaving}
            />
          }
        />
        <Modal.Content>
          <Form.TextAreaField
            name={fieldName}
            label=""
            labelHidden
            defaultValue={description}
            rows={6}
            maxLength={1000}
            disabled={isSaving}
          />
        </Modal.Content>
      </Form>
    </ConfirmDiscardWrapper>
  );
};

import isEqual from 'lodash/isEqual';

/**
 * Utility that reconciles localization value with product value to decide if the
 * localization value should be set to 'null' which means fallback to product.
 */
export const reconcileValue = <T extends string | string[]>(
  localizationValue: T,
  productValue: T | null
): T | null => {
  if (
    Array.isArray(localizationValue) &&
    isEqual(localizationValue, productValue)
  ) {
    return null;
  }

  if (localizationValue === '' && productValue === null) {
    return null;
  }

  if (localizationValue === productValue) {
    return null;
  }

  return localizationValue;
};

/**
 * Utility that takes the output from the edit localization form and converts it
 * to the format expected by the update localization function. This also
 * compares the form data with the parent product replacing any matching values
 * with 'null' which signifies a field that should fallback to the parent
 * product's data.
 */
import type {
  EditFormProduct,
  UpdateLocalizationParams,
} from '@jane/catalog-cms/data-access';
import { reconcileValue } from '@jane/catalog-cms/util';
import { FormValidationError } from '@jane/shared/reefer';

import type { EditLocalizationFormData } from '../EditLocalizationForm';

export const toUpdateLocalizationParams = (
  data: EditLocalizationFormData,
  localizationId: string,
  parentProduct: EditFormProduct
): UpdateLocalizationParams => {
  const name = reconcileValue(data.name.trim(), parentProduct.name);
  const lineage = reconcileValue(data.lineage, parentProduct.lineage);
  const categoryLabel = reconcileValue(
    data.categoryLabel.trim(),
    parentProduct.categoryLabel
  );
  const description = reconcileValue(
    data.description.trim(),
    parentProduct.description
  );
  const images = reconcileValue(data.imageURLs, parentProduct.imageURLs);

  return {
    productUUID: parentProduct.id,
    localizationId,
    name,
    lineage,
    description,
    categoryLabel,
    images,
  };
};

const UPDATE_FIELDS: (keyof UpdateLocalizationParams)[] = [
  'name',
  'lineage',
  'categoryLabel',
  'description',
  'images',
];

/**
 * Validates data for the edit localization form
 */

export const validateUpdateData = (
  data: Record<string, unknown>,
  parentProduct: EditFormProduct
) => {
  const errors = [];

  if (UPDATE_FIELDS.every((key) => data[key as string] === null)) {
    errors.push({
      name: 'form',
      message:
        'One field must differ from the product. Remove this state if its custom content should no longer exist.',
    });
  }

  if (parentProduct.category?.match(/^flower$/i) && data['lineage'] === '') {
    errors.push({
      name: 'form',
      message: 'Lineage is required for products with category: Flower',
    });
  }

  if (data['name'] === '') {
    errors.push({
      name: 'name',
      message:
        'Cannot be blank, revert to default if state specific name should not differ from the product name',
    });
  }

  if (data['description'] === '') {
    errors.push({
      name: 'description',
      message:
        'Cannot be blank, revert to default if state specific description should not differ from the product description.',
    });
  }

  if (errors.length > 0) {
    throw new FormValidationError('edit-localization', errors);
  }
};

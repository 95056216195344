export const storeIdConversions = {
  asString: (id: number | string) => (typeof id === 'number' ? `${id}` : id),
  asNumber: (id: number | string) =>
    typeof id === 'string' ? parseInt(id, 10) : id,
  fromStrings: (ids: string): string[] => {
    if (!ids.trim()) return [];

    // First, protect numbers with commas by replacing them with a placeholder
    const numericIds = ids.replace(/\b\d{1,3}(,\d{3})+\b/g, (match) =>
      match.replace(/,/g, '')
    );

    // Then split by delimiters
    const splitIds = numericIds.split(/\s*,\s*|\s+/);

    return splitIds.map((id) => id.trim()).filter(Boolean); // Remove empty strings
  },
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const External24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="External 24">
      <g className="Union">
        <path
          fill="#000"
          d="M4 5a1 1 0 0 1 1-1h5a1 1 0 1 0 0-2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-5a1 1 0 1 0-2 0v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5Z"
        />
        <path
          fill="#000"
          d="M14 2a1 1 0 1 0 0 2h4.586l-7.293 7.293a1 1 0 0 0 1.414 1.414L20 5.414V10a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1h-7Z"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: External24,
  sm: External24,
  lg: External24,
  xl: External24,
  xxl: External24,
  xxxl: External24,
};

export const ExternalIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'external-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useRouteError } from 'react-router-dom';

import { useBrandsNavigate } from '@jane/brands/hooks';
import { useSpecialId } from '@jane/gold-manager/hooks';
import { ApiRequestError } from '@jane/shared/data-access';
import { Box, Button, ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

export const SpecialError = () => {
  const brandsNavigate = useBrandsNavigate();
  const specialId = useSpecialId();
  const error = useRouteError();

  let title = 'Oops!';
  let subtitle = 'There was an unexpected error when retrieving this special.';
  let action =
    'We could be experiencing a temporary problem or you may be having network issues.';
  let help = "If this issue doesn't resolve itself please contact support.";

  if (error instanceof ApiRequestError && error.response.status === 404) {
    title = 'Special not found';
    subtitle = `We couldn't find a special with ID "${specialId}".`;
    action = 'Try selecting another special by returning to the specials list.';
    help = '';
  }

  return (
    <Box p={40}>
      <ErrorIcon size="xxl" color="error" mx="auto" mb={16} />
      <Typography mb={8} variant="title-bold" as="h2" textAlign="center">
        {title}
      </Typography>
      <Typography variant="body-bold" color="text-light" textAlign="center">
        {subtitle}
      </Typography>
      <Typography color="text-light" textAlign="center">
        {action}
      </Typography>
      <Flex width="100%" justifyContent="center" py={16} gap={16}>
        <Button
          label="View all specials"
          variant="secondary"
          onClick={() => brandsNavigate.specialsList()}
        />
        <Button label="Try again" onClick={() => window.location.reload()} />
      </Flex>
      <Typography color="text-light" textAlign="center">
        {help}
      </Typography>
    </Box>
  );
};

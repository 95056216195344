import { useEffect } from 'react';
import { TrackJS } from 'trackjs';

export const useIdentifyTrackJsUser = (email?: string) => {
  // Updates the userId for trackJS error reporting
  useEffect(() => {
    if (TrackJS.isInstalled()) {
      TrackJS?.configure({ userId: email || '' });
    }
  }, [email]);
};

import { useQuery } from '@tanstack/react-query';

import { fetchLocalization } from '@jane/catalog-cms/data-access';

export const localizationQueryKey = (
  productId: string,
  localizationId: string
): ['localization', { localizationId: string; productId: string }] => {
  return ['localization', { localizationId, productId }];
};

export const useLocalization = (productId: string, localizationId: string) => {
  return useQuery({
    queryFn: fetchLocalization,
    queryKey: localizationQueryKey(productId, localizationId),
  });
};

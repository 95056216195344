import styled from '@emotion/styled';

import { Flex, InfoIcon, Typography } from '@jane/shared/reefer';

const Container = styled.div(({ theme }) => ({
  background: theme.colors.grays.ultralight,
  borderRadius: theme.borderRadius.sm,
  padding: 24,
  paddingRight: 72,
  width: 'fit-content',
}));

export const BrandThemesFooter = () => {
  return (
    <Container>
      <Flex flexDirection="row" alignItems="center" gap={24}>
        <InfoIcon />
        <Typography textAlign="left">
          Promote your brand with brand pages on Jane’s marketplace.
          <br />
          Customize your brand page themes and shopping experience to stand out
          to your customers and boost sales.
        </Typography>
      </Flex>
    </Container>
  );
};

import styled from '@emotion/styled';
import { useState } from 'react';

import { Accordion } from '@jane/shared/components';
import { Flex, Form, Typography } from '@jane/shared/reefer';

import type { SelectionModeOptions } from './AdProductsSection';

const PlacementAccordionHeading = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

const productSelectorOptions = [
  {
    label: 'Automatically',
    id: 'automatic',
    value: 'automatic',
  },
  {
    label: 'Automatically by category',
    id: 'category',
    value: 'category',
  },
  {
    label: 'Manually',
    id: 'manual',
    value: 'manual',
  },
];

interface Props {
  selectionMode: SelectionModeOptions;
  setSelectionMode: (mode: SelectionModeOptions) => void;
}

export const ProductTargetingModeSelector = ({
  selectionMode,
  setSelectionMode,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(selectionMode !== 'automatic');
  const selectedModeText = () => {
    switch (selectionMode) {
      case 'manual':
        return 'Manually';
      case 'category':
        return 'Automatically by category';
      default:
        return 'Automatically';
    }
  };

  return (
    <Accordion>
      <Accordion.Item id="product-selector" expanded={isExpanded}>
        <Accordion.Heading onClick={() => setIsExpanded(!isExpanded)}>
          <PlacementAccordionHeading>
            <Flex justifyContent="space-between">
              <Typography variant="body-bold">Select products</Typography>
              <Accordion.Indicator size={18} />
            </Flex>
            <Typography variant="body">{selectedModeText()}</Typography>
          </PlacementAccordionHeading>
        </Accordion.Heading>
        <Accordion.Content>
          <Form.RadioFieldGroup
            name="_productTargetingOption"
            options={productSelectorOptions}
            onChange={setSelectionMode}
            defaultChecked={selectionMode}
            mt={32}
            ml={4}
          />
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
};

import styled from '@emotion/styled';

import { Button, DragIcon, Image, Tag, TrashIcon } from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

export interface ProductImageProps {
  handleRemove: (url: string) => void;
  index: number;
  src: string;
}

export const ProductImage = ({
  src,
  index,
  handleRemove,
}: ProductImageProps) => {
  const filename = new URL(src).pathname.slice(1);
  const position = `${index + 1}`;
  return (
    <div style={{ position: 'relative' }}>
      <Image
        src={src}
        altText={filename}
        borderRadius="rounded"
        border
        aspectRatio="1"
      />
      <StyledTag label={position} background="grays-mid" />
      <StyledRemoveButton
        icon={<TrashIcon />}
        variant="tertiary"
        label={`Remove ${filename} from product images`}
        onClick={() => handleRemove(src)}
      />
      <StyledDragIcon color="grays-mid" />
    </div>
  );
};

const INSET = '16px';

const StyledTag = styled(Tag)({
  position: 'absolute',
  bottom: INSET,
  right: INSET,
  boxShadow: shadows.drop,
});

const StyledRemoveButton = styled(Button.Icon)({
  position: 'absolute',
  top: INSET,
  right: INSET,
});

const StyledDragIcon = styled(DragIcon)({
  position: 'absolute',
  bottom: INSET,
  left: INSET,
  cursor: 'grab',
});

import { z } from 'zod';

const ApiSegmentSchema = z.object({
  id: z.number(),
  description: z.string(),
});

export const ApiSegmentsSchema = z.object({
  segments: z.array(ApiSegmentSchema),
});

export type ApiSegments = z.infer<typeof ApiSegmentsSchema>;

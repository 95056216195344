// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NVR7MiiS8VQQOR0DXRfi {\n  --frozen-column-box-shadow: #00000008;\n\n  background-color: var(--colors-grays-white);\n  border-collapse: separate;\n  border-spacing: 0;\n  display: table;\n  height: 100%;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  max-width: var(--table-max-width);\n  min-width: var(--table-min-width);\n  position: relative;\n  table-layout: fixed;\n  white-space: nowrap;\n  width: 100%;\n}\n\n.pY1moBQ6tFMTy_rwZ29f {\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/table.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;;EAErC,2CAA2C;EAC3C,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,iCAAiC;EACjC,iCAAiC;EACjC,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".table {\n  --frozen-column-box-shadow: #00000008;\n\n  background-color: var(--colors-grays-white);\n  border-collapse: separate;\n  border-spacing: 0;\n  display: table;\n  height: 100%;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  max-width: var(--table-max-width);\n  min-width: var(--table-min-width);\n  position: relative;\n  table-layout: fixed;\n  white-space: nowrap;\n  width: 100%;\n}\n\n.table__scrollable {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "NVR7MiiS8VQQOR0DXRfi",
	"table__scrollable": "pY1moBQ6tFMTy_rwZ29f"
};
export default ___CSS_LOADER_EXPORT___;

/**
 * Given a File object and an array of MIME types will return true if the File's
 * type matches any allowed Mime types. Note that this does not check the actual
 * contents of the file, uses `File.type` which only uses the file's extension
 * to determine its
 * @example
 * // returns 'true' if userFile is a jpeg image.
 * const isJpeg = validateFileType(userFile, ['image/jpeg'])
 */

export const validateFileType = (
  file: File,
  allowedTypes: string[]
): boolean => {
  return allowedTypes.includes(file.type);
};

interface SizeValidationOpts {
  maxSize?: number;
  minSize?: number;
}

/**
 * Given a file and size options, this will return true if the file meets the
 * given size constrains.
 */
export const validateFileSize = (
  file: File,
  { minSize, maxSize }: SizeValidationOpts
): boolean => {
  if (minSize === undefined && maxSize === undefined) {
    throw new TypeError(
      'validateFileSize was called without a "minSize" and "maxSize" option. At least one of these options must be specified.'
    );
  }

  const max = maxSize ?? Infinity;
  const min = minSize ?? 0;
  return file.size >= min && file.size < max;
};

const ASPECT_RATIO_VALUES = {
  '1:1': { w: 1, h: 1 },
  '16:9': { w: 16, h: 9 },
  '9:16': { w: 9, h: 16 },
  '3:1': { w: 3, h: 1 },
  '4:1': { w: 4, h: 1 },
} as const;

export type AspectRatios = keyof typeof ASPECT_RATIO_VALUES;

/**
 * Given an HTMLImageElement (Image) this will verify that its naturalHeight and
 * naturalWidth conform to the allowed aspect ratios provided, returning a
 * boolean of the result. Aspect ratios are strings formatted as
 * '{width}:{height}'. Ex "1:1" or "9:16". Supported aspect ratios are specified
 * in the AspectRatios type.
 * @example
 * const hasValidAspectRatio = validateAspectRatio(image, ['1:1', '16:9'])
 * if (hasValidAspectRatio) {
 *   console.log('Acceptable aspect ratio')
 * } else {
 *   console.log('Invalid aspect ratio')
 * }
 */

export const validateAspectRatio = (
  image: HTMLImageElement,
  allowedRatios: AspectRatios[]
): boolean => {
  if (allowedRatios.length === 0) {
    throw new TypeError(
      `Cannot validate image aspect ratio, 'validateAspectRatio' was called with a value of '[]' for 'allowedRatios'. Please specify at least one aspect ratio to validate.`
    );
  }

  const imageWidth = image.naturalWidth;
  const imageHeight = image.naturalHeight;

  if (imageWidth === 0 || imageHeight === 0) {
    return false;
  }

  return allowedRatios.some((ratio) => {
    const { w, h } = ASPECT_RATIO_VALUES[ratio];
    const result =
      imageWidth === Math.round(imageHeight * (w / h)) &&
      imageHeight === Math.round(imageWidth * (h / w));

    return result;
  });
};

export const validateDimensions = (
  image: HTMLImageElement,
  { min, max }: { max?: number; min?: number }
): boolean => {
  if (min === undefined && max === undefined) {
    throw new TypeError(
      'validateDimensions was called without a "min" and "max" option. At least one of these options must be specified.'
    );
  }

  const minDimension = min || 1;
  const maxDimension = max || Infinity;
  const { naturalHeight, naturalWidth } = image;

  const smallestDim = Math.min(naturalHeight, naturalWidth);
  const largestDim = Math.max(naturalHeight, naturalWidth);

  const isValid = smallestDim >= minDimension && largestDim <= maxDimension;
  return isValid;
};

/**
 * This error is thrown when there is an error in the image data that prevents
 * the browser from correctly reading the file as an image.
 */
export class InvalidImageError extends Error {
  filename?: string;
  constructor(message: string, filename?: string) {
    super(message);
    this.filename = filename;
  }
}

interface FileToImageResult {
  error?: unknown;
  image?: HTMLImageElement;
}

/**
 * Given a File this function will return a Promise that resolves to a result
 * object with an 'image' property containing an Image instance using the
 * provided File as its source. If there are any errors in loading the File as
 * an Image the result then the 'image' property on the result object will be
 * undefined and the 'error' property will have the Error.
 * @example
 * const {image, error} = await fileToImage(myFile)
 *
 * if(error && error instance of Error) {
 *   console.log(error.message)
 *   doSomethingWithError(error)
 * }
 *
 * if(image) {
 *   doSomethingWithImage(image)
 * }
 *
 */
export const fileToImage = async (file: File): Promise<FileToImageResult> => {
  try {
    const dataURL = await new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        if (fileReader.error) {
          reject(fileReader.error);
        } else if (typeof fileReader.result !== 'string') {
          reject(
            new InvalidImageError(
              `File '${file.name}' could not be read as an image.`,
              file.name
            )
          );
        } else {
          resolve(fileReader.result);
        }
      };
      fileReader.readAsDataURL(file);
    });

    const image = await new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        resolve(image);
      };
      image.onerror = (e) => {
        reject(
          new InvalidImageError(
            `File '${file.name}' could not be loaded correctly.`,
            file.name
          )
        );
      };
    });

    return { image };
  } catch (error) {
    return { error };
  }
};

export type BrandPermission =
  | 'accessAnalytics'
  | 'accessDigitalMerchandising'
  | 'manageUsers'
  | 'accessBrandPages';

export type BrandRole =
  | 'analytics:external'
  | 'brand_page:external'
  | 'catalog:internal'
  | 'catalog:external'
  | 'dm:internal'
  | 'dm:external'
  | 'catalog_cms:admin'
  | 'dm:admin'
  | 'super_admin';

type BrandPermissionMap = {
  [key in BrandRole]: BrandPermission[];
};

export const BRAND_PERMISSION_MAP: BrandPermissionMap = {
  'analytics:external': ['accessAnalytics'],
  'brand_page:external': ['accessBrandPages'],
  'catalog_cms:admin': ['manageUsers'],
  'catalog:internal': ['manageUsers'],
  'catalog:external': [],
  'dm:admin': ['manageUsers', 'accessDigitalMerchandising', 'accessBrandPages'],
  'dm:internal': [
    'manageUsers',
    'accessDigitalMerchandising',
    'accessBrandPages',
  ],
  'dm:external': ['accessDigitalMerchandising'],
  super_admin: [
    'manageUsers',
    'accessAnalytics',
    'accessDigitalMerchandising',
    'accessBrandPages',
  ],
};

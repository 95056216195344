const LOGIN_PATH = '/login';
const WHOAMI_PATH = '/whoami';
const LOGOUT_PATH = '/logout';
const PRODUCTS_PATH = '/products';
const CATEGORIES_PATH = '/categories';
const CATEGORY_LABELS_PATH = '/category-labels';
const BRANDS_PATH = '/brands';
const PASSWORD_RESET = '/password-reset';
const THOUGHTSPOT_TOKEN_PATH = '/thoughtspot/tokens';
const THOUGHTSPOT_LIVEBOARDS_PATH = '/thoughtspot/liveboards';

/**
 * Helper functions to build routes on the product branding server.
 */
export const brandServerPaths = {
  brands() {
    return BRANDS_PATH;
  },
  brandSearch() {
    return '/brand_search';
  },
  categories() {
    return CATEGORIES_PATH;
  },
  categoryLabels() {
    return CATEGORY_LABELS_PATH;
  },
  localizationCreate(productUuid: string) {
    return `${PRODUCTS_PATH}/${productUuid}/localizations`;
  },
  localization(productUuid: string, localizationId: string) {
    return `${PRODUCTS_PATH}/${productUuid}/localizations/${localizationId}`;
  },
  login() {
    return LOGIN_PATH;
  },
  logout() {
    return LOGOUT_PATH;
  },
  passwordReset() {
    return PASSWORD_RESET;
  },
  updatePassword(token: string) {
    return `${PASSWORD_RESET}/${token}`;
  },
  product(uuid: string) {
    return `${PRODUCTS_PATH}/${uuid}`;
  },
  products() {
    return PRODUCTS_PATH;
  },
  productsSearch() {
    return PRODUCTS_PATH + '/search';
  },
  productEditForm(uuid: string) {
    return `${PRODUCTS_PATH}/${uuid}/edit-form`;
  },
  productNotice(productUuid: string) {
    return `${PRODUCTS_PATH}/${productUuid}/notice`;
  },
  thoughtSpotLiveboardsPath() {
    return THOUGHTSPOT_LIVEBOARDS_PATH;
  },
  thoughtSpotToken() {
    return THOUGHTSPOT_TOKEN_PATH;
  },
  whoami() {
    return WHOAMI_PATH;
  },
};

import type { UsePurchasedProducts } from './products';

export const urls = {
  purchasedProducts: ({
    limit,
    showUnavailable,
    storeId,
  }: Omit<UsePurchasedProducts, 'userId'>) => {
    const params = new URLSearchParams({
      ...(limit !== undefined && { limit: String(limit) }),
      ...(showUnavailable && {
        available_only: 'false',
      }),
    });

    return `/stores/${storeId}/menu_products/purchased?${params.toString()}`;
  },
};

export const queryKeys = {
  purchasedProducts: (params: UsePurchasedProducts) => [
    'purchasedProducts',
    params,
  ],
};

import { Flex, Typography } from '@jane/shared/reefer';

export const Error = () => {
  return (
    <Flex flexDirection="column" justifyContent="center" height="100%">
      <Typography textAlign="center" variant="header-bold">
        An unexpected error has occurred!
      </Typography>
    </Flex>
  );
};

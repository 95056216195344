// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LWrd8VxatH8xQT1K8GoX {\n  align-content: var(--flex-align-content);\n  align-items: var(--flex-align-items);\n  align-self: var(--flex-align-self);\n  display: flex;\n  flex: var(--flex);\n  flex-direction: var(--flex-direction);\n  flex-wrap: var(--flex-wrap);\n  gap: var(--flex-gap);\n  justify-content: var(--flex-justify-content);\n  justify-items: var(--flex-justify-items);\n  justify-self: var(--flex-justify-self);\n}\n\n.AMYUNgJutW0OKeow66ZA {\n  display: inline-flex;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/flex/flex.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,oCAAoC;EACpC,kCAAkC;EAClC,aAAa;EACb,iBAAiB;EACjB,qCAAqC;EACrC,2BAA2B;EAC3B,oBAAoB;EACpB,4CAA4C;EAC5C,wCAAwC;EACxC,sCAAsC;AACxC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".flex {\n  align-content: var(--flex-align-content);\n  align-items: var(--flex-align-items);\n  align-self: var(--flex-align-self);\n  display: flex;\n  flex: var(--flex);\n  flex-direction: var(--flex-direction);\n  flex-wrap: var(--flex-wrap);\n  gap: var(--flex-gap);\n  justify-content: var(--flex-justify-content);\n  justify-items: var(--flex-justify-items);\n  justify-self: var(--flex-justify-self);\n}\n\n.flex__inline {\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": "LWrd8VxatH8xQT1K8GoX",
	"flex__inline": "AMYUNgJutW0OKeow66ZA"
};
export default ___CSS_LOADER_EXPORT___;

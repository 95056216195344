// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d0EC0937xEDrXeMUvrxq {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  min-height: 80px;\n  box-shadow: var(--shadows-divider);\n  padding-top: var(--padding-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n}\n\n@media (min-width: 768px) {\n  .sKwo2KoNPLk5pSD3mkH6 {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/modal/modalHeader/modalHeader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,kCAAkC;EAClC,+BAA+B;EAC/B,qCAAqC;EACrC,iCAAiC;EACjC,mCAAmC;AACrC;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":[".modalHeader {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  min-height: 80px;\n  box-shadow: var(--shadows-divider);\n  padding-top: var(--padding-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n}\n\n@media (min-width: 768px) {\n  .modalHeader__full-screen {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "d0EC0937xEDrXeMUvrxq",
	"modalHeader__full-screen": "sKwo2KoNPLk5pSD3mkH6"
};
export default ___CSS_LOADER_EXPORT___;

import type * as ReactTable from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { Storage } from '@jane/shared/util';

const defaultColumnVisibilityState: ReactTable.VisibilityState = {
  states: true,
  stores: false,
  startDate: true,
  endDate: true,
  model: true,
  products: false,
  placement: false,
  dailyBudget: true,
  spend: true,
  bid: true,
  frequencyCap: true,
  impressions: true,
  displaysOn: false,
};

const JANE_AD_MANAGER_COLUMN_VISIBILITY_KEY =
  'jane-ad-manager-column-visibility';

const getColVisibilityFromStorage = () => {
  const colVisJson = Storage.get(JANE_AD_MANAGER_COLUMN_VISIBILITY_KEY);
  if (!colVisJson) return;
  try {
    return JSON.parse(colVisJson);
  } catch (e) {
    return;
  }
};

export const useColumnVisibilityInStorage = () => {
  const [columnVisibility, setColumnVisibility] = useState(
    getColVisibilityFromStorage() || defaultColumnVisibilityState
  );

  useEffect(() => {
    try {
      Storage.set(
        JANE_AD_MANAGER_COLUMN_VISIBILITY_KEY,
        JSON.stringify(columnVisibility)
      );
    } catch (e) {
      return;
    }
  }, [columnVisibility]);

  return [columnVisibility, setColumnVisibility];
};

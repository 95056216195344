/**
 * Given a date object, return a short formatted date with time. Ex. 12/06/21, 04:17 PM
 */
export const shortDateTimeFormat = (date: Date): string => {
  return new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

/**
 * Given a date object, return a short formatted date. Ex. 12/06/21
 */
export const shortDateFormat = (date: Date): string => {
  return new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

import { useParams } from 'react-router-dom';

/**
 * Small convenience wrapper around useParams() that will return the :specialId
 * param or will throw an error. This is to avoid having to deal with the
 * default param typing of string | undefined in components that should
 * never be called when specialId === undefined.
 */

export const useSpecialId = () => {
  const { specialId } = useParams();

  if (specialId === undefined) {
    throw new TypeError(
      [
        'URL parameter "specialId" is "undefined". Make sure the route calling',
        'this component specifies a parameter called ":specialId" and that the URL',
        'has a value for the parameter.',
      ].join(' ')
    );
  }

  return specialId;
};

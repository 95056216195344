import type { CustomMedia } from '@jane/ad-manager/types';

import { makeMediaKey } from './mediaKey';

type Prefix = { media: CustomMedia } | { mediaKey: string };
export const mediaFormKeys = {
  orderedMediaKeys: 'orderedMediaKeys',
  colorHex: 'colorHex',
  imageImageUrl: (prefix: Prefix) =>
    makeFormKey({ prefix, suffix: 'imageUrl' }),
  videoOverlayImageUrl: (prefix: Prefix) =>
    makeFormKey({ prefix, suffix: 'overlayImageUrl' }),
  videoOverlayText: (prefix: Prefix) =>
    makeFormKey({ prefix, suffix: 'overlayText' }),
  videoVideoUrl: (prefix: Prefix) =>
    makeFormKey({ prefix, suffix: 'videoUrl' }),
  id: (prefix: Prefix) => makeFormKey({ prefix, suffix: 'id' }),
  position: (prefix: Prefix) => makeFormKey({ prefix, suffix: 'position' }),
  brandMediaTags: (prefix: Prefix) =>
    makeFormKey({ prefix, suffix: 'mediaTags' }),
  _destroy: (prefix: Prefix) => makeFormKey({ prefix, suffix: '_destroy' }),
  _mediaType: (prefix: Prefix) => makeFormKey({ prefix, suffix: '_mediaType' }),
};

interface MakeFormKeyArgs {
  prefix: Prefix;
  suffix: string;
}
const makeFormKey = ({ prefix, suffix }: MakeFormKeyArgs): string => {
  let mediaKey;
  if ('media' in prefix) mediaKey = makeMediaKey(prefix.media);
  else mediaKey = prefix.mediaKey;
  return `${mediaKey}_${suffix}`;
};

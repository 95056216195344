const buildURL = (baseURL: string, file: File): string => {
  const url = new URL(baseURL, document.location.href);

  const imageTypeMatch = file.type?.match(/^image\/([^;]+)/);
  const filename = imageTypeMatch ? 'image.' + imageTypeMatch[1] : 'image';

  url.searchParams.append('type', file.type);
  url.searchParams.append('filename', filename);

  return url.toString();
};

type Fields = {
  [key: string]: any;
  key: string;
};

type PresignResponse = {
  [key: string]: any;
  fields: Fields;
  overrideBaseURL?: string | null;
  url: string;
};

export const uploadFileToS3 = async (
  presignBaseURL: string,
  file: File,
  options?: RequestInit
): Promise<string> => {
  const presignURL = buildURL(presignBaseURL, file);

  const response = await fetch(presignURL, options);
  const {
    fields,
    url: bucketUploadURL,
    overrideBaseURL,
  }: PresignResponse = await response.json();

  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });
  formData.append('file', file);

  await fetch(bucketUploadURL, {
    method: 'POST',
    body: formData,
    credentials: 'omit',
  });

  if (overrideBaseURL) {
    return overrideBaseURL.replace(/\/$/, '') + '/' + fields.key;
  }

  const awsBucketMatch = bucketUploadURL.match(
    /^https:\/\/s3(?:\.[^.]+)?\.amazonaws\.com\/([^./]+\.[^/]+)\/?$/
  );
  if (awsBucketMatch?.[1]) {
    return 'https://' + awsBucketMatch[1] + '/' + fields.key;
  }
  return bucketUploadURL + '/' + fields.key;
};

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';

export const removeLocalization = async ({
  productUUID,
  localizationId,
}: {
  localizationId: string;
  productUUID: string;
}) => {
  return await brandsApi.delete(
    brandServerPaths.localization(productUUID, localizationId)
  );
};

import { useParams } from 'react-router-dom';

/**
 * Small convenience wrapper around useParams() that will return the
 * :localizationId param or will throw an error. This is to avoid having to deal
 * with the default param typing of string | undefined in components that should
 * never be called when localizationId === undefined.
 */

export const useLocalizationId = () => {
  const { localizationId } = useParams();

  if (localizationId === undefined) {
    throw new TypeError(
      [
        'URL parameter "localizationId" is "undefined". Make sure the route calling',
        'this component specifies a parameter called ":localizationId" and that the URL',
        'has a value for the parameter.',
      ].join(' ')
    );
  }

  return localizationId;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yv6CFAGq2gMhhLlf5OCu {\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: var(--max-height);\n}\n\n@media (min-width: 768px) {\n  .yv6CFAGq2gMhhLlf5OCu {\n    max-height: var(--max-height-desktop);\n  }\n}\n\n.yv6CFAGq2gMhhLlf5OCu::-webkit-scrollbar {\n  display: none;\n}\n\n.OR1NLvRa5k1lyyNKPaIL {\n  padding: 24px;\n}\n\n@media (min-width: 768px) {\n  .OR1NLvRa5k1lyyNKPaIL {\n    padding: 16px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/popover/popoverContent/popoverContent.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".popover_content {\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: var(--max-height);\n}\n\n@media (min-width: 768px) {\n  .popover_content {\n    max-height: var(--max-height-desktop);\n  }\n}\n\n.popover_content::-webkit-scrollbar {\n  display: none;\n}\n\n.popover_content__padding {\n  padding: 24px;\n}\n\n@media (min-width: 768px) {\n  .popover_content__padding {\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover_content": "yv6CFAGq2gMhhLlf5OCu",
	"popover_content__padding": "OR1NLvRa5k1lyyNKPaIL"
};
export default ___CSS_LOADER_EXPORT___;

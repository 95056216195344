import { useMemo } from 'react';

import { useCategories, useProductFilters } from '@jane/catalog-cms/hooks';

import Filters from '../Filters';

export const Category = () => {
  const { data, isLoading } = useCategories();
  const { filters, updateFilters } = useProductFilters();

  const options = useMemo(() => {
    if (data === undefined) return [];
    return data.map((category) => ({ label: category, value: category }));
  }, [data]);

  return (
    <Filters.Multiselect
      label="Category"
      options={options}
      optionsLoading={isLoading}
      currentValue={filters.category || []}
      handleChange={(filterValue) => updateFilters('category', filterValue)}
    />
  );
};

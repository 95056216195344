import { useEffect } from 'react';
import type { To } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuth } from '@jane/brands/hooks';
import { brandsPaths } from '@jane/brands/util';

import { Loading } from '../util/Loading';

/**
 * This component is meant to be used in a router and should wrap publicly
 * accessible pages that when visited by an authenticated user should redirect them
 * to a different page. The original use case was to wrap the Login page so that an
 * authenticated user is redirected to the products page.
 *
 * The redirect defaults to the products page but It accepts an optional 'to' property
 * that can overwrite that.
 * @example
 * // Login route object wrapped with RedirectAuthed
 * {
 *  element: <RedirectAuthed to={'/products'}/>,
 *  children: [
 *    {
 *      path: '/login',
 *      element: <Login />,
 *    },
 *  ],
 *},
 */

export const RedirectAuthed = ({ to }: { to?: To }) => {
  const { userQuery } = useAuth();
  const navigate = useNavigate();
  const user = userQuery.data;

  useEffect(() => {
    if (userQuery.isFetched && user) {
      navigate(to || brandsPaths.products(), { replace: true });
    }
  }, [navigate, to, user, userQuery.isFetched]);

  return !user && userQuery.isFetched ? (
    <Outlet />
  ) : (
    <Loading data-testid="loader" fullPage />
  );
};

import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { createContext, useMemo, useState } from 'react';

type NextProductIdContext =
  | undefined
  | {
      nextProductId?: string | null;
      setNextProductId: Dispatch<SetStateAction<string | undefined | null>>;
    };

export const NextProductIdContext =
  createContext<NextProductIdContext>(undefined);

export const NextProductIdProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [nextProductId, setNextProductId] = useState<string | undefined | null>(
    undefined
  );

  const value = useMemo(() => {
    return {
      nextProductId,
      setNextProductId,
    };
  }, [nextProductId]);

  return (
    <NextProductIdContext.Provider value={value}>
      {children}
    </NextProductIdContext.Provider>
  );
};

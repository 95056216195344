import { Outlet } from 'react-router-dom';

import { Box } from '@jane/shared/reefer';

import { BrandPortalNavBar } from './BrandPortalNavBar/BrandPortalNavBar';

export const BrandPortalLayout = () => {
  return (
    <div style={{ minHeight: '100vh', backgroundColor: 'white' }}>
      <Box as={'header'}>
        <BrandPortalNavBar />
      </Box>
      <Box as={'main'}>
        <Outlet />
      </Box>
    </div>
  );
};

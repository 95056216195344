import { HttpResponse, http } from 'msw';

import { config } from '@jane/shared/config';

import type {
  ApiBrandThemeAndMediaTagsIncoming,
  ApiBrandThemeIncoming,
} from './types';

export const makeBrandThemeResponse = ({
  draft,
}: {
  draft: boolean;
}): ApiBrandThemeAndMediaTagsIncoming => {
  const body: ApiBrandThemeAndMediaTagsIncoming = {
    media_tags: [],
    product_brand_theme: {
      color_hex: null,
      custom_image_url: null,
      description: null,
      draft: false,
      logo_url: null,
      pbt_custom_images: [],
      pbt_custom_video: null,
      product_brand: {
        name: 'Brand 1',
        normalized_name: 'brand_1',
      },
      product_brand_id: 1,
      rank: null,
      updated_at: '2023-04-20T00:00:00Z',
    },
  };
  body.product_brand_theme.draft = draft;
  return body;
};

const applyProductBrandToBrandThemeResponse = ({
  id,
  response,
}: {
  id: number;
  response: ApiBrandThemeAndMediaTagsIncoming;
}) => {
  return {
    ...response,
    product_brand: { name: `Brand ${id}` },
    product_brand_id: id,
  };
};

export const fetchBrandTheme = (
  response: ApiBrandThemeAndMediaTagsIncoming = makeBrandThemeResponse({
    draft: false,
  })
) => {
  return http.get(
    `${config.brandServerUrl}/jam/brand-themes/:brandId`,
    ({ params }) => {
      const id = Number(params['brandId']);
      const body = applyProductBrandToBrandThemeResponse({ id, response });
      return HttpResponse.json(body, { status: 200 });
    }
  );
};

export const fetchBrandThemeLatest = (
  {
    response = makeBrandThemeResponse({ draft: true }),
    shouldReturn404 = false,
    once = false,
  }: {
    once?: boolean;
    response?: ApiBrandThemeAndMediaTagsIncoming;
    shouldReturn404?: boolean;
  } = {
    response: makeBrandThemeResponse({ draft: true }),
    shouldReturn404: false,
    once: false,
  }
) => {
  return http.get(
    `${config.brandServerUrl}/jam/brand-themes/:brandId/latest`,
    ({ params }) => {
      if (shouldReturn404) return HttpResponse.json(null, { status: 404 });
      const id = Number(params['brandId']);
      const body =
        response && applyProductBrandToBrandThemeResponse({ id, response });
      return HttpResponse.json(body, { status: 200 });
    },
    {
      once,
    }
  );
};

export const saveDraft = (callback?: (...args: unknown[]) => void) => {
  return http.post(
    `${config.brandServerUrl}/jam/brand-themes/draft`,
    ({ request }) => {
      if (callback) {
        request.json().then((data) => callback(data));
      }
      const body: ApiBrandThemeIncoming = makeBrandThemeResponse({
        draft: true,
      }).product_brand_theme;
      return HttpResponse.json(body, { status: 200 });
    }
  );
};

export const deleteDraft = () => {
  return http.delete(
    `${config.brandServerUrl}/jam/brand-themes/:brandId/draft`,
    () => new HttpResponse(null, { status: 204 })
  );
};

export const publish = (brandId: number) => {
  return http.post(`${config.brandServerUrl}/jam/brand-themes/publish`, () => {
    const body: ApiBrandThemeIncoming = applyProductBrandToBrandThemeResponse({
      id: brandId,
      response: makeBrandThemeResponse({ draft: false }),
    }).product_brand_theme;
    return HttpResponse.json(body, { status: 200 });
  });
};

import type { AlgoliaBrand } from '@jane/search/types';

export const brandOrder = (
  brandA: AlgoliaBrand & { productCount: number },
  brandB: AlgoliaBrand & { productCount: number }
) => {
  // we can rely on rank never being 0
  if (brandA.rank && brandB.rank) {
    // rank is sorted in ascending order
    if (brandA.rank < brandB.rank) {
      return -1;
    } else if (brandA.rank > brandB.rank) {
      return 1;
    } else {
      return 0;
    }
  } else if (brandA.rank && !brandB.rank) {
    return -1;
  } else if (!brandA.rank && brandB.rank) {
    return 1;
  }

  if (brandA.logo_url && !brandB.logo_url) {
    return -1;
  }

  if (!brandA.logo_url && brandB.logo_url) {
    return 1;
  }

  return brandB.productCount - brandA.productCount;
};

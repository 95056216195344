// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OLO4LtB5rMVzC4jXr8ro {\n  background-color: var(--cardContent-background-color);\n  border: var(--cardContent-border);\n  border-width: var(--cardContent-border-width);\n  color: var(--cardContent-color);\n  height: var(--cardContent-height);\n  padding: 16px;\n  outline: none;\n  width: var(--cardContent-width);\n}\n\n.Muuxb6789H4AvCAtb1lI {\n  cursor: pointer;\n}\n\n.Muuxb6789H4AvCAtb1lI:focus-visible {\n  background: radial-gradient(\n    transparent,\n    transparent,\n    var(--colors-grays-ultralight)\n  );\n  transition: 250ms all;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/card/cardContent/cardContent.module.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,iCAAiC;EACjC,6CAA6C;EAC7C,+BAA+B;EAC/B,iCAAiC;EACjC,aAAa;EACb,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;;;;GAIC;EACD,qBAAqB;AACvB","sourcesContent":[".cardContent {\n  background-color: var(--cardContent-background-color);\n  border: var(--cardContent-border);\n  border-width: var(--cardContent-border-width);\n  color: var(--cardContent-color);\n  height: var(--cardContent-height);\n  padding: 16px;\n  outline: none;\n  width: var(--cardContent-width);\n}\n\n.cardContent__clickable {\n  cursor: pointer;\n}\n\n.cardContent__clickable:focus-visible {\n  background: radial-gradient(\n    transparent,\n    transparent,\n    var(--colors-grays-ultralight)\n  );\n  transition: 250ms all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContent": "OLO4LtB5rMVzC4jXr8ro",
	"cardContent__clickable": "Muuxb6789H4AvCAtb1lI"
};
export default ___CSS_LOADER_EXPORT___;

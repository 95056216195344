export interface ProductCategory {
  label: string;
  subItems?: { label: string; value: string }[];
  value: string;
}

export const PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    label: 'Edible',
    value: 'edible',
    subItems: [],
  },
  {
    label: 'Extract',
    value: 'extract',
    subItems: [],
  },
  {
    label: 'Flower',
    value: 'flower',
    subItems: [],
  },
  {
    label: 'Gear',
    value: 'gear',
    subItems: [],
  },
  {
    label: 'Grow',
    value: 'grow',
    subItems: [],
  },
  {
    label: 'Merch',
    value: 'merch',
    subItems: [],
  },
  {
    label: 'Pre-roll',
    value: 'pre-roll',
    subItems: [],
  },
  {
    label: 'Tinctures',
    value: 'tinctures',
    subItems: [],
  },
  {
    label: 'Vape',
    value: 'vape',
    subItems: [],
  },
];

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { PropsWithChildren } from 'react';

import { DragIcon, Flex, LockSquareIcon } from '@jane/shared/reefer';

import type { Direction } from './types';

interface Props {
  direction?: Direction;
  disabled?: boolean;
  id: string;
  showDragHandle: boolean;
  variableHeights?: boolean;
}

export const SortableElement = ({
  children,
  direction = 'horizontal',
  disabled,
  id,
  showDragHandle = true,
  variableHeights = false,
}: PropsWithChildren<Props>) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ disabled, id });
  const cursor = disabled ? 'default' : isDragging ? 'grabbing' : 'grab';

  const flexDirection = direction === 'horizontal' ? 'column' : 'row';

  const style = {
    cursor,
    ...(direction === 'vertical' && { width: '100%' }),
  };

  const content = (
    <div data-foo-bar style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );

  const dragHandle = showDragHandle ? (
    <div style={{ cursor }}>
      {disabled ? (
        <LockSquareIcon
          aria-label="drag disabled icon"
          color="grays-light"
          size="md"
        />
      ) : (
        <DragIcon
          aria-label="drag handle"
          color="grays-light"
          size="md"
          {...listeners}
          {...attributes}
        />
      )}
    </div>
  ) : null;

  return (
    // if disaabled it will not be allowed to be dragged over.
    <li key={id} ref={disabled ? undefined : setNodeRef}>
      <div
        style={{
          touchAction: 'none',
          transform: variableHeights
            ? CSS.Translate.toString(transform)
            : CSS.Transform.toString(transform),
          transition,
        }}
      >
        <Flex
          flexDirection={flexDirection}
          alignItems="center"
          gap={16}
          className="sortable-element"
        >
          {direction === 'vertical' ? (
            <>
              {dragHandle}
              {content}
            </>
          ) : (
            <>
              {content}
              {dragHandle}
            </>
          )}
        </Flex>
      </div>
    </li>
  );
};

import { useBrandsNavigate } from '@jane/brands/hooks';
import { useProductId } from '@jane/catalog-cms/hooks';
import { Box, Link, Typography } from '@jane/shared/reefer';

export const NotifyJane = () => {
  const productId = useProductId();
  const brandsNavigate = useBrandsNavigate();
  return (
    <Box>
      <Typography as="span">Problem with this product? </Typography>
      <Link onClick={() => brandsNavigate.productNotice(productId)}>
        Notify Jane
      </Link>
    </Box>
  );
};

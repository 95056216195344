import type { BrandTheme, ProductBrand } from '@jane/ad-manager/types';

export const DEFAULT_WHITE = '#FFFFFF';

export const makeBrandThemeStub = (brand: ProductBrand): BrandTheme => ({
  colorHex: DEFAULT_WHITE,
  productBrandId: brand.id,
  productBrandName: brand.name,
  productBrandNormalizedName: brand.normalizedName ?? undefined,
  customImages: [],
  draft: false,
});

import { Flex, Typography } from '@jane/shared/reefer';

interface Props {
  children: React.ReactNode;
  sectionTitle: string;
}
export const SidebarSection = ({ sectionTitle, children }: Props) => {
  return (
    <Flex flexDirection="column" gap={16} width="100%">
      <Typography mb={16} variant="body-bold">
        {sectionTitle}
      </Typography>
      {children}
    </Flex>
  );
};

import { TrackJS } from 'trackjs';

import { config } from '@jane/shared/config';

const applicationName = config.trackJsApplication
  ? config.trackJsApplication.replace('jane', 'brand-portal')
  : undefined;

const ignorableMessages = [
  // Occasionally thrown by resize observers but is considered harmless
  'ResizeObserver loop limit exceeded',

  // Network issues for user, nothing we can do about it
  'Api Request Failed',
  'The request timed out',
  'Failed to fetch',
];
const ignorableMessageRegex = new RegExp(ignorableMessages.join('|'), 'i');

export const initTrackJSBrands = () => {
  TrackJS.install({
    token: config.trackJsToken,
    application: applicationName || config.trackJsApplication,
    version: config.appVersion,
    forwardingDomain: config.trackJsDomain,
    // Whether an error should be reported if a network request is returned with a response code of 400 or greater.
    network: { error: false },
    onError: (payload: { message: string }) => {
      const ignore = ignorableMessageRegex.test(payload.message);

      return !ignore;
    },
  });
};

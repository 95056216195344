import type { QueryObserverOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  login as brandsLogin,
  logout as brandsLogout,
  fetchUser,
} from '@jane/brands/data-access';
import type { AuthUser } from '@jane/brands/data-access';
import { ApiRequestError } from '@jane/shared/data-access';

/**
 * Hook for interacting with brand server authentication. Uses React Query
 * to fetch the logged in user. Hook also provides functions to perform
 * login and logout actions.
 */

export const USER_QUERY_KEY = ['user'] as const;
export const USER_QUERY_DEFAULTS: QueryObserverOptions = {
  retry: (failureCount, error) => {
    if (error instanceof ApiRequestError && error.response.status === 401) {
      return false;
    } else if (failureCount >= 3) {
      return false;
    }
    return true;
  },
  staleTime: Infinity,
};

export const useAuth = () => {
  // Has custom query defaults set on queryClient, see above
  const userQuery = useQuery<AuthUser>({
    queryKey: ['user'],
    queryFn: fetchUser,
  });
  const queryClient = useQueryClient();

  const { refetch } = userQuery;

  const login = useCallback(
    async (email: string, password: string) => {
      const result = await brandsLogin(email, password);

      // After successful login refetch the user
      if (result.status === 204) refetch();

      return result;
    },
    [refetch]
  );

  const logout = useCallback(async () => {
    const result = await brandsLogout();

    // After successful logout reset user query and clear cache, re-render will
    // redirect out of any protected route
    if (result.status === 204) {
      await queryClient.resetQueries();
    }

    return result;
  }, [queryClient]);

  // Status 401 responses are expect, any other bad response should throw
  if (userQuery.isError) {
    const { error } = userQuery;
    if (error instanceof ApiRequestError) {
      if (error.response.status !== 401) throw error;
    } else {
      throw error;
    }
  }

  return {
    userQuery,
    login,
    logout,
  };
};

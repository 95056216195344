import { useQuery } from '@tanstack/react-query';

import { fetchCategories } from '@jane/catalog-cms/data-access';

/**
 * Fetches a list of available product categories
 */

export const useCategories = () => {
  const categoriesQuery = useQuery({
    queryKey: ['categories'],
    queryFn: fetchCategories,
  });

  if (categoriesQuery.isError) {
    throw categoriesQuery.error;
  }

  return categoriesQuery;
};

import clsx from 'clsx';

import { getTextWithHighlights } from '../../../utils';
import { Box } from '../../box/box';
import { Flex } from '../../flex/flex';
import { CheckboxField } from '../../forms/fields/checkboxField/checkboxField';
import { usePopoverContext } from '../../popover/popoverContext/popoverContext';
import { Typography } from '../../typography';
import type { TypeAheadOption } from '../typeAhead.types';
import { useTypeAheadContext } from '../typeAheadContext';
import styles from './resultListItem.module.css';

export const ResultListItem = ({
  className,
  'data-testid': testId,
  id,
  label,
  subLabel,
}: TypeAheadOption) => {
  const { optionType, query, onSelection, selection, width } =
    useTypeAheadContext();
  const { closePopover } = usePopoverContext();

  const selectionArray = selection as string[];

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.target as HTMLButtonElement;
    onSelection && onSelection(button.value);
    closePopover();
  };

  const checkboxHandler = (checked: boolean, optionLabel: string) => {
    let newFilters = [...selectionArray];
    if (checked) {
      newFilters = [...selectionArray, optionLabel];
    } else {
      newFilters = selectionArray.filter((option) => option !== optionLabel);
    }
    onSelection && onSelection(newFilters);
  };

  const renderedLabel = getTextWithHighlights(label, query);
  const renderedSubLabel = getTextWithHighlights(subLabel || '', query);

  return (
    <Box
      className={clsx(className, styles.resultListItem)}
      data-testid={testId}
      id={id}
      width={width}
    >
      {optionType === 'button' ? (
        <button
          className={styles.resultListItem_button}
          onClick={buttonHandler}
          value={label}
          type="button"
        >
          <Flex
            as="span"
            className={styles.resultListItem_buttonLabel}
            justifyContent="space-between"
          >
            <Typography as="span">{renderedLabel}</Typography>
            {renderedSubLabel && (
              <Typography as="span" color="text-light" ml={8}>
                {renderedSubLabel}
              </Typography>
            )}
          </Flex>
        </button>
      ) : (
        <CheckboxField
          className={styles.resultListItem_checkbox}
          name={label}
          onChange={(checked: boolean) => checkboxHandler(checked, label)}
          label={renderedLabel}
          checked={selection?.includes(label)}
        />
      )}
    </Box>
  );
};

import styled from '@emotion/styled';
import { useRef } from 'react';

import { Button, Flex, Typography } from '@jane/shared/reefer';

const StyledFlex = styled(Flex)(({ theme }) => ({
  aspectRatio: '1/1',
  borderStyle: 'dashed',
  color: theme.colors.grays.mid,
}));

const StyledButton = styled(Button)({});
export interface ImageUploadAreaProps {
  isUploading?: boolean;
  onFilesAdded: (fileList: FileList | null) => void;
}

export const ImageUploadArea = ({
  onFilesAdded,
  isUploading,
}: ImageUploadAreaProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <StyledFlex
      borderRadius="lg"
      border="grays-light"
      p={16}
      borderWidth="2px"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      gap={12}
      data-testid="image-upload-outline"
    >
      <StyledButton
        variant="secondary"
        label="Upload images"
        onClick={() => {
          fileInputRef.current && fileInputRef.current.click();
        }}
        loading={isUploading}
      />
      <Typography color="grays-mid" textAlign="center">
        or
      </Typography>
      <Typography color="grays-mid" textAlign="center">
        Drag and drop images
      </Typography>
      <label style={{ display: 'none' }}>
        Image upload
        <input
          ref={fileInputRef}
          type="file"
          multiple
          style={{ display: 'none' }}
          accept={'image/*'}
          onChange={(event) => {
            event.preventDefault();
            const files = event.currentTarget.files;
            onFilesAdded(files);
          }}
        />
      </label>
    </StyledFlex>
  );
};

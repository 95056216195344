import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ProductFilters } from '@jane/catalog-cms/types';

const defaultFilters: Readonly<ProductFilters> = {
  status: ['active'] as string[],
} as const;

const ARRAY_KEYS = ['category', 'categoryLabel', 'status'] as const;
const STRING_KEYS = ['searchTerm', 'sortField', 'reverse'] as const;
const DATETIME_KEYS = ['createdTime', 'updatedTime'] as const;
const FILTER_KEYS = [...ARRAY_KEYS, ...STRING_KEYS, ...DATETIME_KEYS];

/**
 * Returns an object with the product filters and some useful functions for
 * modifying the filters. The `filters` property is a ProductFilters object with
 * current filter values parsed from the URL search string parameters. The
 * `updateFilters` function should be used to modify the value of an individual
 * filter. The `clearFilters` function should be used to clear all product
 * filters.
 */

export const useProductFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilters = useCallback(
    (
      filterKey: keyof ProductFilters,
      filterValue: (string | number)[] | string
    ) => {
      setSearchParams((searchParams) => {
        const updated = new URLSearchParams(searchParams);
        updated.delete(filterKey);

        if (Array.isArray(filterValue)) {
          // Array values need to be added individually
          if (filterValue.length > 0) {
            filterValue.forEach((val) =>
              updated.append(filterKey, toString(val))
            );
          } else {
            // empty arrays are considered explicitly empty, add empty
            // string to search params
            updated.set(filterKey, '');
          }
        } else {
          updated.set(filterKey, filterValue);
        }
        return updated;
      });
    },
    [setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      const updatedParams = new URLSearchParams(searchParams);

      FILTER_KEYS.forEach((key) => {
        updatedParams.set(key, '');
      });

      return updatedParams;
    });
  }, [setSearchParams]);

  const sortBy = useCallback(
    (field: string) => {
      setSearchParams((searchParams) => {
        const updatedParams = new URLSearchParams(searchParams);

        const sortField = updatedParams.get('sortField');
        const reverse = updatedParams.get('reverse');

        if (sortField === null) {
          updatedParams.set('sortField', field);
        }

        if (sortField === field && reverse === null) {
          updatedParams.set('reverse', 'true');
        }

        if (sortField === field && reverse === 'true') {
          updatedParams.delete('reverse');
        }

        if (sortField !== null && sortField !== field) {
          updatedParams.set('sortField', field);
          updatedParams.delete('reverse');
        }

        return updatedParams;
      });
    },
    [setSearchParams]
  );

  const parsedFilters: ProductFilters = {};

  if (!searchParams.has('status')) {
    defaultFilters.status &&
      (parsedFilters.status = [...defaultFilters.status]);
  }

  STRING_KEYS.forEach((key) => {
    if (searchParams.has(key)) {
      const value = searchParams.get(key) as string;
      if (value.length > 0) parsedFilters[key] = value;
    }
  });

  ARRAY_KEYS.forEach((key) => {
    if (searchParams.has(key)) {
      const values = searchParams.getAll(key).filter((str) => str !== '');
      if (values.length > 0) {
        parsedFilters[key] = values;
      }
    }
  });

  DATETIME_KEYS.forEach((key) => {
    if (searchParams.has(key)) {
      const [time1, time2] = searchParams.getAll(key);
      const timeTuple = [];

      time1 && timeTuple.push(time1);
      time2 && timeTuple.push(time2);

      if (timeTuple.length > 0)
        parsedFilters[key] = timeTuple as [string] | [string, string];
    }
  });

  return { filters: parsedFilters, updateFilters, clearFilters, sortBy };
};

const toString = (val: number | string): string => {
  if (typeof val === 'number') return val.toString();
  return val;
};

import { useEffect } from 'react';

import type { AuthUser } from '@jane/brands/data-access';

export const useIdentifyMixpanelUser = (
  user?: Pick<AuthUser, 'id' | 'email' | 'roles'>
) => {
  useEffect(() => {
    if (user) {
      janeMixpanel.identify(user?.id);

      const user_type = user.email.match('@iheartjane.com')
        ? 'internal'
        : 'external';

      janeMixpanel.people.set({
        user_id: user.id,
        user_type,
        roles: user.roles,
        $email: user.email,
      });

      janeMixpanel.register({
        user_type,
        user_roles: user.roles,
      });

      janeMixpanel.people.set_once({
        $created: new Date(),
      });
    } else {
      // if there is no user reset mixpanel
      janeMixpanel.reset();
    }
  }, [user]);
};

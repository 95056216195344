import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import type { ProductBrand } from '@jane/ad-manager/types';
import { handleJamError } from '@jane/ad-manager/util';
import { useAuth } from '@jane/brands/hooks';
import type { ApiRequestError } from '@jane/shared/data-access';
import type { Product } from '@jane/shared/models';

import { brandServerApi } from '../brandServerApi';
import type { ApiBrandAds, ApiProductBrand, ApiProductBrands } from './schemas';
import { ApiBrandFlightsSchema } from './schemas';
import { brandFromApiResponse, brandsFromApiResponse } from './util';

export const urls = {
  getBrand: (brandId: string) => `/jam/product-brands/${brandId}`,
  getBrands: '/jam/product-brands',
  getBrandFlights: (brandId: string) => `/jam/api/brand/${brandId}/flights`,
};

type GetBrandFlightsKey = FetchBrandFlightsArgs;
type GetBrandsKey = FetchBrandsArgs;
type GetInfiniteBrandsKey = Omit<GetBrandsKey, 'page'>;
const keys = {
  all: ['productBrands'] as const,
  getBrandFlights: (args: GetBrandFlightsKey) =>
    [...keys.all, 'get-brand-ads', args] as const,
  getBrands: ({ query, page }: GetBrandsKey) =>
    [...keys.all, 'get-brands', query, page] as const,
  getInfiniteBrands: ({ query }: GetInfiniteBrandsKey) =>
    [...keys.all, 'get-brands-infinite', query] as const,
};

const fetchBrand = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number | undefined]
>): Promise<ApiProductBrand> => {
  if (!queryKey[1]) {
    // this is safe because the query is only enabled when brandId is present
    throw new Error('missing brand_id query key');
  }
  return await brandServerApi.get(urls.getBrand(queryKey[1].toString()));
};

export const useGetBrand = (brandId: number | undefined) => {
  return useQuery({
    queryFn: fetchBrand,
    queryKey: ['brandFromUrlParam', brandId],
    enabled: !!brandId,
    retry: false,
    select: brandFromApiResponse,
    onError: (error: ApiRequestError) => handleJamError(error),
  });
};

export interface FetchBrandsArgs {
  page: number;
  query: string;
  signal?: AbortSignal;
}
const fetchBrands = async ({
  query,
  page,
  signal,
}: FetchBrandsArgs): Promise<ApiProductBrands> => {
  const params = new URLSearchParams({
    query: query,
    page: page.toString(),
    simple: true.toString(),
  });
  const urlWithParams = `${urls.getBrands}?${params.toString()}`;
  return await brandServerApi.get(urlWithParams, { signal });
};

export const useGetAllBrands = (args?: FetchBrandsArgs) => {
  const query = args?.query || '';
  const page = args?.page || 1;
  const { userQuery } = useAuth();
  const userId = userQuery.data?.id;

  return useQuery({
    queryKey: keys.getBrands({ query, page }),
    queryFn: async ({ signal }) => fetchBrands({ query, page, signal }),
    enabled: userId !== undefined,
    select: brandsFromApiResponse,
    refetchOnWindowFocus: false,
    onError: (error: ApiRequestError) => handleJamError(error),
    retry: false,
  });
};

export const useGetBrandFlights = (args: FetchBrandFlightsArgs) => {
  return useQuery({
    queryKey: keys.getBrandFlights(args),
    queryFn: async () => {
      const resp = await fetchBrandAds(args);
      const response = ApiBrandFlightsSchema.safeParse(resp);

      if (response.error) {
        throw response.error; // Let React Query handles the errors
      }

      return response.data;
    },
    refetchOnWindowFocus: false,
    onError: (error: ApiRequestError) => handleJamError(error),
    retry: false,
  });
};

interface FetchBrandFlightsArgs {
  brandId?: ProductBrand['id'];
  endDate?: string | null;
  models?: string[];
  productIds?: Product['id'][];
  startDate?: string | null;
  states?: string[];
}

const fallbackFetchBrandFlightsResponse: ApiBrandAds = {
  flights: [],
  statistics: {
    adsLive: 0,
    customersImpressed: 0,
    id: 0,
    impressions: 0,
    roas: 0,
    totalBillable: 0,
  },
};

const fetchBrandAds = async ({
  brandId,
  endDate,
  startDate,
  productIds,
  states,
  models,
}: FetchBrandFlightsArgs): Promise<ApiBrandAds> => {
  if (!brandId) return Promise.resolve(fallbackFetchBrandFlightsResponse);

  const params = new URLSearchParams();
  if (startDate) params.set('start', startDate);
  if (endDate) params.set('end', endDate);
  if (productIds && productIds.length > 0) {
    params.set('productIds', productIds.join(','));
  }
  if (states && states.length > 0) {
    params.set('states', states.join(','));
  }
  if (models && models.length > 0) {
    params.set('model', models.join(','));
  }
  const urlWithParams = `${urls.getBrandFlights(
    brandId.toString()
  )}?${params.toString()}`;

  return await brandServerApi.get(urlWithParams);
};

export const useInfiniteBrands = (query: string) => {
  const { userQuery } = useAuth();
  const userId = userQuery.data?.id;

  return useInfiniteQuery<
    { data: ProductBrand[]; hasNextPage: boolean; pageParam: number },
    ApiRequestError,
    { data: ProductBrand[] }
  >({
    queryKey: keys.getInfiniteBrands({ query }),
    queryFn: async ({ pageParam = 1, signal }) => {
      const data = await fetchBrands({ query, page: pageParam, signal });
      return {
        data: brandsFromApiResponse(data),
        pageParam,
        hasNextPage: data.has_next_page,
      };
    },
    getNextPageParam: (lastPage, _allPages) => {
      const { hasNextPage, pageParam } = lastPage;
      return hasNextPage ? pageParam + 1 : undefined;
    },
    enabled: userId !== undefined,
    refetchOnWindowFocus: false,
    onError: (error) => handleJamError(error),
    retry: false,
  });
};

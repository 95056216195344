import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import type { ProductImageProps } from './ProductImage';
import { ProductImage } from './ProductImage';

type SortableImageProps = ProductImageProps;

export function SortableImage({
  src,
  index,
  handleRemove,
}: SortableImageProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: src });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ProductImage src={src} index={index} handleRemove={handleRemove} />
    </div>
  );
}

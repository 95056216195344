import { config } from '@jane/shared/config';
import { ApiRequestError } from '@jane/shared/data-access';

const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    throw new ApiRequestError('Api Request Failed', response);
  }

  const status = response.status;
  const data: unknown = status === 204 ? null : await response.json();

  return { data, response, status };
};

const buildUrl = (url: string) => `${config.brandServerUrl}${url}`;

/**
 * Fetch wrapper that sets host and other defaults for product branding server. Also includes
 * a response wrapper that throws an error on bad response statuses (4xx, 5xx).
 * This is to make the functions compatible with React Query. Though when using
 * these functions for React Query they should probably be wrapped in another
 * function that will pull out and only return the data.
 */

export const brandsApi = {
  delete: async (path: string, params?: RequestInit) => {
    const response = await fetch(buildUrl(path), {
      ...params,
      credentials: 'include',
      headers: {
        ...COMMON_HEADERS,
        ...params?.headers,
      },
      method: 'DELETE',
    });
    return handleResponse(response);
  },
  get: async (path: string, params?: RequestInit) => {
    const response = await fetch(buildUrl(path), {
      ...params,
      credentials: 'include',
      headers: {
        ...COMMON_HEADERS,
        ...params?.headers,
      },
      method: 'GET',
    });
    return handleResponse(response);
  },
  patch: async (path: string, data: unknown, params?: RequestInit) => {
    const response = await fetch(buildUrl(path), {
      ...params,
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        ...COMMON_HEADERS,
        ...params?.headers,
      },
      method: 'PATCH',
    });
    return handleResponse(response);
  },
  post: async (path: string, data: unknown, params?: RequestInit) => {
    const response = await fetch(buildUrl(path), {
      ...params,
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        ...COMMON_HEADERS,
        ...params?.headers,
      },
      method: 'POST',
    });
    return handleResponse(response);
  },
  put: async (path: string, data: unknown, params?: RequestInit) => {
    const response = await fetch(buildUrl(path), {
      ...params,
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        ...COMMON_HEADERS,
        ...params?.headers,
      },
      method: 'PUT',
    });
    return handleResponse(response);
  },
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YPkgfS7k642_gIhiBqqY {\n  width: 100%;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n  padding-top: var(--padding-top);\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/list/list.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,qCAAqC;EACrC,iCAAiC;EACjC,mCAAmC;EACnC,+BAA+B;AACjC","sourcesContent":[".list {\n  width: 100%;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n  padding-top: var(--padding-top);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "YPkgfS7k642_gIhiBqqY"
};
export default ___CSS_LOADER_EXPORT___;

import type * as ReactTable from '@tanstack/react-table';

import {
  DEFAULT_PRODUCTS_PER_PAGE,
  useFetchProducts,
} from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';

type FilterValues = string[] | undefined;

export const useProductTableQuery = ({
  columnFilters,
  searchIds,
  sorting,
}: {
  columnFilters: ReactTable.ColumnFiltersState;
  searchIds: string[];
  sorting: ReactTable.SortingState;
}) => {
  const { selectedBrand } = useSelectedBrandContext();
  const nameFilter = columnFilters.find((filter) => filter.id === 'name')
    ?.value as string;

  const productsQuery = useFetchProducts({
    brandId: selectedBrand?.id,
    order: sorting[0],
    name: nameFilter,
    ids: searchIds.length > 0 ? searchIds : undefined,
    kinds: columnFilters.find((filter) => filter.id === 'kind')
      ?.value as FilterValues,
    categories: columnFilters.find((filter) => filter.id === 'category')
      ?.value as FilterValues,
    per_page: DEFAULT_PRODUCTS_PER_PAGE,
  });

  return productsQuery;
};

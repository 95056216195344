import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { SidebarSection } from './sidebarSection';
import type { SidebarWidgetProps } from './sidebarWidget';
import { SidebarWidget } from './sidebarWidget';

const StyledSidebarWrapper = styled.div(({ theme }) => ({
  ...spacing({ mb: 40, mt: 40, pr: 40 }),
  display: 'flex',
  width: 400,
}));

export const Sidebar = ({
  sidebarWidgets,
}: {
  sidebarWidgets: SidebarWidgetProps[];
}) => {
  return (
    <StyledSidebarWrapper>
      <Flex width="100%">
        <SidebarSection sectionTitle="Appearance">
          {sidebarWidgets.map((widget, index) => {
            return (
              <SidebarWidget
                key={widget.id}
                id={widget.id}
                onWidgetClick={widget.onWidgetClick}
                labels={{
                  addText: widget.labels.addText,
                  assetCount: widget.labels.assetCount,
                  editText: widget.labels.editText,
                }}
                isEmpty={widget.isEmpty}
              />
            );
          })}
        </SidebarSection>
      </Flex>
    </StyledSidebarWrapper>
  );
};

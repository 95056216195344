import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import { marginCSSVars } from '@jane/shared/reefer';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import '../../../reefer/src/styles/theme.css';
import styles from './table.module.css';
import type { TableProps } from './table.types';
import { TableSettingsContext } from './useTableSettings';

export function Table({
  children,
  className,
  'data-testid': testId,
  freezeLastColumn = false,
  freezeFirstColumn = false,
  minWidth = 'auto',
  maxWidth = 'none',
  scrollable = true,
  style,
  ...marginProps
}: TableProps) {
  const tableSettings = useMemo(
    () => ({
      freezeFirstColumn,
      freezeLastColumn,
    }),
    [freezeFirstColumn, freezeLastColumn]
  );
  return (
    <TableSettingsContext.Provider value={tableSettings}>
      <table
        className={clsx(className, styles.table, {
          [styles.table__scrollable]:
            scrollable || freezeFirstColumn || freezeLastColumn,
        })}
        data-testid={testId}
        style={
          {
            ...marginCSSVars(marginProps),
            '--table-max-width': maxWidth,
            '--table-min-width': minWidth,
            ...style,
          } as CSSProperties
        }
      >
        {children}
      </table>
    </TableSettingsContext.Provider>
  );
}

import parse from 'date-fns/parse';

export const TIME_SLOT_OPTIONS = Array.from({ length: 24 }, (_, index) => {
  const hour = index % 12 || 12;
  const ampm = index < 12 ? 'AM' : 'PM';
  const timeLabel =
    index === 23
      ? '11:59 PM'
      : `${hour.toString().padStart(2, '0')}:00 ${ampm}`;
  return { label: timeLabel, value: timeLabel };
});

export const parseTime = (timeString: string) => {
  return parse(timeString, 'hh:mm a', new Date());
};

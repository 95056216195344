import { Typography } from '@jane/shared/reefer';

export const TitleValuePair = ({
  title,
  value,
}: {
  title: string;
  value: string | null;
}) => (
  <div style={{ flexBasis: '50%' }}>
    <Typography variant="caps" color="grays-mid">
      {title}
    </Typography>
    <Typography>{value || 'N/A'}</Typography>
  </div>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y0fvIZIcRyE05oxUPqH2 {\n  cursor: pointer;\n  outline: none;\n}\n\n.y0fvIZIcRyE05oxUPqH2:focus_visisble {\n  filter: grayscale(0.5) opacity(0.7);\n  transition: 250ms all;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/card/cardImage/cardImage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,mCAAmC;EACnC,qBAAqB;AACvB","sourcesContent":[".cardImage__clickable {\n  cursor: pointer;\n  outline: none;\n}\n\n.cardImage__clickable:focus_visisble {\n  filter: grayscale(0.5) opacity(0.7);\n  transition: 250ms all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardImage__clickable": "y0fvIZIcRyE05oxUPqH2"
};
export default ___CSS_LOADER_EXPORT___;

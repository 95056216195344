import { useProductFilters } from '@jane/catalog-cms/hooks';
import { Flex, Link } from '@jane/shared/reefer';

import { BrandCategory } from './BrandCategory';
import { Category } from './Category';
import { CreatedTime } from './CreatedTime';
import { LastModified } from './LastModified';
import { SearchTerm } from './SearchTerm';
import { Status } from './Status';

export const ProductFilters = () => {
  const { clearFilters } = useProductFilters();

  return (
    <section aria-label="product filters">
      <Flex gap={16} alignItems="center" flexWrap="wrap">
        <SearchTerm />
        <Category />
        <BrandCategory />
        <Status />
        <CreatedTime />
        <LastModified />
        <Link onClick={clearFilters}>Clear filters</Link>
      </Flex>
    </section>
  );
};

import { useEffect, useState } from 'react';
import type { MutableRefObject } from 'react';

export const useIsInView = (
  componentRef: MutableRefObject<HTMLElement | null>,
  threshold: IntersectionObserverInit['threshold'] = 0.5
) => {
  const { current } = componentRef;
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (!current) return;
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold }
    );
    observer.observe(current);
    return () => {
      observer.unobserve(current);
    };
  }, [current, threshold]);
  return isInView;
};

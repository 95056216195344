import { useParams } from 'react-router-dom';

/**
 * Small convenience wrapper around useParams() that will return the :productId
 * param or will throw an error. This is to avoid having to deal with the
 * default param typing of string | undefined in components that should
 * never be called when productId === undefined.
 */

export const useProductId = () => {
  const { productId } = useParams();

  if (productId === undefined) {
    throw new TypeError(
      [
        'URL parameter "productId" is "undefined". Make sure the route calling',
        'this component specifies a parameter called ":productId" and that the URL',
        'has a value for the parameter.',
      ].join(' ')
    );
  }

  return productId;
};

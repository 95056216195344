import { formatDistanceToNow } from 'date-fns';

export const getRelativeDate = (date: Date) => {
  if (dateIsToday(date)) {
    return 'today';
  } else if (dateIsYesterday(date)) {
    return 'yesterday';
  } else {
    return formatDistanceToNow(date, { addSuffix: true });
  }
};

const dateIsToday = (date: Date) => {
  const today = new Date();
  return date.toDateString() === today.toDateString();
};

const dateIsYesterday = (date: Date) => {
  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);
  return date.toDateString() === yesterday.toDateString();
};

import { useProductFilters } from '@jane/catalog-cms/hooks';

import Filters from '../Filters';

export const CreatedTime = () => {
  const { filters, updateFilters } = useProductFilters();
  const { createdTime } = filters;
  const dateValues = createdTime?.map((dateStr) => new Date(dateStr)) || [];

  return (
    <Filters.DateRange
      label="Created date"
      currentValue={dateValues}
      handleChange={(dates) => {
        const isoDates = dates
          .filter((date) => date !== undefined)
          .map((date) => date.toISOString());
        updateFilters('createdTime', isoDates);
      }}
      requireStart={true}
    />
  );
};

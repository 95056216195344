import type { QueryClient } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import type {
  FetchProductsParams,
  ProductListItem,
} from '@jane/catalog-cms/data-access';
import { fetchInfiniteProducts } from '@jane/catalog-cms/data-access';

export const infiniteProductsQueryKey = (
  filters: FetchProductsParams = {}
): [string, string, FetchProductsParams] => {
  return ['products', 'infiniteProducts', filters];
};

export const useInfiniteProducts = (filters: FetchProductsParams = {}) => {
  const productsQuery = useInfiniteQuery({
    queryKey: infiniteProductsQueryKey(filters),
    queryFn: fetchInfiniteProducts,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.final === true ? undefined : lastPage.nextPosition;
    },
  });

  // Extract and simplify necessary data from payloads
  let data = undefined;

  if (productsQuery.data) {
    const { pages } = productsQuery.data;
    const products = pages.flatMap((body) => body.data);
    const totalCount = pages[pages.length - 1].totalCount;

    data = { products, totalCount };
  }

  return { ...productsQuery, data: data };
};

/**
 * The shape of the infinite products query result
 */
type InfiniteProductsQueryResult = {
  pages: {
    data: ProductListItem[];
  }[];
};

type UpdatedProductListItem = Partial<ProductListItem> &
  Pick<ProductListItem, 'id'>;

/**
 * Utility that updates the query cache for the given updated product array
 * @param updatedProductListItems - the updated product information
 * @param queryClient - the react-query client
 * @param filters - the filters used to build the infinite products query key
 */
export const updateInfiniteProductsCache = (
  updatedProductListItems: UpdatedProductListItem[],
  queryClient: QueryClient,
  filters: FetchProductsParams
): void => {
  const queryKey = infiniteProductsQueryKey(filters);

  const updatedProductMap = new Map(
    updatedProductListItems.map((product) => [product.id, product])
  );

  queryClient.setQueryData<InfiniteProductsQueryResult | undefined>(
    queryKey,
    (oldData) => {
      if (!oldData || !oldData.pages) {
        return oldData;
      }

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          data: page.data.map((product: ProductListItem) => {
            if (updatedProductMap.has(product.id)) {
              return { ...product, ...updatedProductMap.get(product.id) };
            } else {
              return product;
            }
          }),
        })),
      };
    }
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type {
  BrandMediaTagWithMediaCompliances,
  BrandTheme,
} from '@jane/ad-manager/types';
import { handleJamError } from '@jane/ad-manager/util';
import { useAuth } from '@jane/brands/hooks';
import type { ApiRequestError } from '@jane/shared/data-access';

import { brandServerApi } from '../brandServerApi';
import type {
  ApiBrandThemeAndMediaTagsIncoming,
  ApiBrandThemeIncoming,
} from './types';
import { fromApi, fromApiWithMediaTags, toApi } from './util';

/* eslint-disable sort-keys */
const urls = {
  get: (brandId: number) => `/jam/brand-themes/${brandId}`,
  getLatest: (brandId: number) => `/jam/brand-themes/${brandId}/latest`,
  saveDraft: () => `/jam/brand-themes/draft`,
  deleteDraft: (brandId: number) => `/jam/brand-themes/${brandId}/draft`,
  publish: () => `/jam/brand-themes/publish`,
};
const keys = {
  all: ['brand-themes'] as const,
  get: (brandId: number) => [...keys.all, brandId] as const,
  getLatest: (brandId: number) => [...keys.get(brandId), 'latest'] as const,
  saveDraft: () => [...keys.all, 'save-draft'] as const,
  deleteDraft: () => [...keys.all, 'delete-draft'] as const,
  publish: () => [...keys.all, 'publish'] as const,
};
/* eslint-enable sort-keys */

const getBrandTheme = async (
  brandId: number
): Promise<{
  mediaTags: BrandMediaTagWithMediaCompliances[];
  productBrandTheme: BrandTheme;
}> => {
  const data = await brandServerApi.get<ApiBrandThemeAndMediaTagsIncoming>(
    urls.get(brandId)
  );
  return fromApiWithMediaTags(data);
};

export const useGetBrandTheme = (brandId: number) => {
  const { userQuery } = useAuth();
  const userId = userQuery.data?.id;

  return useQuery({
    enabled: brandId !== undefined && userId !== undefined,
    onError: (error: ApiRequestError) => {
      handleJamError(error);
    },
    queryFn: async () => {
      try {
        return await getBrandTheme(brandId);
      } catch (error: unknown) {
        if ((error as ApiRequestError)?.response.status === 404) {
          return null;
        }
        throw error;
      }
    },
    queryKey: keys.get(brandId),
    refetchOnWindowFocus: false,
    retry: false,
  });
};

const getLatest = async (
  brandId: number
): Promise<{
  mediaTags: BrandMediaTagWithMediaCompliances[];
  productBrandTheme: BrandTheme;
}> => {
  const data = await brandServerApi.get<ApiBrandThemeAndMediaTagsIncoming>(
    urls.getLatest(brandId)
  );
  return fromApiWithMediaTags(data);
};

export const useGetLatest = (brandId: number | undefined) => {
  const { userQuery } = useAuth();
  const userId = userQuery.data?.id;

  return useQuery({
    enabled: !!brandId && !!userId,
    onError: (error: Error) => handleJamError(error),
    queryFn: async () => {
      try {
        return await getLatest(brandId as number);
      } catch (error: unknown) {
        if ((error as ApiRequestError)?.response.status === 404) {
          return null;
        }
        throw error;
      }
    },
    queryKey: keys.getLatest(brandId as number),
    refetchOnWindowFocus: false,
  });
};

const saveDraft = async (brandTheme: BrandTheme) => {
  return await brandServerApi.post<ApiBrandThemeIncoming>(urls.saveDraft(), {
    product_brand_theme: toApi(brandTheme),
  });
};

export const useSaveDraft = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (brandTheme: BrandTheme) => saveDraft(brandTheme),
    mutationKey: keys.saveDraft(),
    onError: (error: Error) => handleJamError(error),
    onSuccess: (incomingBrand) => {
      const brandTheme = fromApi(incomingBrand);
      const { productBrandId } = brandTheme;
      queryClient.invalidateQueries(keys.getLatest(productBrandId));
    },
  });
};

const deleteDraft = async (brandId: number) => {
  return await brandServerApi.delete(urls.deleteDraft(brandId));
};

export const useDeleteDraft = (brandId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (brandId: number) => {
      return deleteDraft(brandId);
    },
    mutationKey: keys.deleteDraft(),
    onError: (error: Error) => handleJamError(error),
    onSuccess: () => {
      queryClient.invalidateQueries(keys.getLatest(brandId));
    },
  });
};
const publish = async (brandId: number) => {
  return await brandServerApi.post<ApiBrandThemeIncoming>(urls.publish(), {
    product_brand_theme: { product_brand_id: brandId },
  });
};

export const usePublish = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (brandId: number) => publish(brandId),
    mutationKey: keys.publish(),
    onError: (error: Error) => handleJamError(error),
    onSuccess: (incomingBrand) => {
      const brandTheme = fromApi(incomingBrand);
      const { productBrandId } = brandTheme;
      queryClient.invalidateQueries(keys.get(productBrandId));
    },
  });
};

import type { FilterParams } from '@jane/ad-manager/types';

import { DateRangeSelector } from '../../DateRangeSelector';

interface Props {
  filterParams: FilterParams;
  onChangeFilterParams: (filterParams: FilterParams) => void;
}

export const DateRangeFilter = ({
  filterParams,
  onChangeFilterParams,
}: Props) => {
  const { startDatePT, endDatePT } = filterParams;

  const onChangeDayRange = (startDatePT: string, endDatePT: string | null) => {
    onChangeFilterParams({
      ...filterParams,
      endDatePT,
      startDatePT,
    });
  };

  return (
    <DateRangeSelector
      startDatePT={startDatePT}
      endDatePT={endDatePT}
      onChangeDayRange={onChangeDayRange}
      showValue={true}
    />
  );
};

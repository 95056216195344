import { localizations } from '@iheartjane/jane-iso-3166';

type Languages = 'en';

/**
 * Given the country code and subdivision code this will return the
 * subdivision's display name. Language defaults to English ('en')
 */
export const getLocalizationName = (
  country: string,
  subdivision: string,
  language: Languages = 'en'
) => {
  const countryCode = country.toLowerCase();
  const subdivisionCode = subdivision.toUpperCase();

  const subdivisions = localizations.subdivisions[countryCode] || [];
  const subdivisionObj = subdivisions.find(
    (s: { code?: string }) => s?.code === subdivisionCode
  );

  if (subdivisionObj) return subdivisionObj.displayName[language];

  throw new Error(
    `Could not find a localization with country code "${country}" and subdivision code "${subdivision}".`
  );
};

import capitalize from 'lodash/capitalize';
import { useCallback } from 'react';

import type { FilterParams } from '@jane/ad-manager/types';
import type {
  ActiveFilters,
  ArrayFilterValue,
  BrandFlightsMultiSelectFilterKeys,
  FilterKeys,
  OnDeselectKeys,
} from '@jane/search/types';
import {
  ActiveFilterProvider,
  FilterBarGroup,
  filterGroup,
} from '@jane/shared-ecomm/components';

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  activeFilters: ActiveFilters[];
  filterParams: FilterParams;
  filterType: BrandFlightsMultiSelectFilterKeys;
  label?: string;
  options: SelectOption[];
  setActiveFilters: (activeFilters: ActiveFilters[]) => void;
  setMultiSelectParams: (multiSelectParams: FilterParams) => void;
  typeahead: boolean;
}

export const MultiSelectFilter = ({
  activeFilters: currentActiveFilters,
  filterParams: currentFilterParams,
  filterType,
  label,
  options,
  setActiveFilters,
  setMultiSelectParams,
  typeahead,
}: Props) => {
  const items = options.map((option) => ({
    count: 1,
    ...option,
  }));

  const onChange = useCallback(
    (
      filterKey: BrandFlightsMultiSelectFilterKeys,
      newValue: FilterParams[BrandFlightsMultiSelectFilterKeys][number]
    ) => {
      const filterParams = { ...currentFilterParams };
      const activeFilters = [...currentActiveFilters];
      const currentValues = filterParams[filterKey];

      if (currentValues.includes(newValue)) {
        filterParams[filterKey] = filterParams[filterType].filter(
          (item) => item !== newValue
        );
      } else {
        filterParams[filterType] = [...filterParams[filterType], newValue];
      }

      const newValueIndex = activeFilters.findIndex(
        (filter) => filter.key === filterType && filter.value === newValue
      );

      if (newValueIndex !== -1) {
        activeFilters.splice(newValueIndex, 1);
      } else {
        activeFilters.push({
          key: filterType,
          label: newValue,
          value: newValue,
        });
      }

      setMultiSelectParams(filterParams);
      setActiveFilters(activeFilters);
    },
    [currentFilterParams, currentActiveFilters]
  );

  const onDeselect = useCallback(
    (filterKey: BrandFlightsMultiSelectFilterKeys) => {
      const filterState = { ...currentFilterParams };
      const activeFilters = [...currentActiveFilters];

      filterState[filterKey] = [];

      setMultiSelectParams(filterState);
      setActiveFilters(
        activeFilters.filter((filter) => filter.key !== filterType)
      );
    },
    [currentFilterParams, currentActiveFilters]
  );

  return (
    <ActiveFilterProvider activeFilters={currentActiveFilters}>
      <FilterBarGroup
        key={filterGroup.key}
        variant="store"
        filterGroup={{
          type: 'multiselect',
          items: items,
          showInFilterBar: true,
          key: filterType,
          typeahead: typeahead,
          label: label || capitalize(filterType),
        }}
        onChange={(filterKey: FilterKeys, value: ArrayFilterValue) => {
          onChange(filterKey as BrandFlightsMultiSelectFilterKeys, value);
        }}
        onDeselect={(filterKey: OnDeselectKeys) => {
          onDeselect(filterKey as BrandFlightsMultiSelectFilterKeys);
        }}
        totalResults={12}
        hideCounts={true}
      />
    </ActiveFilterProvider>
  );
};

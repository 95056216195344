import styled from '@emotion/styled';

import {
  Box,
  ChevronDownIcon,
  Flex,
  PartnerLogo,
  Skeleton,
  Tag,
  Typography,
} from '@jane/shared/reefer';

export interface Brand {
  id: string;
  logoUrl?: string | null;
  name: string;
}

export interface SelectedBrandsProps {
  /** Optionally prevents the selector from being clickable */
  disabled?: boolean;
  /** Boolean to render a skeleton of the component */
  isLoading?: boolean;
  /** Action fired on click */
  onClick: () => void;
  /** Array of brands that are displayed in the selector */
  selectedBrands: Brand[];
}

export const SelectedBrands = ({
  disabled = false,
  isLoading = false,
  onClick,
  selectedBrands,
}: SelectedBrandsProps) => {
  const label = generateLabel(selectedBrands);

  const badgeCount = selectedBrands?.length || 0;

  // Reverse stack to show the first brand logo on top of the stack
  const brandStack = selectedBrands?.slice(0, 9).reverse();

  const brandLogos = (
    <Box position="relative">
      <Flex inline position="relative">
        {brandStack.map((brand) => (
          <Offset key={brand.id}>
            <PartnerLogo
              size="sm"
              variant="brand"
              name={brand.name}
              image={brand.logoUrl}
            />
          </Offset>
        ))}
      </Flex>
      {badgeCount > 9 && (
        <Box
          data-testid="brand-count-badge"
          position="absolute"
          bottom={4}
          right={0}
        >
          <Tag label={`${badgeCount}`} background="grays-black" />
        </Box>
      )}
    </Box>
  );

  if (isLoading) {
    return (
      <Skeleton
        data-testid="selected-brands-skeleton"
        direction="row"
        animate
        height={71}
      >
        <Flex alignItems="center" justifyContent="center">
          <Skeleton.Bone
            height="64px"
            width="64px"
            borderRadius="circular"
            mr={24}
          />
          <Skeleton.Bone height="40px" width="275px" mb={8} />
        </Flex>
      </Skeleton>
    );
  }

  return (
    <Clickable
      disabled={disabled}
      onClick={disabled ? () => null : onClick}
      onKeyDown={(e) => e.key === ' ' && onClick()}
      role={disabled ? undefined : 'button'}
      aria-label={disabled ? undefined : 'select new brand'}
      tabIndex={disabled ? undefined : 0}
    >
      <Flex alignItems="center" gap={24} minHeight={64}>
        {brandLogos}
        <Flex alignItems="center">
          <Typography variant="header-bold">{label}</Typography>
          {!disabled && <ChevronDownIcon />}
        </Flex>
      </Flex>
    </Clickable>
  );
};

const generateLabel = (selectedBrands: Brand[]) => {
  // Show all brands when none selected and there are more than 3 brands
  if (!selectedBrands || selectedBrands.length === 0) {
    return 'All brands';
  }

  // Join brands name when there are less than 3 brands selected
  if (selectedBrands.length <= 3) {
    return selectedBrands.map((brand) => brand.name).join(', ');
  }

  // Show first brand name and count when there are more than 3 brands selected
  return `${selectedBrands[0].name} and ${selectedBrands?.length - 1} others`;
};

const Offset = styled.div(() => ({
  position: 'relative',
  overflow: 'hidden',
  '&:not(:last-child)': {
    marginRight: -48,
  },
}));

type ClickableProps = {
  disabled: boolean;
};

const Clickable = styled.div<ClickableProps>((props) => ({
  cursor: props.disabled === true ? undefined : 'pointer',
  width: 'fit-content',
}));

import styled from '@emotion/styled';
import isInteger from 'lodash/isInteger';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  MIN_PRIORITY_BUDGET,
  campaignTarget,
  minBidForCampaignType,
  validationMessages,
  validators,
} from '@jane/ad-manager/util';
import { FLAGS, brandsPaths } from '@jane/brands/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { useFlag } from '@jane/shared/feature-flags';
import { Flex, Form, Modal } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { AdDetailsSection } from './Details/AdDetailsSection';
import { AdPricingSection } from './Pricing/AdPricingSection';
import { AdProductsSection } from './Products/AdProductsSection';
import { AdSchedulingSection } from './Scheduling/AdSchedulingSection';
import { AdTargetingSection } from './Targeting/AdTargetingSection';
import type {
  FlatAdSubmissionForm,
  UseAdBuilderFormReturn,
} from './useAdBuilderForm';

interface AdBuilderProps {
  adBuilderForm: UseAdBuilderFormReturn;
  onDismiss: () => void;
}

const AdBuilderFormWrapper = styled.div(() => ({
  maxWidth: 944,
  minWidth: 550,
  margin: '0 auto',
}));

export interface AdBuilderNavigationState {
  adBuilderPrefill?: FlatAdSubmissionForm | number;
}

export const useNavigateToAdBuilder = () => {
  const navigate = useNavigate();
  return (state: AdBuilderNavigationState = {}) => {
    navigate(brandsPaths.createFlight(), { state });
  };
};

export const useAdBuilderPrefill = (
  onPrefill: (state: number | Partial<FlatAdSubmissionForm>) => void
) => {
  const { state: locationState }: { state: AdBuilderNavigationState | null } =
    useLocation();

  // prefill ad builder if data exists in location state
  useEffect(() => {
    if (!locationState?.adBuilderPrefill) {
      return;
    }

    onPrefill(locationState.adBuilderPrefill);
  }, [onPrefill, locationState?.adBuilderPrefill]);
};

export const AdBuilder = ({ onDismiss, adBuilderForm }: AdBuilderProps) => {
  const { formMethods, onSubmit } = adBuilderForm;
  const {
    getValues,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = formMethods;

  const enableCreditPercentage = useFlag(
    FLAGS.jamFlightCreditPercentage,
    false
  );
  const enableBidControl = useFlag(FLAGS.jamBidControl, false);

  const validateAdBuilder = () => {
    let isValid = true;

    const {
      adPlacement,
      adType,
      bid,
      bidModifiers,
      budget,
      creditPercent,
      endDate,
      impressions,
      isPriority,
      menuRowTitle,
      productIds,
      startDate,
      states,
      storeIds,
      timePeriod,
    } = getValues();

    if (adPlacement.length === 0) {
      formMethods.setError('adPlacement', {
        type: 'adPlacement',
        message: 'At least one placement needs to be selected.',
      });
      isValid = false;
    }

    if (adPlacement.includes('Menu row') && !menuRowTitle) {
      formMethods.setError('menuRowTitle', {
        type: 'menuRowTitle',
        message: 'Please enter a title',
      });
      isValid = false;
    }

    if (storeIds.length < 1 && states.length < 1 && !isPriority) {
      formMethods.setError('root.adTargeting', {
        type: 'adTargeting',
        message: 'At least one store or state must be selected',
      });
      isValid = false;
    }

    if (isPriority && storeIds.length < 1) {
      formMethods.setError('root.priorityTargeting', {
        type: 'priorityTargeting',
        message: 'One store must be selected for a priority ad',
      });
      isValid = false;
    }

    if (budget < 1 || !budget) {
      formMethods.setError('budget', {
        type: 'budget',
        message: validationMessages.requiredField,
      });
      isValid = false;
    }

    if (isPriority && budget < MIN_PRIORITY_BUDGET) {
      const formattedMinimumBudget = formatCurrency(MIN_PRIORITY_BUDGET);

      formMethods.setError('budget', {
        type: 'budget',
        message: `Please enter a numeric value greater than or equal to ${formattedMinimumBudget}`,
      });
      isValid = false;
    }

    if (!isPriority) {
      const cTarget = campaignTarget({ storeIds, productIds });
      const minBid = minBidForCampaignType({
        adType: adType as 'cpm' | 'cpc',
        campaignTarget: cTarget,
      });

      if (isInteger(bid) && (bid as number) < minBid) {
        formMethods.setError('bid', {
          type: 'bid',
          message: `A ${cTarget}-targeted ${adType.toUpperCase()} campaign must have a bid of at least ${formatCurrency(
            minBid
          )}`,
        });
        isValid = false;
      }
    }

    if (!isPriority && !bid) {
      formMethods.setError('bid', {
        type: 'bid',
        message: validationMessages.requiredField,
      });
      isValid = false;
    }

    if (!impressions) {
      formMethods.setError('impressions', {
        type: 'impressions',
        message: validationMessages.requiredField,
      });
      isValid = false;
    }

    if (!timePeriod) {
      formMethods.setError('timePeriod', {
        type: 'timePeriod',
        message: validationMessages.requiredField,
      });
      isValid = false;
    }

    if (startDate === '') {
      formMethods.setError('startDate', {
        type: 'startDate',
        message: 'Please enter a start date',
      });
      isValid = false;
    }

    if (endDate === '') {
      formMethods.setError('endDate', {
        type: 'endDate',
        message: 'Please enter an end date',
      });
      isValid = false;
    }

    if (!validators.isDateRangeValid(startDate, endDate)) {
      formMethods.setError('startDate', {
        type: 'startDate',
        message: validationMessages.invalidStartDateRange,
      });
      isValid = false;
    }

    if (!validators.isDateRangeValid(startDate, endDate)) {
      formMethods.setError('endDate', {
        type: 'endDate',
        message: validationMessages.invalidEndDateRange,
      });
      isValid = false;
    }

    if (enableBidControl && bidModifiers) {
      const { isValid: isValidBidModifier, errorMessages } =
        validators.isValidBidModifiers(bidModifiers);

      if (!isValidBidModifier) {
        formMethods.setError('root.bidModifiers', {
          type: 'bidModifiers',
          message: errorMessages.join('|'),
        });
        isValid = isValidBidModifier;
      }
    }

    if (enableCreditPercentage && !validators.isValidPercent(creditPercent)) {
      formMethods.setError('creditPercent', {
        type: 'creditPercent',
        message: validationMessages.invalidPercent,
      });
      isValid = false;
    }

    return isValid;
  };

  const handleFormSubmit = handleSubmit(() => {
    const isValid = validateAdBuilder();
    if (isValid) {
      onSubmit();
    }
  });

  return (
    // always-open modal hack since header styles are tightly-coupled to ConfirmDiscardWrapper
    <ConfirmDiscardWrapper
      appId="root"
      open
      setOpen={() => onDismiss()}
      hasChanges={isDirty}
      variant="full-screen"
      contentLabel="create ad form"
    >
      <Form.BaseForm
        formMethods={formMethods}
        name="ad-builder"
        onSubmit={() => handleFormSubmit()}
        data-testid="ad-builder-form"
      >
        <Modal.Header
          title="Create ad"
          actions={
            <Flex gap={12}>
              <Form.SubmitButton
                data-testid="submit-ad"
                variant="primary"
                label="Create"
                loading={isSubmitting}
              />
            </Flex>
          }
        />
        <Modal.Content>
          <AdBuilderFormWrapper>
            <Flex gap={24} flexDirection="column">
              <AdDetailsSection />
              <AdProductsSection />
              <AdTargetingSection />
              <AdPricingSection />
              <AdSchedulingSection />
            </Flex>
          </AdBuilderFormWrapper>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmDiscardWrapper>
  );
};

import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

// TODO: Deprecate images attribute clickup ticket #862k4c4ew
const UpdateProductParamsSchema = z.object({
  categoryLabel: z.string(),
  description: z.string(),
  imageURLs: z.array(z.string()),
  images: z.string(),
  lineage: z.string(),
  name: z.string(),
  uuid: z.string(),
});

export type UpdateProductParams = z.infer<typeof UpdateProductParamsSchema>;

const UpdateProductResponseSchema = z.object({ time: z.string().datetime() });
export type UpdateProductResponseBody = z.infer<
  typeof UpdateProductResponseSchema
>;

export const updateProduct = async ({
  uuid,
  ...updateParams
}: UpdateProductParams) => {
  const { data } = await brandsApi.put(
    brandServerPaths.product(uuid),
    updateParams
  );
  const parsedData = parseData(UpdateProductResponseSchema, data);
  return parsedData;
};

import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { initDmMixpanel } from '@jane/dm/internal';

import { routes } from './routes';

const router = createBrowserRouter(routes);

export function App() {
  initDmMixpanel({
    appMode: 'ad-manager',
    storeId: null,
    janeDeviceId: null,
  });

  return <RouterProvider router={router} />;
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hMSUjRvpeJAgoZOwMxUL {\n  border: var(--cardGroup-border);\n  border-radius: var(--border-radius-sm);\n  box-shadow: var(--shadows-item);\n  cursor: initial;\n  display: inline-flex;\n  flex-direction: var(--cardGroup-flex-direction);\n  height: var(--cardGroup-height);\n  overflow: hidden;\n  height: fit-content;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  outline: none;\n  position: relative;\n  width: var(--cardGroup-width);\n}\n\n.QR_VoVIMi0OoFPLYW1P5 {\n  box-shadow: none;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/card/cardGroup/cardGroup.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,sCAAsC;EACtC,+BAA+B;EAC/B,eAAe;EACf,oBAAoB;EACpB,+CAA+C;EAC/C,+BAA+B;EAC/B,gBAAgB;EAChB,mBAAmB;EACnB,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,aAAa;EACb,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".cardGroup {\n  border: var(--cardGroup-border);\n  border-radius: var(--border-radius-sm);\n  box-shadow: var(--shadows-item);\n  cursor: initial;\n  display: inline-flex;\n  flex-direction: var(--cardGroup-flex-direction);\n  height: var(--cardGroup-height);\n  overflow: hidden;\n  height: fit-content;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  outline: none;\n  position: relative;\n  width: var(--cardGroup-width);\n}\n\n.cardGroup__flat {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardGroup": "hMSUjRvpeJAgoZOwMxUL",
	"cardGroup__flat": "QR_VoVIMi0OoFPLYW1P5"
};
export default ___CSS_LOADER_EXPORT___;

import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import styled from '@emotion/styled';

import { Grid } from '@jane/shared/reefer';

import { FullScreenFileDrop } from './FullScreenFileDrop/FullScreenFileDrop';
import type { ImageUploadAreaProps } from './ImageUploadArea/ImageUploadArea';
import { ImageUploadArea } from './ImageUploadArea/ImageUploadArea';
import { SortableImage } from './SortableImage/SortableImage';

const GRID_PROPS = {
  direction: 'row',
  spacing: '3%',
  xs: 6,
  md: 4,
} as const;

export interface ImageEditorProps {
  /** This is the 'value' of the input, it is an array of image URLs */
  images: string[];
  /** Use this to disable the file input while processing the files a user submitted */
  isUploading?: ImageUploadAreaProps['isUploading'];
  /** Function that will be called when an image is removed or is moved to a new
   * position. It will be given the updated state of images
   */
  onChange: (images: string[]) => void;
  /** Function that will be called with the files a user selects from their system */
  onFilesAdded: ImageUploadAreaProps['onFilesAdded'];
  /** Some component designs don't include the upload area element */
  showUploadGridItem?: boolean;
}

/**
 * A controlled input for uploading, re-ordering and removing product images.
 */

export const ImageEditor = ({
  images,
  onChange,
  onFilesAdded,
  isUploading,
  showUploadGridItem = true,
}: ImageEditorProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );
  const handleChange = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = images.indexOf(active.id as string);
      const newIndex = images.indexOf(over.id as string);
      const updated = arrayMove(images, oldIndex, newIndex);

      onChange(updated);
    }
  };

  const handleRemove = (url: string) => {
    const newImages = [...images].filter((el) => el !== url);
    onChange(newImages);
  };

  return (
    <FullScreenFileDrop onFilesAdded={onFilesAdded}>
      <DndContext
        onDragEnd={handleChange}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        <SortableContext items={images} strategy={rectSortingStrategy}>
          <Grid.Container
            direction={GRID_PROPS.direction}
            spacing={GRID_PROPS.spacing}
          >
            <>
              {images.map((url, i) => (
                <ImageGridItem key={url + i} {...GRID_PROPS}>
                  <SortableImage
                    src={url}
                    index={i}
                    handleRemove={handleRemove}
                  />
                </ImageGridItem>
              ))}
            </>
            <UploadGridItem key="upload-area" {...GRID_PROPS}>
              {showUploadGridItem ? (
                <ImageUploadArea
                  onFilesAdded={onFilesAdded}
                  isUploading={isUploading}
                />
              ) : null}
            </UploadGridItem>
          </Grid.Container>
        </SortableContext>
      </DndContext>
    </FullScreenFileDrop>
  );
};

// Image grid items need zIndex so upload button doesn't float over images when
// they are dragged across button.
const ImageGridItem = styled(Grid.Item)({ zIndex: '20' });
const UploadGridItem = styled(Grid.Item)({ zIndex: '10' });

import { useContext } from 'react';

import { FileDropContext } from './FileDropContext';

/**
 * This hook exposes the FileDropContext. The context contains the `isActive`
 * property which will be `true` when the user is dragging something over the
 * browser window.
 */
export const useFileDrop = () => {
  const context = useContext(FileDropContext);

  if (!context) {
    throw new Error('useFileDrop must be a child of FileDropProvider.');
  }

  return { isActive: context.isActive };
};

import type { PropsWithChildren } from 'react';

import { Flex } from '@jane/shared/reefer';

import { Preview } from './preview/preview';
import { Sidebar } from './sidebar/sidebar';
import type { SidebarWidgetProps } from './sidebar/sidebarWidget';

export const MenuEditor = ({
  children,
  mobileView,
  sidebarWidgets,
}: PropsWithChildren<{
  mobileView?: boolean;
  sidebarWidgets: SidebarWidgetProps[];
}>) => {
  return (
    <Flex flexDirection="row" width="100%">
      <Sidebar sidebarWidgets={sidebarWidgets} />
      <Preview forceMobile={!!mobileView}>{children}</Preview>
    </Flex>
  );
};

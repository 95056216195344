import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { SubmitHandler } from 'react-hook-form';

import { SrOnly } from '@jane/brands/components';
import { useBrandsNavigate } from '@jane/brands/hooks';
import type {
  EditFormProduct,
  Localization,
} from '@jane/catalog-cms/data-access';
import {
  removeLocalization,
  updateLocalization,
} from '@jane/catalog-cms/data-access';
import { localizationQueryKey } from '@jane/catalog-cms/hooks';
import { getLocalizationName } from '@jane/catalog-cms/util';
import { ApiRequestError } from '@jane/shared/data-access';
import {
  Banner,
  Box,
  Button,
  Drawer,
  Flex,
  FormValidationError,
  Link,
  useToast,
} from '@jane/shared/reefer';

import { NavigationDropdown } from '../../NavigationDropdown';
import { DrawerHeader } from '../DrawerHeader';
import { CategoryDetails } from '../shared/CategoryDetails/CategoryDetails';
import { ProductHeader } from '../shared/ProductHeader/ProductHeader';
import { ProductHistory } from '../shared/ProductHistory/ProductHistory';
import { ProductNotice } from '../shared/ProductNotice';
import { ReadonlyValues } from '../shared/ReadonlyValues/ReadonlyValues';
import type { EditLocalizationFormData } from './EditLocalizationForm';
import { EditLocalizationForm } from './EditLocalizationForm';
import {
  toUpdateLocalizationParams,
  validateUpdateData,
} from './util/editLocalizationHelpers';

export interface EditLocalizationProps {
  localization: Localization;
  parentProduct: EditFormProduct;
}
export const EditLocalization = ({
  parentProduct,
  localization,
}: EditLocalizationProps) => {
  const formId = 'edit-localization';
  const toast = useToast();
  const queryClient = useQueryClient();
  const brandsNavigate = useBrandsNavigate();

  const localizationName = getLocalizationName(
    localization.country,
    localization.subdivision
  );

  const localizationMutation = useMutation({
    mutationFn: updateLocalization,
    onSuccess: () => {
      toast.add({ label: 'Saved!', variant: 'success' });
      return queryClient.invalidateQueries(
        localizationQueryKey(parentProduct.id, localization.id)
      );
    },
  });

  const onSubmit: SubmitHandler<EditLocalizationFormData> = async (data) => {
    const updateParams = toUpdateLocalizationParams(
      data,
      localization.id,
      parentProduct
    );

    validateUpdateData(updateParams, parentProduct);

    try {
      await localizationMutation.mutateAsync(updateParams);
    } catch (error) {
      if (
        error instanceof ApiRequestError &&
        error.response.status.toString().match(/^4/)
      ) {
        const { errors } = await error.response.json();

        if (Array.isArray(errors)) {
          const message = errors.join(', ') + '.';
          throw new FormValidationError('edit-localization', [
            { name: 'form', message },
          ]);
        }
      }
      throw new Error(
        [
          'There was an unexpected error when submitting the form.',
          'Please try again or contact support if this problem persists.',
        ].join(' ')
      );
    }
  };

  const localizationRemovalMutation = useMutation({
    mutationFn: removeLocalization,
    onSuccess: () => {
      toast.add({
        label: `State specific content for ${localizationName} removed.`,
        variant: 'success',
      });

      brandsNavigate.editProduct(parentProduct.id);

      return queryClient.invalidateQueries(
        localizationQueryKey(parentProduct.id, localization.id)
      );
    },
  });

  // For Api errors display message but allow user to continue editing localization
  if (localizationRemovalMutation.isError) {
    if (
      localizationRemovalMutation.error instanceof ApiRequestError ===
      false
    ) {
      // Non-Api errors are unexpected, throw to error boundary
      throw localizationRemovalMutation.error;
    }
  }

  const handleRemoveLocalization = () => {
    localizationRemovalMutation.reset();

    localizationRemovalMutation.mutate(
      {
        localizationId: localization.id,
        productUUID: parentProduct.id,
      },
      {
        onError: (error) => {
          toast.add({
            label: 'There was an error trying to remove this state.',
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <>
      <DrawerHeader showDismiss>
        <Flex grow={1} justifyContent="end">
          <Button
            label="Save"
            variant="secondary"
            form={formId}
            type="submit"
          />
        </Flex>
      </DrawerHeader>
      <Drawer.Content data-testid="drawer-content">
        <SrOnly as="h2">Edit state</SrOnly>
        <ProductHeader {...parentProduct} />
        <CategoryDetails {...parentProduct} />

        <Box pt={24}>
          <NavigationDropdown label={localizationName}>
            <NavigationDropdown.ListItem>
              <Link
                variant="minimal"
                onClick={() => brandsNavigate.editProduct(parentProduct.id)}
              >
                Default
              </Link>
            </NavigationDropdown.ListItem>
            <NavigationDropdown.Divider />
            <NavigationDropdown.ListItem>
              <Link
                variant="minimal"
                onClick={() =>
                  brandsNavigate.createLocalization(parentProduct.id)
                }
              >
                Add a state
              </Link>
            </NavigationDropdown.ListItem>
            <NavigationDropdown.Localizations
              localizations={parentProduct.localizations}
              productId={parentProduct.id}
            />
          </NavigationDropdown>
          <RemoveErrorBanner
            hasRemoveError={localizationRemovalMutation.isError}
            onDismiss={() => localizationRemovalMutation.reset()}
          />
        </Box>

        <Box pt={24} pb={24}>
          <EditLocalizationForm
            localization={localization}
            parentProduct={parentProduct}
            formId={formId}
            onSubmit={onSubmit}
            key={localization.id}
          />
        </Box>

        <Drawer.ContentDivider />

        <Box pt={24} pb={24}>
          <ReadonlyValues {...parentProduct} />
        </Box>

        <Drawer.ContentDivider />

        <Box py={24}>
          <ProductHistory {...parentProduct} />
        </Box>

        <ProductNotice {...parentProduct} />
      </Drawer.Content>

      <Drawer.Footer>
        <Flex justifyContent="end">
          <Button
            loading={localizationRemovalMutation.isLoading}
            label={'Remove state'}
            variant="destructive"
            onClick={handleRemoveLocalization}
          />
        </Flex>
      </Drawer.Footer>
    </>
  );
};

const REMOVE_ERROR_MESSAGE = [
  'There was an unexpected error when trying to remove this state specific content.',
  'Please try again later or contact support if this problem persists.',
].join(' ');

/**
 * Error banner that gives user more context when a state fails to be removed.
 */
const RemoveErrorBanner = ({
  hasRemoveError,
  onDismiss = () => null,
}: {
  hasRemoveError: boolean;
  onDismiss?: () => void;
}) => {
  if (hasRemoveError) {
    return (
      <Banner
        label={REMOVE_ERROR_MESSAGE}
        variant="error"
        onDismiss={onDismiss}
        my={16}
      />
    );
  }
  return null;
};

import type { AspectRatios } from './ImageValidation';
import {
  InvalidImageError,
  fileToImage,
  validateAspectRatio,
  validateDimensions,
  validateFileSize,
  validateFileType,
} from './ImageValidation';

export const IMAGE_VALIDATION_TYPES = {
  FILE_TYPE: 'fileType',
  FILE_SIZE: 'fileSize',
  ASPECT_RATIO: 'aspectRatio',
  DIMENSIONS: 'dimensions',
  INVALID: 'invalidFile',
} as const;

const DEFAULT_VALIDATION_MESSAGES = {
  [IMAGE_VALIDATION_TYPES.FILE_TYPE]:
    'File does not match any of the allowed file types.',
  [IMAGE_VALIDATION_TYPES.FILE_SIZE]:
    'File does not meet the file size requirements.',
  [IMAGE_VALIDATION_TYPES.ASPECT_RATIO]:
    'Image does not fit any of the allowed aspect ratios.',
  [IMAGE_VALIDATION_TYPES.DIMENSIONS]:
    'Image dimensions do not adhere to the allowed minimum and maximum dimension.',
};

interface ImageValidation {
  errors: {
    message: string;
    type: typeof IMAGE_VALIDATION_TYPES[keyof typeof IMAGE_VALIDATION_TYPES];
  }[];
  filename: string;
}
export interface ValidateImageFileOptions {
  [IMAGE_VALIDATION_TYPES.FILE_SIZE]?: {
    /** Maximum file size in bytes */
    maxSize?: number;
    message?: string;
    /** Minimum file size in bytes */
    minSize?: number;
  };
  [IMAGE_VALIDATION_TYPES.FILE_TYPE]?: {
    /** Array of mine type strings */
    allowedTypes: string[];
    message?: string;
  };
  [IMAGE_VALIDATION_TYPES.ASPECT_RATIO]?: {
    allowedRatios: AspectRatios[];
    message?: string;
  };
  [IMAGE_VALIDATION_TYPES.DIMENSIONS]?: {
    /** Maximum dimension of image in pixels. */
    max?: number;
    message?: string;
    /** Minimum dimension of image in pixels. */
    min?: number;
  };
}

/**
 * Convenience wrapper around image validation. Given a file and options this
 * function will apply the validations specified in options. If all validations
 * pass the function will resolve to null. If there are any validation errors a
 * ImageValidation object will be returned with a 'fileName' property and
 * 'errors' property. Each validation type option can also be given a custom validation message.
 * @param {File} file - A File instance that should be an image
 * @param {Object} [options.fileType] - Options forwarded to file type validation
 * @param {Object} [options.fileSize] - Options forwarded to file size validation
 * @param {Object} [options.aspectRatio] - Options forwarded to aspect ratio validation
 * @param {Object} [options.dimensions] - Options forwarded to dimension validation
 * @example
 * const validationOptions: ValidateImageFileOptions = {
 *   fileType: { allowedTypes: ['image/jpeg'] },
 *   fileSize: { minSize: 0, maxSize: 1024 },
 *   aspectRatio: { allowedRatios: ['1:1'] },
 *   dimensions: { min: 0, max: 2048 },
 * };
 * const result = await validateImageFile(myFile, validationOptions)
 *
 * if(result) {
 *   console.log(`File "${result.filename}" failed validation`)
 *   result.errors.forEach((validation) => {
 *     console.log(`Invalid ${validation.type}: ${validation.message}`)
 *   })
 * }
 */

export const validateImageFile = async (
  file: File,
  options: ValidateImageFileOptions
): Promise<null | ImageValidation> => {
  const errors: ImageValidation['errors'] = [];

  // Validate file type
  const fileTypeOptions = options.fileType;
  if (
    fileTypeOptions &&
    !validateFileType(file, fileTypeOptions.allowedTypes)
  ) {
    const type = IMAGE_VALIDATION_TYPES.FILE_TYPE;
    errors.push({
      type,
      message: fileTypeOptions.message || DEFAULT_VALIDATION_MESSAGES[type],
    });
  }

  // Validate file size
  const fileSizeOptions = options.fileSize;
  if (fileSizeOptions && !validateFileSize(file, fileSizeOptions)) {
    const type = IMAGE_VALIDATION_TYPES.FILE_SIZE;
    errors.push({
      type,
      message: fileSizeOptions.message || DEFAULT_VALIDATION_MESSAGES[type],
    });
  }

  // Validations that require an HTMLImageElement
  if (options.aspectRatio || options.dimensions) {
    const { image, error } = await fileToImage(file);

    if (image) {
      // Successfully loaded image, proceed with image validations

      // Validate aspect ratio
      const aspectRatioOptions = options.aspectRatio;
      if (
        aspectRatioOptions &&
        !validateAspectRatio(image, aspectRatioOptions.allowedRatios)
      ) {
        const type = IMAGE_VALIDATION_TYPES.ASPECT_RATIO;
        errors.push({
          type,
          message:
            aspectRatioOptions.message || DEFAULT_VALIDATION_MESSAGES[type],
        });
      }

      // Validate image dimensions
      const dimensionsOptions = options.dimensions;
      if (dimensionsOptions && !validateDimensions(image, dimensionsOptions)) {
        const type = IMAGE_VALIDATION_TYPES.DIMENSIONS;
        errors.push({
          type,
          message:
            dimensionsOptions.message || DEFAULT_VALIDATION_MESSAGES[type],
        });
      }
    } else {
      // Image load failed, add error and skip image validations
      if (image === undefined && error instanceof InvalidImageError) {
        errors.push({
          type: IMAGE_VALIDATION_TYPES.INVALID,
          message: error.message,
        });
      } else {
        throw error;
      }
    }
  }

  if (errors.length > 0) {
    return { filename: file.name, errors };
  }

  return null;
};

import type { QueryFunctionContext } from '@tanstack/react-query';
import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

const ProductsSearchItemSchema = z.object({
  id: z.string().uuid(),
  productId: z.number().int().positive(),
  name: z.string(),
  category: z.string().nullable(),
  subcategory: z.string().nullable(),
  imageUrl: z.string().url().nullable(),
  brand: z.string().nullable(),
});

const ProductsSearchResponseBodySchema = z.object({
  data: z.array(ProductsSearchItemSchema),
});

export type ProductsSearchItem = z.infer<typeof ProductsSearchItemSchema>;
export type ProductsSearchResponseBody = z.infer<
  typeof ProductsSearchResponseBodySchema
>;

export type FetchProductsSearchParams = {
  limit?: string;
  term?: string;
};

export const fetchProductsSearch = async ({
  queryKey,
}: QueryFunctionContext<[string, string, FetchProductsSearchParams]>) => {
  const params = queryKey[2];
  const searchParams = new URLSearchParams({ limit: '20' });

  if (params.term) searchParams.append('term', params.term);

  const searchString = searchParams.toString();

  const path = `${brandServerPaths.productsSearch()}?${searchString}`;

  const { data } = await brandsApi.get(path);
  const parsedData = parseData(ProductsSearchResponseBodySchema, data);

  return parsedData.data;
};

import type { QueryFunctionContext } from '@tanstack/react-query';
import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

const BrandSchema = z.object({
  id: z.string().uuid(),
  productBrandId: z.number().int().positive(),
  name: z.string(),
  logoUrl: z.string().nullable(),
});

const BrandResponseSchema = z.object({
  data: z.array(BrandSchema),
});

const BrandSearchResponseSchema = z.object({
  metadata: z.object({
    currentPage: z.number(),
    previousPage: z.number().nullable(),
    nextPage: z.number().nullable(),
    totalResults: z.number(),
    totalPages: z.number(),
  }),
  brands: z.array(BrandSchema),
});

export type Brand = z.infer<typeof BrandSchema>;
export type BrandResponseBody = z.infer<typeof BrandResponseSchema>;
export type BrandSearchResponseBody = z.infer<typeof BrandSearchResponseSchema>;

export type FetchBrandsParams = {
  brandIds?: Array<string>;
};

export const fetchBrands = async ({
  queryKey,
}: QueryFunctionContext<[string, FetchBrandsParams]>) => {
  const queryStringParams = new URLSearchParams();
  const params = queryKey[1];

  // No selected brands so don't bother fetching
  if (params.brandIds && params.brandIds.length === 0) {
    return [];
  }

  if (params.brandIds) {
    params.brandIds.forEach((brandId) => {
      queryStringParams.append('id[]', brandId);
    });
  }

  const { data } = await brandsApi.get(
    `${brandServerPaths.brands()}?${queryStringParams.toString()}`
  );
  const parsedData = parseData(BrandResponseSchema, data);

  return parsedData.data;
};

export type FetchInfiniteBrandsParams = {
  perPage?: number;
  query?: string;
};

export const fetchInfiniteBrands = async ({
  pageParam,
  queryKey,
}: QueryFunctionContext<
  [string, string, FetchInfiniteBrandsParams],
  string | undefined
>) => {
  const queryStringParams = new URLSearchParams();
  const params = queryKey[2];

  if (pageParam) {
    queryStringParams.append('page', pageParam);
  }

  if (params.query) {
    queryStringParams.append('query', params.query);
  }

  if (params.perPage) {
    queryStringParams.append('per_page', params.perPage.toString());
  }

  const { data } = await brandsApi.get(
    `${brandServerPaths.brandSearch()}?${queryStringParams.toString()}`
  );

  const parsedData = parseData(BrandSearchResponseSchema, data);
  return parsedData;
};

import { useAuth } from './useAuth';

/**
 * This hook is meant to be used where we already expect the useAuth hook to
 * have been called and have finished fetching the user. The hook will throw an
 * error if the `userQuery`'s `data` attribute is `undefined`
 */
export const useUser = () => {
  const { userQuery } = useAuth();
  const user = userQuery.data;

  if (user === undefined) {
    throw new TypeError(
      [
        'The "user" object is undefined, make sure this component is',
        'called after the "useAuth" hook has already fetched a user.',
      ].join(' ')
    );
  }

  return user;
};

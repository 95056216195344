import clsx from 'clsx';

import { CheckboxField } from '@jane/shared/reefer';

import type { TableCellProps } from '../table.types';
import { TableCellBase } from '../tableCellBase/tableCellBase';

export function TableCell({
  checkbox,
  children,
  className,
  'data-testid': testId,
  height = '72px',
  id,
  onClick,
  style,
  width = 'auto',
}: TableCellProps) {
  return (
    <TableCellBase
      checkbox={checkbox}
      className={clsx(className)}
      data-testid={testId}
      element="td"
      height={height}
      id={id}
      onClick={onClick}
      style={style}
      width={width}
    >
      {checkbox && (
        <CheckboxField
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          label=""
          name="cell-checkbox"
          labelHidden
          width={width}
        />
      )}
      {!checkbox && !!onClick && <button onClick={onClick}>{children}</button>}
      {!checkbox && !onClick && children}
    </TableCellBase>
  );
}

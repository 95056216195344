import clsx from 'clsx';

import type { TableBodyProps } from '../table.types';
import styles from './tableBody.module.css';

export function TableBody({
  children,
  className,
  'data-testid': testId,
  id,
  style,
}: TableBodyProps) {
  return (
    <tbody
      className={clsx(className, styles.tableBody)}
      data-testid={testId}
      id={id}
      style={style}
    >
      {children}
    </tbody>
  );
}

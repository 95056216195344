// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gOwkGsCY1PcJea45vSUv {\n  margin-bottom: var(--grid-container-margin-bottom);\n  margin-left: var(--grid-container-margin-left);\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/grid/gridContainer/gridContainer.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,8CAA8C;AAChD","sourcesContent":[".gridContainer {\n  margin-bottom: var(--grid-container-margin-bottom);\n  margin-left: var(--grid-container-margin-left);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": "gOwkGsCY1PcJea45vSUv"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gvvvqb0J__IEA3nEv_WH {\n  box-shadow: var(--shadows-divider-top);\n  padding-top: var(--padding-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n  position: relative;\n}\n\n@media (min-width: 768px) {\n  .yUZwl8bztFYmxzNLVJZM {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/modal/modalFooter/modalFooter.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,+BAA+B;EAC/B,qCAAqC;EACrC,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;AACpB;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":[".modalFooter {\n  box-shadow: var(--shadows-divider-top);\n  padding-top: var(--padding-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n  position: relative;\n}\n\n@media (min-width: 768px) {\n  .modalFooter__full-screen {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalFooter": "Gvvvqb0J__IEA3nEv_WH",
	"modalFooter__full-screen": "yUZwl8bztFYmxzNLVJZM"
};
export default ___CSS_LOADER_EXPORT___;

import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

// TODO: Deprecate images attribute clickup ticket #862k4c4ew
const createLocalizationParamsSchema = z.object({
  categoryLabel: z.string().nullable(),
  country: z.string(),
  description: z.string().nullable(),
  images: z.array(z.string()).nullable(),
  lineage: z.string().nullable(),
  name: z.string().nullable(),
  productUUID: z.string().uuid(),
  subdivision: z.string(),
});

export type CreateLocalizationParams = z.infer<
  typeof createLocalizationParamsSchema
>;

const CreateLocalizationResponseSchema = z.object({
  productLocalizationId: z.string().uuid(),
});
export type CreateLocalizationResponseBody = z.infer<
  typeof CreateLocalizationResponseSchema
>;

export const createLocalization = async ({
  productUUID,
  ...createParams
}: CreateLocalizationParams) => {
  const { data } = await brandsApi.post(
    brandServerPaths.localizationCreate(productUUID),
    createParams
  );
  const parsedData = parseData(CreateLocalizationResponseSchema, data);
  return parsedData;
};

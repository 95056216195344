import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { validationMessages, validators } from '@jane/ad-manager/util';
import { Flex, SelectField, Typography } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';
import { ORDERED_DAYS } from './constants';
import { TIME_SLOT_OPTIONS } from './utils';

type Schedule = Record<string, { endTime: string; startTime: string }>;

const TimeSlotSelectorWrapper = styled.div`
  padding: '24px';
`;

export const WeeklySchedule = () => {
  const {
    field: { onChange: onChangeSchedule },
  } = useController<FlatAdSubmissionForm, 'dayParting'>({
    name: 'dayParting',
  });

  const { setError, clearErrors } = useFormContext<FlatAdSubmissionForm>();

  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [weeklyStartTime, setWeeklyStartTime] = useState<string>('12:00 AM');
  const [weeklyEndTime, setWeeklyEndTime] = useState<string>('11:59 PM');

  useEffect(() => {
    const initialSchedule: Schedule = {};

    ORDERED_DAYS.forEach((day) => {
      initialSchedule[day] = {
        endTime: weeklyEndTime,
        startTime: weeklyStartTime,
      };
    });

    onChangeSchedule(initialSchedule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDayPartError = (errorMessage: string) =>
    setError('dayParting', {
      type: 'custom',
      message: `Invalid weekly schedule. ${errorMessage}`,
    });

  const clearDayPartError = useCallback(
    () => clearErrors('dayParting'),
    [clearErrors]
  );

  const onChangeDaySchedule = useCallback(
    (newSchedule: { endTime: string; startTime: string }) => {
      setWeeklyStartTime(newSchedule.startTime);
      setWeeklyEndTime(newSchedule.endTime);

      const schedules: Schedule = {};

      ORDERED_DAYS.forEach((day) => {
        schedules[day] = newSchedule;
      });

      onChangeSchedule(schedules);
    },
    [onChangeSchedule]
  );

  return (
    <TimeSlotSelectorWrapper>
      <Flex flexDirection="column" gap={24} mb={24}>
        <Flex gap={24}>
          <Flex width="420px" flexDirection="column">
            <SelectField
              name="startTime-weekly"
              data-testid="startTime-weekly"
              label="Daily start time"
              defaultValue={weeklyStartTime}
              options={TIME_SLOT_OPTIONS}
              onChange={(startTime) => {
                if (!validators.isValidTimeRange(startTime, weeklyEndTime)) {
                  setStartTimeError(validationMessages.invalidStartTime);
                  setDayPartError(validationMessages.invalidStartTime);
                } else {
                  setStartTimeError('');
                  setEndTimeError('');
                  clearDayPartError();
                }

                setWeeklyStartTime(startTime);
                onChangeDaySchedule({
                  endTime: weeklyEndTime,
                  startTime: startTime,
                });
              }}
              width="100%"
              required
            />
            {startTimeError && (
              <Typography color="error" mt={16} role="alert">
                {startTimeError}
              </Typography>
            )}
          </Flex>
          <Flex width="420px" flexDirection="column">
            <SelectField
              name="endTime-weekly"
              data-testid="endTime-weekly"
              label="End time"
              defaultValue={weeklyEndTime}
              onChange={(endTime) => {
                if (!validators.isValidTimeRange(weeklyStartTime, endTime)) {
                  setEndTimeError(validationMessages.invalidEndTime);
                  setDayPartError(validationMessages.invalidEndTime);
                } else {
                  setStartTimeError('');
                  setEndTimeError('');
                  clearDayPartError();
                }

                setWeeklyEndTime(endTime);
                onChangeDaySchedule({
                  startTime: weeklyStartTime,
                  endTime: endTime,
                });
              }}
              options={TIME_SLOT_OPTIONS}
              width="100%"
              required
            />
            {endTimeError && (
              <Typography color="error" mt={16} role="alert">
                {endTimeError}
              </Typography>
            )}
          </Flex>
        </Flex>
      </Flex>
    </TimeSlotSelectorWrapper>
  );
};

import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { Box, Typography } from '@jane/shared/reefer';

export type ProductHeaderProps = Pick<
  EditFormProduct,
  'name' | 'productId' | 'storeCount'
>;

export const ProductHeader = ({
  name,
  productId,
  storeCount,
}: ProductHeaderProps) => {
  const store = storeCount === 1 ? 'store' : 'stores';
  return (
    <Box>
      <Typography variant="header-bold" as="h3">
        {name}
      </Typography>
      <Typography color="grays-mid">{`ID #${productId} | ${storeCount} ${store}`}</Typography>
    </Box>
  );
};

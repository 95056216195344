import type {
  ThoughtspotBucket,
  ThoughtspotLiveboard,
} from '@jane/brands-analytics/data-access';
import { brandsPaths } from '@jane/brands/util';
import { Box, Tabs, Typography } from '@jane/shared/reefer';

export type AnalyticsSidebarProps = {
  buckets: ThoughtspotLiveboard;
  selectedLiveboardId?: string;
};

export const AnalyticsSidebar = ({
  buckets,
  selectedLiveboardId,
}: AnalyticsSidebarProps) => {
  const selectedValue = selectedLiveboardId || '';

  return (
    <>
      {buckets['On Menu Ads'] && (
        <TabGroup
          title="On Menu Ads"
          buckets={buckets['On Menu Ads']}
          selectedLiveboardId={selectedValue}
        />
      )}

      {buckets['Off Menu Ads'] && (
        <TabGroup
          title="Off Menu Ads"
          buckets={buckets['Off Menu Ads']}
          selectedLiveboardId={selectedValue}
        />
      )}

      {buckets['Jane Gold'] && (
        <TabGroup
          title="Jane Gold"
          buckets={buckets['Jane Gold']}
          selectedLiveboardId={selectedValue}
        />
      )}

      {buckets['Brand Insights'] && (
        <TabGroup
          title="Brand Insights"
          buckets={buckets['Brand Insights']}
          selectedLiveboardId={selectedValue}
        />
      )}

      {buckets['Brand eCommerce'] && (
        <TabGroup
          title="Brand eCommerce"
          buckets={buckets['Brand eCommerce']}
          selectedLiveboardId={selectedValue}
        />
      )}
    </>
  );
};

type TabGroupProps = {
  buckets: ThoughtspotBucket[];
  selectedLiveboardId: string;
  title: keyof ThoughtspotLiveboard;
};

/**
 * Renders a group of liveboard tabs with the title.
 */
const TabGroup = ({ title, buckets, selectedLiveboardId }: TabGroupProps) => (
  <Box mb={24}>
    <Typography ml={16} variant="caps" color="grays-mid">
      {title}
    </Typography>
    <Tabs direction="vertical" value={selectedLiveboardId}>
      {buckets.map((bucket) => (
        <Tabs.Tab
          to={brandsPaths.analyticsBoard(bucket.id)}
          value={bucket.id}
          label={bucket.name}
          key={bucket.id}
        />
      ))}
    </Tabs>
  </Box>
);

import type { SetValueConfig } from 'react-hook-form';

import type {
  BrandTheme,
  CustomImage,
  CustomMedia,
  CustomVideo,
} from '@jane/ad-manager/types';
import { mediaFormKeys } from '@jane/ad-manager/util';
import { useFormContext } from '@jane/shared/reefer';

export const useMediaFormUtil = () => {
  const { getValues, setValue } = useFormContext();

  const setValueOptions = { shouldDirty: true, shouldValidate: true };

  const getColorHex = (): BrandTheme['colorHex'] =>
    getValues(mediaFormKeys.colorHex);
  const setColorHex = (colorHex: BrandTheme['colorHex']) =>
    setValue(mediaFormKeys.colorHex, colorHex, setValueOptions);

  const getOrderedMediaKeys = (): string[] =>
    getValues(mediaFormKeys.orderedMediaKeys);
  const setOrderedMediaKeys = (mediaKeys: string[], options?: SetValueConfig) =>
    setValue(mediaFormKeys.orderedMediaKeys, mediaKeys, {
      ...setValueOptions,
      ...options,
    });
  const getShownMediaKeys = (allMediaKeys: string[]) =>
    allMediaKeys.filter((mediaKey) => !getDestroy(mediaKey));

  /* CustomMedia (common) */
  const getId = (mediaKey: string): CustomMedia['id'] =>
    getValues(mediaFormKeys.id({ mediaKey }));
  const setId = (mediaKey: string, id: CustomMedia['id']) =>
    setValue(mediaFormKeys.id({ mediaKey }), id, setValueOptions);

  const getPosition = (mediaKey: string): CustomMedia['position'] =>
    getValues(mediaFormKeys.position({ mediaKey }));
  const setPosition = (mediaKey: string, position: CustomMedia['position']) =>
    setValue(mediaFormKeys.position({ mediaKey }), position, setValueOptions);

  const getDestroy = (mediaKey: string): CustomMedia['_destroy'] =>
    getValues(mediaFormKeys._destroy({ mediaKey }));
  const setDestroy = (mediaKey: string, _destroy: CustomMedia['_destroy']) =>
    setValue(mediaFormKeys._destroy({ mediaKey }), _destroy, setValueOptions);

  const getMediaType = (mediaKey: string): CustomMedia['_mediaType'] =>
    getValues(mediaFormKeys._mediaType({ mediaKey }));
  const setMediaType = (
    mediaKey: string,
    _mediaType: CustomMedia['_mediaType']
  ) =>
    setValue(
      mediaFormKeys._mediaType({ mediaKey }),
      _mediaType,
      setValueOptions
    );

  /* CustomImage */
  const getImageImageUrl = (mediaKey: string): CustomImage['imageUrl'] =>
    getValues(mediaFormKeys.imageImageUrl({ mediaKey }));
  const setImageImageUrl = (
    mediaKey: string,
    imageUrl: CustomImage['imageUrl']
  ) =>
    setValue(
      mediaFormKeys.imageImageUrl({ mediaKey }),
      imageUrl,
      setValueOptions
    );

  /* CustomVideo */
  const getVideoOverlayImageUrl = (
    mediaKey: string
  ): CustomVideo['overlayImageUrl'] =>
    getValues(mediaFormKeys.videoOverlayImageUrl({ mediaKey }));
  const setVideoOverlayImageUrl = (
    mediaKey: string,
    overlayImageUrl: CustomVideo['overlayImageUrl']
  ) =>
    setValue(
      mediaFormKeys.videoOverlayImageUrl({ mediaKey }),
      overlayImageUrl,
      setValueOptions
    );

  const getVideoOverlayText = (mediaKey: string): CustomVideo['overlayText'] =>
    getValues(mediaFormKeys.videoOverlayText({ mediaKey }));
  const setVideoOverlayText = (
    mediaKey: string,
    overlayText: CustomVideo['overlayText']
  ) =>
    setValue(
      mediaFormKeys.videoOverlayText({ mediaKey }),
      overlayText,
      setValueOptions
    );

  const getVideoVideoUrl = (mediaKey: string): CustomVideo['videoUrl'] =>
    getValues(mediaFormKeys.videoVideoUrl({ mediaKey }));
  const setVideoVideoUrl = (
    mediaKey: string,
    videoUrl: CustomVideo['videoUrl']
  ) =>
    setValue(
      mediaFormKeys.videoVideoUrl({ mediaKey }),
      videoUrl,
      setValueOptions
    );

  const getMediaTags = (mediaKey: string): CustomMedia['brandMediaTags'] =>
    getValues(mediaFormKeys.brandMediaTags({ mediaKey }));
  const setMediaTags = (
    mediaKey: string,
    brandMediaTags: CustomMedia['brandMediaTags']
  ) =>
    setValue(
      mediaFormKeys.brandMediaTags({ mediaKey }),
      brandMediaTags,
      setValueOptions
    );

  return {
    getColorHex,
    setColorHex,
    getOrderedMediaKeys,
    setOrderedMediaKeys,
    getShownMediaKeys,
    getId,
    setId,
    getPosition,
    setPosition,
    getDestroy,
    setDestroy,
    getMediaType,
    setMediaType,
    getImageImageUrl,
    setImageImageUrl,
    getVideoOverlayImageUrl,
    setVideoOverlayImageUrl,
    getVideoOverlayText,
    setVideoOverlayText,
    getVideoVideoUrl,
    setVideoVideoUrl,
    getMediaTags,
    setMediaTags,
  };
};

import styled from '@emotion/styled';

import { Box, Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { SPEND_ALLOCATION_LABELS } from '../AdBuilder/Scheduling/SpendAllocation';
import { FlightDetailSection } from './FlightDetailSection';

interface ManualDaySelection {
  name: string;
  value: string;
}
interface ScheduleDetailsProps {
  end: string;
  frequencyCapping: {
    frequency?: string;
    periodLength?: string;
    periodUnit?: string;
  };
  spendAllocation:
    | {
        mode: 'consistent';
      }
    | {
        manualDays: ManualDaySelection[];
        mode: 'manual';
      };
  start: string;
}

const StyledTable = styled.table(({ theme }) => ({
  borderSpacing: 0,
  width: '100%',
  whiteSpace: 'nowrap',
  'th:first-of-type, td:first-of-type': {
    position: 'sticky',
    left: 0,
  },
  'td:nth-of-type(2), th:nth-of-type(2)': {
    paddingLeft: '24px',
  },
}));

const getManualDaysLabel = (days: ManualDaySelection[]) => {
  const uniqueDays = new Set(days.map((day) => day.value));
  if (uniqueDays.size === 1) {
    return SPEND_ALLOCATION_LABELS.weekly;
  }
  return SPEND_ALLOCATION_LABELS.manual;
};

interface ManualSelectionDaysProps {
  days: ManualDaySelection[];
}
const ManualSelectionDays = ({ days }: ManualSelectionDaysProps) => (
  <StyledTable>
    <Table.Head>
      <Table.Row>
        <Table.HeaderCell width="50%">
          <Typography variant="caps">Day</Typography>
        </Table.HeaderCell>
        <Table.HeaderCell width="50%">
          <Typography variant="caps">Schedule</Typography>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Head>
    <Table.Body>
      {days?.map(({ name, value }) => (
        <Table.Row key={name}>
          <Table.Cell width="50%">
            <Typography>{name}</Typography>
          </Table.Cell>
          <Table.Cell width="50%">
            <Typography>{value}</Typography>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </StyledTable>
);
export const ScheduleDetails = ({
  frequencyCapping: { frequency, periodLength, periodUnit },
  spendAllocation,
  start,
  end,
}: ScheduleDetailsProps) => {
  const spendAllocationMode =
    spendAllocation.mode === 'consistent' ? (
      'Consistent schedule throughout the week'
    ) : (
      <>
        <Typography>
          {getManualDaysLabel(spendAllocation.manualDays)}
        </Typography>
        <Box mt={24}>
          <ManualSelectionDays days={spendAllocation.manualDays} />
        </Box>
      </>
    );

  const rows = [
    [
      {
        name: 'Spend allocation',
        value: spendAllocationMode,
      },
    ],
  ];

  const hasFrequencyCapping =
    frequency !== undefined ||
    periodLength !== undefined ||
    periodUnit !== undefined;

  if (hasFrequencyCapping) {
    rows.unshift([
      {
        name: 'Frequency Capping',
        value: `For each user, display this flight up to ${frequency} times every ${periodLength} ${periodUnit}.`,
      },
    ]);
  }

  return (
    <FlightDetailSection
      data-testid="schedule-details"
      headerLabel={
        hasFrequencyCapping ? 'Frequency capping and schedule' : 'Schedule'
      }
      rows={rows}
    />
  );
};

import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

const CategorySchema = z.array(z.string());
const CategoryBodySchema = z.object({ data: CategorySchema });

export type Category = z.infer<typeof CategorySchema>;
export type CategoryBody = z.infer<typeof CategoryBodySchema>;

export const fetchCategories = async () => {
  const { data } = await brandsApi.get(brandServerPaths.categories());
  const parsedData = parseData(CategoryBodySchema, data);
  return parsedData.data;
};

import { useContext, useEffect } from 'react';

import type { ProductListItem } from '@jane/catalog-cms/data-access';
import { NextProductIdContext } from '@jane/catalog-cms/providers';

/**
 * Has no return value. The hook initializes a `useEffect` which will
 * automatically update the value of `nextProductId` in the nearest
 * `NextProductIdProvider` based on the `productId` and `products` values passed
 * in to the hook. This hook is how the `nextProductId` is determined for the
 * save and next feature.
 */

export const useUpdateNextProductId = (
  productId?: string,
  products?: ProductListItem[]
) => {
  const context = useContext(NextProductIdContext);

  if (context === undefined) {
    throw new Error(
      'useUpdateNextProductId must be a child of NextProductIdProvider.'
    );
  }

  useEffect(() => {
    const { setNextProductId } = context;

    if (productId === undefined) {
      setNextProductId(undefined);
      return;
    }

    if (productId) {
      if (products === undefined) return;

      const matchingIndex = products.findIndex((p) => p.id === productId);

      if (matchingIndex >= 0) {
        const nextId = products[matchingIndex + 1]?.id;
        setNextProductId(nextId ?? null);
      } else {
        setNextProductId(null);
      }
      return;
    }
  }, [context, productId, products]);
};

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';

const buildUrl = (url: string) => {
  const { brandServerUrl } = config;
  return `${brandServerUrl}${url}`;
};

export const brandServerApi = {
  get: <T>(url: string, params?: RequestInit): Promise<T> =>
    api.get(buildUrl(url), {
      credentials: 'include',
      ...params,
    }),
  post: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.post(buildUrl(url), data, {
      credentials: 'include',
      ...params,
    }),
  put: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.put(buildUrl(url), data, {
      credentials: 'include',
      ...params,
    }),
  patch: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.patch(buildUrl(url), data, {
      credentials: 'include',
      ...params,
    }),
  delete: <T>(url: string, params?: RequestInit): Promise<T | null> =>
    api.delete(buildUrl(url), {
      credentials: 'include',
      ...params,
    }),
};

import styled from '@emotion/styled';

import { PlayIcon } from '@jane/shared/reefer';

export const size = 48;

const Playback = styled.div(({ theme }) => {
  return {
    width: size,
    height: size,
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: `blur(8px)`,
    borderRadius: size / 2,
    svg: {
      position: 'absolute',
      top: 12,
      left: 14,
    },
  };
});

export const PlaybackIcon = () => {
  return (
    <Playback>
      <PlayIcon color="grays-white" />
    </Playback>
  );
};

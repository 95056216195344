import type { CategoryOption } from '../CategorySelectInputModal';

export const updateSelectedCategories = (
  value: string,
  selectedCategories: CategoryOption[],
  categoryOptions: CategoryOption[]
) => {
  const isSelected = selectedCategories.find(
    (selectedCategory) => selectedCategory.value === value
  );

  const category = findOptionForValue(value, categoryOptions);

  if (isSelected) {
    return selectedCategories.filter((category) => category.value !== value);
  }

  if (category) {
    return [...selectedCategories, category];
  }

  return selectedCategories;
};

function findOptionForValue(
  value: string,
  options: CategoryOption[]
): CategoryOption | undefined {
  for (const option of options) {
    if (option.value === value) {
      return option;
    }
    if (option.subItems && option.subItems.length > 0) {
      const subOption = findOptionForValue(value, option.subItems);
      if (subOption) {
        return subOption;
      }
    }
  }
  return undefined;
}

import styled from '@emotion/styled';
import type { Dispatch, SetStateAction } from 'react';

import { toBrandPageApi } from '@jane/ad-manager/data-access';
import type {
  BrandMediaTagWithMediaCompliances,
  BrandTheme,
} from '@jane/ad-manager/types';
import { DEFAULT_WHITE, combineCustomMedia } from '@jane/ad-manager/util';
import type { SidebarWidgetProps } from '@jane/shared-b2b/components';
import { MenuEditor } from '@jane/shared-b2b/components';
import {
  BrandMediaCarousel,
  DesktopBrandDetail,
  MobileBrandDetail,
} from '@jane/shared-ecomm/components';

interface BrandThemeEditorMenuProps {
  brandTheme: BrandTheme;
  forceMobile: boolean;
  mediaTags: BrandMediaTagWithMediaCompliances[];
  setEditingBrandDescription: Dispatch<SetStateAction<boolean>>;
  setEditingBrandLogo: Dispatch<SetStateAction<boolean>>;
  setEditingBrandThemeAssets: Dispatch<SetStateAction<boolean>>;
}

const BrandDetailWrapper = styled.div(() => ({
  marginBottom: '28px',
  transform: 'translateZ(0)', // CU-85zryw22p: fix rounded top corners in safari
}));

export const BrandThemeEditorMenu = (props: BrandThemeEditorMenuProps) => {
  const {
    brandTheme,
    mediaTags,
    forceMobile,
    setEditingBrandDescription,
    setEditingBrandLogo,
    setEditingBrandThemeAssets,
  } = props;

  const brandPageTheme = toBrandPageApi(brandTheme);
  const { customImages, customVideo, description, logoUrl, colorHex } =
    brandTheme;
  const colorIsDefault = colorHex.toLowerCase() === DEFAULT_WHITE.toLowerCase();
  const numberOfMediaItems = combineCustomMedia(
    customImages,
    customVideo
  ).length;
  const brandThemeWidgetProps: SidebarWidgetProps = {
    id: 1,
    onWidgetClick: () => setEditingBrandThemeAssets(true),
    labels: {
      addText: 'Add a cover image or color',
      assetCount: numberOfMediaItems || undefined,
      editText: 'Edit your cover',
    },
    isEmpty: colorIsDefault && numberOfMediaItems === 0,
  };
  const brandDescriptionWidgetProps = {
    id: 2,
    onWidgetClick: () => setEditingBrandDescription(true),
    labels: {
      addText: 'Add a description',
      editText: 'Edit your description',
    },
    isEmpty: !description,
  };
  const brandLogoWidgetProps = {
    id: 3,
    onWidgetClick: () => setEditingBrandLogo(true),
    labels: {
      addText: 'Add your logo',
      editText: 'Edit your logo',
    },
    isEmpty: !logoUrl,
  };
  const sidebarWidgets = [
    brandThemeWidgetProps,
    brandDescriptionWidgetProps,
    brandLogoWidgetProps,
  ];

  return (
    <MenuEditor sidebarWidgets={sidebarWidgets} mobileView={forceMobile}>
      <BrandMediaCarousel
        brand={brandPageTheme}
        mediaTags={mediaTags}
        forceMobile={forceMobile}
      />
      <BrandDetailWrapper>
        {forceMobile ? (
          <MobileBrandDetail brand={brandPageTheme} forceMobile={forceMobile} />
        ) : (
          <DesktopBrandDetail brand={brandPageTheme} />
        )}
      </BrandDetailWrapper>
    </MenuEditor>
  );
};

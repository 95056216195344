import { isEqual, parse } from 'date-fns';
import isBefore from 'date-fns/isBefore';

import type { BidModifier, BidModifiers } from '@jane/ad-manager/types';

import { parsePacificDate } from './time';

const MIN_BID_MODIFIER_PERCENT = 100;
const MAX_BID_MODIFIER_PERCENT = 1_000;

export const MIN_PRIORITY_BUDGET = 1.0;

export const MIN_CPM_PLACEMENT_TARGETED_BID = 25.0;
export const MIN_CPM_SKU_TARGETED_BID = 30.0;
export const MIN_CPM_STORE_TARGETED_BID = 30.0;

export const MIN_CPC_PLACEMENT_TARGETED_BID = 1.0;
export const MIN_CPC_SKU_TARGETED_BID = 1.5;
export const MIN_CPC_STORE_TARGETED_BID = 1.5;

export interface ImageValidationParams {
  file: File;
  fileSizeErrorMessage: string;
  fileSizeValidator: (file: File) => boolean;
  fileTypeErrorMessage: string;
  fileTypeValidator: (file: File) => boolean;
  imageDimensionsErrorMessage: string;
  imageDimensionsValidator: (height: number, width: number) => boolean;
}

export const parseTime = (timeString: string) => {
  return parse(timeString, 'hh:mm a', new Date());
};

const validateBidModifier = (
  target: string,
  { amount, type }: BidModifier
): string => {
  switch (type) {
    case '%':
      if (!Number.isInteger(amount)) {
        return `Please enter an integer for ${target}`;
      }
      if (amount < MIN_BID_MODIFIER_PERCENT) {
        return `Please enter an amount greater than or equal to ${MIN_BID_MODIFIER_PERCENT}% for ${target}`;
      }
      if (amount > MAX_BID_MODIFIER_PERCENT) {
        return `Please enter an amount less than or equal to ${MAX_BID_MODIFIER_PERCENT}% for ${target}`;
      }
      break;
    case '$':
      if (amount < 0.01) {
        return `Please enter a positive dollar amount for ${target}`;
      }
      if (String(amount).split('.')[1]?.length > 2) {
        return `Please round to the nearest penny for ${target}`;
      }
      break;
    default:
      return `Unsupported bid modifier type "${type}" for ${target}`;
  }

  return '';
};

export const validators = {
  isHexValue: (value: string): boolean => {
    const validHexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
    return validHexColorRegex.test(value);
  },
  isDateRangeValid: (startDate?: string, endDate?: string): boolean => {
    if (!startDate || !endDate) return true;

    const startDateObj = parsePacificDate(startDate);
    const endEodTimeObj = parsePacificDate(endDate);

    return !startDateObj.isAfter(endEodTimeObj);
  },
  isValidTimeRange: (startTime: string, endTime: string): boolean => {
    return (
      isBefore(parseTime(startTime), parseTime(endTime)) &&
      !isEqual(parseTime(startTime), parseTime(endTime))
    );
  },
  isValidImage: ({
    file,
    fileTypeValidator,
    fileTypeErrorMessage,
    fileSizeValidator,
    fileSizeErrorMessage,
    imageDimensionsValidator,
    imageDimensionsErrorMessage,
  }: ImageValidationParams): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!fileTypeValidator(file)) return resolve(fileTypeErrorMessage);
      if (!fileSizeValidator(file)) return resolve(fileSizeErrorMessage);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        const image = new Image();

        if (typeof dataURL == 'string') {
          image.src = dataURL;
          image.onload = () => {
            const { height, width } = image;
            const imageDimensionsError = !imageDimensionsValidator(
              height,
              width
            )
              ? imageDimensionsErrorMessage
              : '';
            resolve(imageDimensionsError);
          };
        } else {
          reject('File unreadable or empty');
        }
      };
    }),
  isValidPercent: (value: number): boolean => {
    return value >= 0 && value <= 100;
  },
  isValidBidModifiers: (
    bidModifiers: BidModifiers
  ): { errorMessages: string[]; isValid: boolean } => {
    const bidModifierErrors = Object.entries(bidModifiers)
      .map(([target, bidModifier]) => {
        return validateBidModifier(target, bidModifier);
      })
      .filter(Boolean);

    return {
      errorMessages: bidModifierErrors,
      isValid: bidModifierErrors.length === 0,
    };
  },
};

export const validationMessages = {
  invalidStartTime: 'Start time should be earlier than the end time',
  invalidEndTime: 'End time should be later than the start time',
  invalidStartDateRange: 'Start date should be earlier than the end date',
  invalidEndDateRange: 'End date should be later than the start date',
  invalidColorHexCode: 'Please enter a valid hex color code',
  invalidPercent: 'Please enter a value between 0 and 100',
  requiredField: 'Please fill out this field',
};

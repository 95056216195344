import type {
  BrandTheme,
  CustomImage,
  CustomMedia,
  CustomVideo,
} from '@jane/ad-manager/types';
import { isAVideo, isAnImage } from '@jane/ad-manager/types';

import { mediaFormKeys as keys } from './mediaFormKeys';

export enum MediaUpdates {
  colorHex = 'colorHex',
  customImages = 'customImages',
  customVideo = 'customVideo',
}
export type MediaUpdate = Pick<Partial<BrandTheme>, MediaUpdates>;
export const getFormData = (data: Record<string, unknown>): MediaUpdate => {
  const updates: MediaUpdate = {};

  const colorHex = data[keys.colorHex] as MediaUpdate['colorHex'];
  if (colorHex) updates.colorHex = colorHex;

  const mediaKeys = data[keys.orderedMediaKeys] as string[];

  const customImages: CustomImage[] = mediaKeys
    .filter((mediaKey) => data[keys._mediaType({ mediaKey })] === 'image')
    .map((key) => makeCustomImageFromFormData(key, data));
  if (customImages.length) updates.customImages = customImages;

  const videoMediaKeys = mediaKeys.filter(
    (mediaKey) => data[keys._mediaType({ mediaKey })] === 'video'
  );
  const customVideo: CustomVideo = videoMediaKeys.map((key) =>
    makeCustomVideoFromFormData(key, data)
  )[videoMediaKeys.length - 1];

  if (customVideo) updates.customVideo = customVideo;

  return updates;
};

const makeCustomImageFromFormData = (
  mediaKey: string,
  data: Record<string, unknown>
): CustomImage => ({
  brandMediaTags: data[
    keys.brandMediaTags({ mediaKey })
  ] as CustomImage['brandMediaTags'],
  id: data[keys.id({ mediaKey })] as CustomImage['id'],
  imageUrl: data[keys.imageImageUrl({ mediaKey })] as CustomImage['imageUrl'],
  position: data[keys.position({ mediaKey })] as CustomImage['position'],
  _mediaType: data[keys._mediaType({ mediaKey })] as CustomImage['_mediaType'],
  _destroy: data[keys._destroy({ mediaKey })] as CustomImage['_destroy'],
});

const makeCustomVideoFromFormData = (
  mediaKey: string,
  data: Record<string, unknown>
): CustomVideo => ({
  id: data[keys.id({ mediaKey })] as CustomVideo['id'],
  brandMediaTags: data[
    keys.brandMediaTags({ mediaKey })
  ] as CustomVideo['brandMediaTags'],
  overlayImageUrl: data[
    keys.videoOverlayImageUrl({ mediaKey })
  ] as CustomVideo['overlayImageUrl'],
  overlayText: data[
    keys.videoOverlayText({ mediaKey })
  ] as CustomVideo['overlayText'],
  position: data[keys.position({ mediaKey })] as CustomVideo['position'],
  videoUrl: data[keys.videoVideoUrl({ mediaKey })] as CustomVideo['videoUrl'],
  _mediaType: data[keys._mediaType({ mediaKey })] as CustomVideo['_mediaType'],
  _destroy: data[keys._destroy({ mediaKey })] as CustomVideo['_destroy'],
});

export const getFormValuesForAllMedia = (customMedia: CustomMedia[]) => {
  const initialValues: Record<string, unknown> = {};
  customMedia.forEach((media) => {
    if (isAnImage(media))
      Object.assign(initialValues, getFormValuesForImage(media));
    else if (isAVideo(media))
      Object.assign(initialValues, getFormValuesForVideo(media));
  });
  return initialValues;
};

export const getFormValuesForImage = (media: CustomImage) => {
  return {
    [keys.id({ media })]: media.id,
    [keys.brandMediaTags({ media })]: media.brandMediaTags,
    [keys.imageImageUrl({ media })]: media.imageUrl,
    [keys.position({ media })]: media.position,
    [keys._destroy({ media })]: media._destroy,
    [keys._mediaType({ media })]: media._mediaType,
  };
};

export const getFormValuesForVideo = (media: CustomVideo) => ({
  [keys.id({ media })]: media.id,
  [keys.brandMediaTags({ media })]: media.brandMediaTags,
  [keys.videoOverlayImageUrl({ media })]: media.overlayImageUrl,
  [keys.videoOverlayText({ media })]: media.overlayText,
  [keys.position({ media })]: media.position,
  [keys.videoVideoUrl({ media })]: media.videoUrl,
  [keys._destroy({ media })]: media._destroy,
  [keys._mediaType({ media })]: media._mediaType,
});

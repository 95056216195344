import type { ProductBrand } from '@jane/ad-manager/types';
import { parseData } from '@jane/shared/util';

import type { ApiProductBrand, ApiProductBrands } from './schemas';
import { ApiProductBrandSchema, ApiProductBrandsSchema } from './schemas';

const transformData = (data: ApiProductBrand) => ({
  id: data.id,
  logoUrl: data.logo_url || undefined,
  name: data.name,
  normalizedName: data.normalized_name,
});

export const brandsFromApiResponse = (
  fromApi: ApiProductBrands
): ProductBrand[] => {
  const parsed = parseData(ApiProductBrandsSchema, fromApi);
  return parsed.product_brands.map(transformData);
};

export const brandFromApiResponse = (
  fromApi: ApiProductBrand
): ProductBrand => {
  const parsed = parseData(ApiProductBrandSchema, fromApi);
  return transformData(parsed);
};

import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Button, Flex, Modal, Typography } from '@jane/shared/reefer';

export interface ConfirmChangeModalProps {
  appId?: string;
  borderRadius?: number | string;
  cancelLabel?: string;
  confirmButtonVariant?: 'destructive';
  confirmLabel?: string;
  minWidth?: number | string;
  onCancel?: () => void;
  onConfirm: () => void;
  open: boolean;
  subtitle?: string;
  title?: ReactNode;
}

export const ConfirmChangeModal = ({
  onConfirm,
  onCancel,
  open,
  title,
  subtitle,
  cancelLabel,
  confirmLabel,
  confirmButtonVariant,
  appId,
  minWidth,
  borderRadius,
}: ConfirmChangeModalProps) => {
  const [modalOpen, setModalOpen] = useState(open);
  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    setModalOpen(false);
    onCancel && onCancel();
  };

  const modalStyle = borderRadius ? { borderRadius: borderRadius } : undefined;

  return (
    <Modal
      onRequestClose={handleCancel}
      open={modalOpen}
      variant="dialogue"
      appId={appId || 'parent'}
      style={modalStyle}
    >
      <Modal.Content>
        <Flex
          width="100%"
          minWidth={minWidth}
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="title-bold">
            {title || 'Are you sure?'}
          </Typography>
          <Typography>{subtitle}</Typography>

          <Flex mt={24} width="100%">
            <Button
              mr={16}
              full
              variant="tertiary"
              label={cancelLabel || 'Go back'}
              onClick={handleCancel}
            />
            <Button
              full
              data-testid="confirm-change-button"
              variant={confirmButtonVariant || 'primary'}
              label={confirmLabel || 'Confirm'}
              onClick={handleConfirm}
            />
          </Flex>
        </Flex>
      </Modal.Content>
    </Modal>
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteDraft, usePublish } from '@jane/ad-manager/data-access';
import type {
  BrandPageState,
  BrandTheme,
  EditorAlert,
  ProductBrand,
} from '@jane/ad-manager/types';
import { handleJamError } from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import { Button, Flex, useToast } from '@jane/shared/reefer';

interface Props {
  brandTheme: BrandTheme;
  setAlert: (alert: EditorAlert) => void;
}
export const HeaderPublishControls = ({ brandTheme, setAlert }: Props) => {
  const { mutate: publish } = usePublish();
  const toast = useToast();
  const navigate = useNavigate();
  const { mutate: reset } = useDeleteDraft(brandTheme.productBrandId);
  const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);
  const disabled = !brandTheme.draft;

  const onClickPublish = () => {
    publish(brandTheme.productBrandId, {
      onError: (error) => {
        handleJamError(error);
        toast.add({ label: 'Failed to publish brand page', variant: 'error' });
      },
      onSettled: () => {
        const brand: ProductBrand = {
          id: brandTheme.productBrandId,
          name: brandTheme.productBrandName,
          normalizedName: brandTheme.productBrandNormalizedName ?? null,
        };
        const state: BrandPageState = { state: { brand } };
        navigate(brandsPaths.brandThemes(), state);
      },
      onSuccess: () => {
        toast.add({ label: 'Brand page published.' });
      },
    });
  };

  const resetBrandTheme = () => {
    reset(brandTheme.productBrandId, {
      onError: (error) => {
        handleJamError(error);
        setAlert({
          label:
            'An error has occurred while attempting to reset this brand page to its previous version.',
          variant: 'error',
        });
      },
      onSettled: () => {
        setConfirmChangeModalOpen(false);
      },
      onSuccess: () => {
        setAlert({
          label: 'This brand page has been reset to its previous version.',
          variant: 'success',
        });
      },
    });
  };

  return (
    <Flex>
      <Button
        disabled={disabled}
        mr={16}
        onClick={() => setConfirmChangeModalOpen(true)}
        label="Reset"
        variant="secondary"
      />
      <Button
        disabled={disabled}
        label="Publish"
        variant="primary"
        onClick={onClickPublish}
        mr={16}
      />
      <ConfirmChangeModal
        appId="root"
        open={confirmChangeModalOpen}
        title="Reset edits?"
        onCancel={() => setConfirmChangeModalOpen(false)}
        onConfirm={() => resetBrandTheme()}
        confirmButtonVariant="destructive"
        subtitle="Resetting will restore the last version that was saved."
      />
    </Flex>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { FilterParams } from '@jane/ad-manager/types';
import {
  formatDatepickerString,
  isDatepickerDateStringValid,
  monthStart,
} from '@jane/ad-manager/util';

import { BRAND_ID_SEARCH_PARAM_KEY } from './useBrandIdSearchParam';

export const DEFAULT_START_DATE = formatDatepickerString(monthStart());

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultStartDate = DEFAULT_START_DATE;

  useEffect(() => {
    // To match URL to the JAM server call
    setSearchParams((params) => {
      if (!searchParams.get('startDatePT')) {
        params.set('startDatePT', defaultStartDate);
      }

      return params;
    });
  }, []);

  let startDatePTParam = searchParams.get('startDatePT');
  let endDatePTParam = searchParams.get('endDatePT');

  startDatePTParam = isDatepickerDateStringValid(startDatePTParam)
    ? startDatePTParam
    : defaultStartDate;
  endDatePTParam = isDatepickerDateStringValid(endDatePTParam)
    ? endDatePTParam
    : null;

  const [filterParams, setFilterParams] = useState<FilterParams>({
    endDatePT: endDatePTParam,
    locations: searchParams.getAll('locations[]'),
    startDatePT: startDatePTParam,
    types: searchParams.getAll('types[]'),
  });

  const onChangeFilterParams = useCallback(
    (newFiltersParams: FilterParams) => {
      setFilterParams(newFiltersParams);
      setSearchParams((old) => {
        const newUrlParams = new URLSearchParams();

        const brandId = old.get(BRAND_ID_SEARCH_PARAM_KEY);
        if (brandId) newUrlParams.set(BRAND_ID_SEARCH_PARAM_KEY, brandId);

        Object.entries(newFiltersParams).forEach(
          ([filterName, filterValues]) => {
            if (!filterValues) return;

            if (Array.isArray(filterValues)) {
              filterValues.forEach((filterValue) =>
                newUrlParams.append(`${filterName}[]`, filterValue)
              );
            } else {
              newUrlParams.set(`${filterName}`, filterValues);
            }
          }
        );
        return newUrlParams;
      });
    },
    [setSearchParams]
  );

  return { filterParams, onChangeFilterParams };
};

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';

export interface ProductReportParams {
  activeProductId?: string;
  comment?: string;
  notice: string;
  productUuid: string;
}

export const createOrUpdateReport = async ({
  productUuid,
  ...payload
}: ProductReportParams) => {
  const response = await brandsApi.post(
    brandServerPaths.productNotice(productUuid),
    payload
  );

  return response;
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Photos24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Photos 24">
      <g className="SVG">
        <path
          fill="#0E0E0E"
          d="M8.757 3.651 20.35 6.757a2 2 0 0 1 1.414 2.45L20 15.785v3.946a3.985 3.985 0 0 0 1.624-2.28l2.07-7.727a4 4 0 0 0-2.828-4.899L9.275 1.72a4 4 0 0 0-4.899 2.828l-.935 3.49C3.623 8.014 3.81 8 4 8h1.522l.786-2.934a2 2 0 0 1 2.45-1.415Z"
          className="Subtract"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M16 10H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2ZM4 8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4H4Z"
          className="Rectangle 727 (Stroke)"
          clipRule="evenodd"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M10 14.01 6.454 18h7.092L10 14.01Zm-5.041 2.661 3.546-3.99a2 2 0 0 1 2.99 0l3.546 3.99c1.147 1.29.23 3.329-1.495 3.329H6.454c-1.726 0-2.642-2.04-1.495-3.329Z"
          className="Vector 142 (Stroke)"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Photos24,
  sm: Photos24,
  lg: Photos24,
  xl: Photos24,
  xxl: Photos24,
  xxxl: Photos24,
};

export const PhotosIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'photos-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { SubmitHandler } from 'react-hook-form';

import { SrOnly } from '@jane/brands/components';
import { useBrandsNavigate } from '@jane/brands/hooks';
import type {
  CreateLocalizationParams,
  EditFormProduct,
} from '@jane/catalog-cms/data-access';
import { createLocalization } from '@jane/catalog-cms/data-access';
import { editFormProductQueryKey } from '@jane/catalog-cms/hooks';
import { reconcileValue } from '@jane/catalog-cms/util';
import { ApiRequestError } from '@jane/shared/data-access';
import {
  Box,
  Button,
  Drawer,
  Flex,
  FormValidationError,
  Link,
  useToast,
} from '@jane/shared/reefer';

import { NavigationDropdown } from '../../NavigationDropdown';
import { DrawerHeader } from '../DrawerHeader';
import { CategoryDetails } from '../shared/CategoryDetails/CategoryDetails';
import { ProductHeader } from '../shared/ProductHeader/ProductHeader';
import { ProductHistory } from '../shared/ProductHistory/ProductHistory';
import { ProductNotice } from '../shared/ProductNotice';
import { ReadonlyValues } from '../shared/ReadonlyValues/ReadonlyValues';
import type { CreateLocalizationFormData } from './CreateLocalizationForm';
import { CreateLocalizationForm } from './CreateLocalizationForm';

export interface CreateLocalizationProps {
  parentProduct: EditFormProduct;
}
export const CreateLocalization = ({
  parentProduct,
}: CreateLocalizationProps) => {
  const formId = 'create-localization';
  const toast = useToast();
  const queryClient = useQueryClient();
  const brandsNavigate = useBrandsNavigate();

  const localizationCreateMutation = useMutation({
    mutationFn: createLocalization,
    onSuccess: () => {
      return queryClient.invalidateQueries(
        editFormProductQueryKey(parentProduct.id)
      );
    },
  });

  const onSubmit: SubmitHandler<CreateLocalizationFormData> = async (data) => {
    const createParams = toCreateLocalizationParams(
      data,
      parentProduct.id,
      parentProduct
    );

    validateCreateData(createParams, parentProduct);

    try {
      const { productLocalizationId: localizationId } =
        await localizationCreateMutation.mutateAsync(createParams);

      toast.add({ label: 'New state view saved!', variant: 'success' });
      brandsNavigate.editLocalization(parentProduct.id, localizationId);
    } catch (error) {
      if (
        error instanceof ApiRequestError &&
        error.response.status.toString().match(/^4/)
      ) {
        const { errors } = await error.response.json();

        if (Array.isArray(errors)) {
          const message = errors.join(', ') + '.';

          throw new FormValidationError('create-localization', [
            { name: 'form', message },
          ]);
        }
      }
      throw new Error(
        [
          'There was an unexpected error when submitting the form.',
          'Please try again or contact support if this problem persists.',
        ].join(' ')
      );
    }
  };

  return (
    <>
      <DrawerHeader showDismiss>
        <Flex grow={1} justifyContent="end">
          <Button
            label="Save"
            variant="secondary"
            form={formId}
            type="submit"
          />
        </Flex>
      </DrawerHeader>
      <Drawer.Content data-testid="drawer-content">
        <SrOnly as="h2">Create new state view</SrOnly>
        <ProductHeader {...parentProduct} />
        <CategoryDetails {...parentProduct} />

        <Box pt={24}>
          <NavigationDropdown label={'Add a state'}>
            <NavigationDropdown.ListItem>
              <Link
                variant="minimal"
                onClick={() => brandsNavigate.editProduct(parentProduct.id)}
              >
                Default
              </Link>
            </NavigationDropdown.ListItem>
            <NavigationDropdown.Localizations
              localizations={parentProduct.localizations}
              productId={parentProduct.id}
            />
          </NavigationDropdown>
        </Box>

        <Box pt={24} pb={24}>
          <CreateLocalizationForm
            formId={formId}
            onSubmit={onSubmit}
            parentProduct={parentProduct}
            key={parentProduct.id}
          />
        </Box>

        <Drawer.ContentDivider />

        <Box pt={24} pb={24}>
          <ReadonlyValues {...parentProduct} />
        </Box>
        <Drawer.ContentDivider />

        <Box py={24}>
          <ProductHistory {...parentProduct} />
        </Box>

        <ProductNotice {...parentProduct} />
      </Drawer.Content>
    </>
  );
};

/**
 * Utility that takes the output from the create localization form and converts
 * it to the format expected by the create localization function. This also
 * compares the form data with the parent product replacing any matching values
 * with 'null' which signifies a field that should fallback to the parent
 * product's data.
 */

const toCreateLocalizationParams = (
  data: CreateLocalizationFormData,
  productId: string,
  parentProduct: Pick<
    EditFormProduct,
    'name' | 'description' | 'lineage' | 'categoryLabel' | 'imageURLs'
  >
): CreateLocalizationParams => {
  const name = reconcileValue(data.name, parentProduct.name);
  const lineage = reconcileValue(data.lineage, parentProduct.lineage);
  const categoryLabel = reconcileValue(
    data.categoryLabel,
    parentProduct.categoryLabel
  );
  const description = reconcileValue(
    data.description,
    parentProduct.description
  );
  const images = reconcileValue(data.imageURLs, parentProduct.imageURLs);

  const [country, subdivision] = data.subdivision.split('-');

  return {
    productUUID: productId,
    name,
    lineage,
    description,
    categoryLabel,
    images,
    country,
    subdivision,
  };
};

const CREATE_FIELDS: (keyof CreateLocalizationParams)[] = [
  'name',
  'lineage',
  'categoryLabel',
  'description',
  'images',
];

const validateCreateData = (
  data: Record<string, unknown>,
  parentProduct: EditFormProduct
) => {
  const errors = [];

  if (CREATE_FIELDS.every((key) => data[key as string] === null)) {
    errors.push({
      name: 'form',
      message: 'At least one field must differ from the product.',
    });
  }

  if (parentProduct.category?.match(/^flower$/i) && data['lineage'] === '') {
    errors.push({
      name: 'form',
      message: 'Lineage is required for products with category: Flower',
    });
  }

  if (data['name'] === '') {
    errors.push({
      name: 'name',
      message:
        'Cannot be blank, revert to default if state specific name should not differ from the product name',
    });
  }

  if (data['description'] === '') {
    errors.push({
      name: 'description',
      message:
        'Cannot be blank, revert to default if state specific description should not differ from the product description.',
    });
  }

  if (!data['country'] || !data['subdivision']) {
    errors.push({ name: 'subdivision', message: 'State is required' });
  }

  if (errors.length > 0) {
    throw new FormValidationError('edit-localization', errors);
  }
};

import { ConfirmChangeModal } from './ConfirmChangeModal';

export const ConfirmDeleteModal = ({
  confirmDelete,
  open,
  setOpen,
  title = 'Delete?',
  subtitle = 'This will be permanently deleted.',
}: {
  confirmDelete: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  subtitle?: string;
  title?: string;
}) => {
  const handleConfirm = () => {
    setOpen(false);
    confirmDelete();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <ConfirmChangeModal
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      open={open}
      title={title}
      subtitle={subtitle}
      confirmButtonVariant="destructive"
    />
  );
};

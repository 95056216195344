/**
 * Parse a string of ids separated by comma, space, or newline delimiters.
 * Handles numbers with commas (e.g., "1,000") by removing commas from numbers.
 * @param {string} ids - The string of ids to parse.
 * @returns {string[]} An array of ids.
 */
export const parseIds = (ids: string): string[] => {
  if (!ids.trim()) return [];

  // First, protect numbers with commas by replacing them with a placeholder
  const numericIds = ids.replace(/\b\d{1,3}(,\d{3})+\b/g, (match) =>
    match.replace(/,/g, '')
  );

  // Then split by delimiters
  const splitIds = numericIds.split(/\s*,\s*|\s+/);

  return splitIds.map((id) => id.trim()).filter(Boolean); // Remove empty strings
};

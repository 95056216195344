import {
  FilterListCheckbox,
  FilterListCheckboxArrow,
  FilterListItem,
} from '@jane/shared-ecomm/components';
import { CheckboxField, ChevronDownIcon } from '@jane/shared/reefer';

import type { CategoryOption } from './CategorySelectInputModal';

interface CategoryCheckboxProps {
  category: CategoryOption;
  children?: React.ReactNode;
  hasSubItems?: boolean;
  indeterminate?: boolean;
  isParentCategoryChecked?: boolean;
  isSubItem?: boolean;
  onChange: (value: string) => void;
  parentCategoryValue?: string;
  selectedCategories: CategoryOption[];
  setShowSubcategories?: React.Dispatch<React.SetStateAction<boolean>>;
  showSubcategories?: boolean;
  subCategoryValues?: { label: string; value: string }[];
}

export const CategoryCheckbox = ({
  children,
  category,
  hasSubItems,
  indeterminate,
  isParentCategoryChecked,
  isSubItem,
  onChange,
  parentCategoryValue,
  selectedCategories,
  setShowSubcategories,
  showSubcategories,
  subCategoryValues,
}: CategoryCheckboxProps) => {
  const { label, value } = category;
  const isChecked = selectedCategories.some(
    (category: CategoryOption) => category.value === value
  );

  return (
    <FilterListItem>
      <FilterListCheckbox subItem={isSubItem} hasSubItems={hasSubItems}>
        <CheckboxField
          checked={isChecked}
          name={label}
          label={label}
          onChange={(checked) => {
            if (checked !== isChecked) {
              if (isSubItem && isParentCategoryChecked && parentCategoryValue) {
                onChange(parentCategoryValue);
              }
              onChange(value);
            }

            if (!checked && indeterminate) {
              subCategoryValues?.forEach(
                (subCategory) =>
                  selectedCategories.some(
                    (category) => category.value === subCategory.value
                  ) && onChange(subCategory.value)
              );
            }
          }}
          indeterminate={indeterminate}
        />
        {hasSubItems && (
          <FilterListCheckboxArrow>
            <ChevronDownIcon
              onClick={() =>
                setShowSubcategories && setShowSubcategories(!showSubcategories)
              }
              rotate={showSubcategories ? 'down' : 'up'}
              altText={`Click to ${
                showSubcategories ? 'hide' : 'show'
              } subcategory filters`}
            />
          </FilterListCheckboxArrow>
        )}
      </FilterListCheckbox>
      {children}
    </FilterListItem>
  );
};

import { useRef } from 'react';

/*
  While editing freshly uploaded assets that have not yet been saved to a theme, the new assets do not have an id
  associated with them. react-dnd-kit requires that all sortable items have a unique id, so we use a negative number
  to indicate that an asset's id is temporary.
 */
export const isAssetIdTemporary = (id: number): boolean => id < 0;
export const useTempAssetId = () => {
  const id = useRef(-1);
  return () => {
    const { current } = id;
    id.current = current - 1;
    return current;
  };
};

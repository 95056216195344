import styled from '@emotion/styled';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import {
  CheckboxField,
  Flex,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

import type { Brand } from './brandSelectModal';

interface BrandCheckboxProps {
  brand: Brand;
  'data-testid'?: string;
  divider?: boolean;
  inSelectedGroup?: boolean;
  onClick: (brand: Brand) => void;
  selected: boolean;
}

type ContainerProps = Pick<BrandCheckboxProps, 'divider' | 'inSelectedGroup'>;

const Container = styled.li<ContainerProps>(
  ({ divider, inSelectedGroup, theme }) => ({
    cursor: 'pointer',
    listStyle: 'none',
    boxShadow: divider ? shadows.dividerTop : undefined,
    ...(!inSelectedGroup && {
      '&:hover, &:focus': {
        backgroundColor: theme.colors.transparent.black.five,
      },
    }),
  })
);

export const BrandCheckbox = forwardRef(
  (
    {
      brand,
      'data-testid': testId,
      divider,
      inSelectedGroup,
      selected,
      onClick,
    }: BrandCheckboxProps,
    ref: ForwardedRef<HTMLLIElement>
  ) => (
    <Container
      aria-label={`select brand ${brand.name}`}
      data-testid={testId}
      divider={divider}
      inSelectedGroup={inSelectedGroup}
      onClick={() => onClick?.(brand)}
      role="button"
      ref={ref}
    >
      <Flex alignItems="center" justifyContent="space-between" py={12} px={24}>
        <Flex flexDirection="row" alignItems="center" gap={16}>
          <PartnerLogo
            name={brand.name}
            variant="brand"
            size="xs"
            image={brand.logoUrl}
          />
          <Typography variant="body-bold">{brand.name}</Typography>
        </Flex>

        <CheckboxField
          label={`Select brand ${brand.name}`}
          name={`${brand.id}-checkbox`}
          checked={selected}
          labelHidden
        />
      </Flex>
    </Container>
  )
);

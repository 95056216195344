import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { removeNotice } from '@jane/catalog-cms/data-access';
import {
  editFormProductQueryKey,
  updateInfiniteProductsCache,
  useProductFilters,
  useSelectedBrands,
} from '@jane/catalog-cms/hooks';
import { ApiRequestError } from '@jane/shared/data-access';
import { Banner, Box, Drawer, useToast } from '@jane/shared/reefer';

import { NotifyJane } from './NotifyJane/NotifyJane';
import { ProductNoticeSummary } from './ProductNoticeSummary/ProductNoticeSummary';

export type ProductNoticeProps = Pick<
  EditFormProduct,
  'id' | 'reportedTime' | 'status' | 'reportData'
>;

export const ProductNotice = ({
  status,
  reportedTime,
  id: productUuid,
  reportData,
}: ProductNoticeProps) => {
  const toast = useToast();

  const queryClient = useQueryClient();
  const { filters } = useProductFilters();
  const { selectedBrandIds } = useSelectedBrands();

  const noticeMutation = useMutation({
    mutationFn: removeNotice,
    onSuccess: (_, productUuid) => {
      const updateProductListItem = {
        id: productUuid,
        status: 'Active' as const,
      };

      return Promise.all([
        queryClient.invalidateQueries(editFormProductQueryKey(productUuid)),
        updateInfiniteProductsCache([updateProductListItem], queryClient, {
          ...filters,
          brand: selectedBrandIds,
        }),
      ]);
    },
  });

  // If remove notice error is an ApiRequestError just display message,
  // otherwise throw
  if (
    noticeMutation.isError &&
    !(noticeMutation.error instanceof ApiRequestError)
  ) {
    throw noticeMutation.error;
  }

  const handleCancelReport = () => {
    noticeMutation.reset();

    noticeMutation.mutate(productUuid, {
      onError: () => {
        toast.add({
          label: 'There was an error trying to cancel this report.',
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast.add({ label: 'Report cancelled', variant: 'success' });
      },
    });
  };

  if (status === 'Active' || reportedTime === null) {
    return (
      <Box pb={24}>
        <NotifyJane />
      </Box>
    );
  }

  return (
    <>
      <Drawer.ContentDivider />
      <RemoveErrorBanner
        hasRemoveError={noticeMutation.isError}
        onDismiss={() => noticeMutation.reset()}
      />
      <Box py={24}>
        <ProductNoticeSummary
          status={status}
          reportedTime={reportedTime}
          comment={reportData?.comment}
          activeProduct={reportData?.activeProduct}
          handleCancelReport={handleCancelReport}
        />
      </Box>
    </>
  );
};

const REMOVE_ERROR_MESSAGE = [
  'There was an unexpected error when trying to cancel this report.',
  'Please try again later or contact support if this problem persists.',
].join(' ');

/**
 * Error banner that gives user more context when a state fails to be removed.
 */
const RemoveErrorBanner = ({
  hasRemoveError,
  onDismiss = () => null,
}: {
  hasRemoveError: boolean;
  onDismiss?: () => void;
}) => {
  if (hasRemoveError) {
    return (
      <Banner
        label={REMOVE_ERROR_MESSAGE}
        variant="error"
        onDismiss={onDismiss}
        my={16}
      />
    );
  }
  return null;
};

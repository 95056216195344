import type { BidModifiers } from '@jane/ad-manager/types';

import { FlightDetailSection } from './FlightDetailSection';

type Props =
  | {
      bidModifiers?: BidModifiers;
      creditPercent?: number;
      priority: true;
      totalBudget: string;
    }
  | {
      bidModifiers?: BidModifiers;
      cpmBid: string;
      creditPercent?: number;
      dailyBudget: string;
      priority?: false;
    };
export const PricingDetails = (props: Props) => {
  if (props.priority) {
    const { totalBudget, creditPercent } = props;
    return (
      <FlightDetailSection
        data-testid="pricing-details"
        headerLabel="Pricing"
        rows={[
          [
            { name: 'Priority', value: 'Enabled' },
            { name: 'Total Budget', value: totalBudget },
            {
              name: 'Flight Credit Percentage',
              value: `${String(creditPercent)}%`,
            },
          ],
        ]}
        bidModifiers={props.bidModifiers}
      />
    );
  }

  const { dailyBudget, cpmBid, creditPercent } = props;
  return (
    <FlightDetailSection
      data-testid="pricing-details"
      headerLabel="Pricing"
      rows={[
        [
          { name: 'Priority', value: 'Off' },
          { name: 'Daily Budget', value: dailyBudget },
          { name: 'Cost Per 1,000 Impression Bid', value: cpmBid },
          {
            name: 'Flight Credit Percentage',
            value: `${String(creditPercent)}%`,
          },
        ],
      ]}
      bidModifiers={props.bidModifiers}
    />
  );
};

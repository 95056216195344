import React from 'react';
import type { SubmitHandler } from 'react-hook-form';

import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { Box, Drawer, Form, useForm } from '@jane/shared/reefer';

import { Images } from '../shared/Images';
import { EditProductInputs } from './EditProductInputs';

export interface EditProductFormProps {
  formId: string;
  onSubmit: SubmitHandler<EditProductFormData>;
  product: EditFormProduct;
}

export type EditProductFormData = {
  categoryLabel: string;
  description: string;
  imageURLs: string[];
  lineage: string;
  name: string;
};

const defaultValuesFromProduct = (
  product: EditFormProduct
): EditProductFormData => {
  return {
    categoryLabel: product.categoryLabel || '',
    description: product.description || '',
    imageURLs: product.imageURLs || [],
    lineage: product.lineage || '',
    name: product.name || '',
  };
};

export const EditProductForm = ({
  formId,
  onSubmit,
  product,
}: EditProductFormProps) => {
  const defaultValues = defaultValuesFromProduct(product);
  const formMethods = useForm<EditProductFormData>({
    defaultValues,
  });

  return (
    <Form.BaseForm
      id={formId}
      name="edit-product"
      autocomplete="off"
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <Box pb={24}>
        <Form.ErrorBanner />
        <EditProductInputs />
      </Box>

      <Drawer.ContentDivider />

      <Box pt={24}>
        <Images defaultValue={defaultValues.imageURLs} />
      </Box>
    </Form.BaseForm>
  );
};

import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { Box } from '../../box/box';
import { Flex } from '../../flex/flex';
import type { GridItemProps, GridSizes } from '../grid.types';
import styles from './gridItem.module.css';

const getItemSize = function (size?: GridSizes) {
  if (size === 'fill' || !size) {
    return 'none';
  }

  return `${100 * (size / 12)}%`;
};

/**
 * Each individual grid item within the `Grid.Container` component is defined using the `Grid.Item` compoennt.
 */
export function GridItem({
  ariaLabel,
  children,
  className,
  'data-testid': testId,
  direction = 'row',
  id,
  onClick,
  spacing = 0,
  style,
  xs,
  sm,
  md,
  lg,
  xl,
  ...paddingProps
}: GridItemProps) {
  return (
    <Flex
      ariaLabel={ariaLabel}
      className={clsx(
        className,
        styles.gridItem,
        styles[`gridItem__direction_${direction}`],
        {
          [styles[`gridItem__xs_direction_${direction}`]]:
            typeof xs === 'number',
          [styles.gridItem__xs_fill]: xs === 'fill',
          [styles.gridItem__xs_size]: typeof xs === 'number',
          [styles[`gridItem__sm_direction_${direction}`]]:
            typeof sm === 'number',
          [styles.gridItem__sm_fill]: sm === 'fill',
          [styles.gridItem__sm_size]: typeof sm === 'number',
          [styles[`gridItem__md_direction_${direction}`]]:
            typeof md === 'number',
          [styles.gridItem__md_fill]: md === 'fill',
          [styles.gridItem__md_size]: typeof md === 'number',
          [styles[`gridItem__lg_direction_${direction}`]]:
            typeof lg === 'number',
          [styles.gridItem__lg_fill]: lg === 'fill',
          [styles.gridItem__lg_size]: typeof lg === 'number',
          [styles[`gridItem__xl_direction_${direction}`]]:
            typeof xl === 'number',
          [styles.gridItem__xl_fill]: xl === 'fill',
          [styles.gridItem__xl_size]: typeof xl === 'number',
        }
      )}
      data-testid={testId}
      id={id}
      onClick={onClick}
      style={
        {
          '--gridItem-size-xs': getItemSize(xs),
          '--gridItem-size-sm': getItemSize(sm),
          '--gridItem-size-md': getItemSize(md),
          '--gridItem-size-lg': getItemSize(lg),
          '--gridItem-size-xl': getItemSize(xl),
          '--gridItem-padding-bottom': spacing,
          '--gridItem-padding-left': spacing,
          ...style,
        } as CSSProperties
      }
    >
      <Box height="100%" width="100%" {...paddingProps}>
        {children}
      </Box>
    </Flex>
  );
}

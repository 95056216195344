import type { ProductListItem } from '@jane/catalog-cms/data-access';
import type { TagProps } from '@jane/shared/reefer';
import { Tag } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

type Status = ProductListItem['status'];
type StatusAttributes = Required<Pick<TagProps, 'background' | 'color'>>;

const STATUS_ATTRIBUTE_MAP: {
  [key in Status]?: StatusAttributes;
} = {
  Active: { background: 'palm-light', color: 'palm-dark' },
  Duplicate: { background: 'error-light', color: 'error-dark' },
  'Incorrect Brand': { background: 'error-light', color: 'error-dark' },
  Discontinued: { background: 'error-light', color: 'error-dark' },
};

export const StatusCell = ({ status }: { status: Status }) => {
  const statusAttr = STATUS_ATTRIBUTE_MAP[status];
  return (
    <Table.Cell>
      <Tag label={status} {...statusAttr} />
    </Table.Cell>
  );
};

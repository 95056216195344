import { z } from 'zod';

const MediaComplianceSchema = z.object({ hide_in_state: z.string() });

const BrandMediaTagSchema = z.object({
  id: z.number(),
  tag: z.string(),
});

const BrandMediaTagWithMediaCompliancesSchema = BrandMediaTagSchema.extend({
  media_compliances: z.array(MediaComplianceSchema),
});

const ApiCustomImageCommonSchema = z.object({
  cta_text: z.nullable(z.string()),
  cta_url: z.nullable(z.string()),
  description: z.nullable(z.string()),
  image_url: z.string(),
  position: z.number(),
});

const ApiCustomImageIncomingSchema = ApiCustomImageCommonSchema.extend({
  id: z.number(),
  brand_media_tags: z.array(BrandMediaTagSchema),
});
export type ApiCustomImageIncoming = z.infer<
  typeof ApiCustomImageIncomingSchema
>;

const ApiCustomImageOutgoingSchema = ApiCustomImageCommonSchema.extend({
  id: z.nullable(z.number()),
  brand_media_tags: z.array(BrandMediaTagSchema),
  _destroy: z.boolean(),
});
export type ApiCustomImageOutgoing = z.infer<
  typeof ApiCustomImageOutgoingSchema
>;

const ApiCustomVideoCommonSchema = z.object({
  overlay_image_url: z.string(),
  overlay_text: z.nullable(z.string()),
  video_url: z.string().min(1),
});

const ApiCustomVideoIncomingSchema = ApiCustomVideoCommonSchema.extend({
  id: z.number(),
  brand_media_tags: z.array(BrandMediaTagSchema),
  position: z.nullable(z.number()),
});
export type ApiCustomVideoIncoming = z.infer<
  typeof ApiCustomVideoIncomingSchema
>;

const ApiCustomVideoOutgoingSchema = ApiCustomVideoCommonSchema.extend({
  id: z.nullable(z.number()),
  position: z.number(),
  brand_media_tags: z.array(BrandMediaTagSchema),
  _destroy: z.boolean(),
});
export type ApiCustomVideoOutgoing = z.infer<
  typeof ApiCustomVideoOutgoingSchema
>;

const ApiProductBrandSchema = z.object({
  name: z.string(),
  normalized_name: z.nullable(z.string()),
});

export type ApiProductBrand = z.infer<typeof ApiProductBrandSchema>;

const ApiBrandThemeCommonSchema = z.object({
  custom_image_url: z.nullable(z.string()),
  description: z.nullable(z.string()),
  logo_url: z.nullable(z.string()),
  product_brand_id: z.number(),
  rank: z.nullable(z.number()),
});

export const ApiBrandThemeIncomingSchema = ApiBrandThemeCommonSchema.extend({
  color_hex: z.nullable(z.string()),
  draft: z.boolean(),
  pbt_custom_images: z.array(ApiCustomImageIncomingSchema),
  pbt_custom_video: z.nullable(ApiCustomVideoIncomingSchema).optional(),
  product_brand: ApiProductBrandSchema,
  updated_at: z.string().datetime(),
});

export const ApiBrandThemeAndMediaTagsIncomingSchema = z.object({
  product_brand_theme: ApiBrandThemeIncomingSchema,
  media_tags: z.array(BrandMediaTagWithMediaCompliancesSchema),
});

export type ApiBrandThemeIncoming = z.infer<typeof ApiBrandThemeIncomingSchema>;

export type ApiBrandThemeAndMediaTagsIncoming = z.infer<
  typeof ApiBrandThemeAndMediaTagsIncomingSchema
>;

export const ApiBrandThemeOutgoingSchema = ApiBrandThemeCommonSchema.extend({
  color_hex: z.string(),
  pbt_custom_images_attributes: z.array(ApiCustomImageOutgoingSchema),
  pbt_custom_video_attributes: z.nullable(ApiCustomVideoOutgoingSchema),
});
export type ApiBrandThemeOutgoing = z.infer<typeof ApiBrandThemeOutgoingSchema>;

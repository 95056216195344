import { Global } from '@emotion/react';
import { Outlet } from 'react-router-dom';

import { useBrandsNavigate } from '@jane/brands/hooks';
import { Drawer } from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

export const ProductDrawerLayout = () => {
  const brandsNavigate = useBrandsNavigate();

  const handleDrawerClose = () => {
    brandsNavigate.productList();
  };

  return (
    <>
      <Global
        styles={{
          '.rc-drawer-content-wrapper': { boxShadow: shadows.hard },
        }}
      />

      <Drawer
        onRequestClose={handleDrawerClose}
        open={true}
        width="668px"
        allowScroll
        hideOverlay
      >
        <Outlet />
      </Drawer>
    </>
  );
};

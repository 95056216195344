import styled from '@emotion/styled';

import { LINEAGE_OPTIONS } from '@jane/catalog-cms/util';
import { Box, Form, Grid, useFormContext } from '@jane/shared/reefer';

export const EditProductInputs = () => {
  const { setValue, watch } = useFormContext();

  const trimOnBlur = (fieldName: string) => {
    const value = watch(fieldName) as string;
    setValue(fieldName, value.trim());
  };

  return (
    <Box>
      <StyledGridContainer>
        <Grid.Item xs={12}>
          <Form.TextField
            name="name"
            label="Name"
            required
            maxLength={255}
            onBlur={() => trimOnBlur('name')}
          />
        </Grid.Item>
        <Grid.Item xs={12} md={6}>
          <Form.SelectField
            name="lineage"
            label="Lineage"
            options={LINEAGE_OPTIONS}
          />
        </Grid.Item>
        <Grid.Item xs={12} md={6}>
          <Form.TextField
            name="categoryLabel"
            label="Brand Category"
            maxLength={255}
            onBlur={() => trimOnBlur('categoryLabel')}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.TextAreaField
            name="description"
            label="Description"
            resize={true}
            rows={8}
            required
            onBlur={() => trimOnBlur('description')}
          />
        </Grid.Item>
      </StyledGridContainer>
    </Box>
  );
};

const StyledGridContainer = styled(Grid.Container)({ height: 'fit-content' });

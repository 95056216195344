import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

import type { FilterParams } from '@jane/ad-manager/types';
import type { ActiveFilters } from '@jane/search/types';
import { Flex } from '@jane/shared/reefer';
import { us_states } from '@jane/shared/util';

import { DateRangeFilter } from './Filters/DateRange';
import { MultiSelectFilter } from './Filters/MultiSelectFilter';

const AD_TYPE_OPTIONS = ['CPC', 'CPM', 'FLAT'].map((value) => ({
  value: value,
  label: value,
}));

const locationOptions = us_states.map((value) => ({
  label: value.name,
  value: value.abbreviation || value.name,
}));

interface Props {
  filterParams: FilterParams;
  onChangeFilterParams: (filters: FilterParams) => void;
}

export const StatisticsFilters = ({
  filterParams,
  onChangeFilterParams,
}: Props) => {
  const filterParamsAsActiveFilters = Object.entries(filterParams).reduce(
    (acc, [key, filterValues]) => {
      if (Array.isArray(filterValues)) {
        return [
          ...acc,
          ...filterValues.map((value) => ({
            key: key,
            label: value,
            value: value,
          })),
        ] as ActiveFilters[];
      } else {
        return [
          ...acc,
          { key: key, label: filterValues, value: filterValues },
        ] as ActiveFilters[];
      }
    },
    [] as ActiveFilters[]
  );

  const [activeFilters, setActiveFilters] = useState<ActiveFilters[]>(
    filterParamsAsActiveFilters
  );

  useEffect(() => {
    if (!isEqual(filterParamsAsActiveFilters, activeFilters)) {
      // Update active filters if filter params changed
      setActiveFilters(filterParamsAsActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParamsAsActiveFilters]);

  return (
    <Flex gap={16}>
      <MultiSelectFilter
        filterParams={filterParams}
        setMultiSelectParams={onChangeFilterParams}
        options={locationOptions}
        filterType="locations"
        typeahead={true}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />
      <MultiSelectFilter
        filterParams={filterParams}
        setMultiSelectParams={onChangeFilterParams}
        options={AD_TYPE_OPTIONS}
        filterType="types"
        typeahead={false}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />
      <DateRangeFilter
        onChangeFilterParams={onChangeFilterParams}
        filterParams={filterParams}
      />
    </Flex>
  );
};

import { brandsPaths } from '@jane/brands/util';
import { Flex, Form, Link } from '@jane/shared/reefer';

import { StyledSubmit } from './StyledSubmitButton';

type HandleSubmit = ({
  password,
  confirmPassword,
}: {
  confirmPassword: string;
  password: string;
}) => Promise<unknown>;

export const ResetPasswordForm = ({
  handleSubmit,
}: {
  handleSubmit: HandleSubmit;
}) => {
  return (
    <Form name="password-reset" onSubmit={handleSubmit} width="100%">
      <Flex flexDirection="column" gap={40}>
        <Form.ErrorBanner />
        <Form.TextField
          label="New password"
          name="password"
          type="password"
          required
        />
        <Form.TextField
          label="Confirm password"
          name="confirmPassword"
          type="password"
          pattern={/.*/}
          required
        />
        <Flex
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <StyledSubmit label="Reset password" />
          <Link to={brandsPaths.login()}>Return to login</Link>
        </Flex>
      </Flex>
    </Form>
  );
};

import styled from '@emotion/styled';

import { Form } from '@jane/shared/reefer';

/**
 * Form.SubmitButton that is styled for login and forgot password pages. Button will take up 35% of width of its flex container, keeping buttons between pages the same size.
 */
export const StyledSubmit = styled(Form.SubmitButton)({
  flex: '0 1 35%',
  minWidth: 'fit-content',
});

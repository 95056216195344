import type {
  CustomImage,
  CustomMedia,
  CustomVideo,
} from '@jane/ad-manager/types';

export const combineCustomMedia = (
  customImages: CustomImage[],
  customVideo: CustomVideo | undefined
): CustomMedia[] => {
  const customMedia: CustomMedia[] = [...customImages];
  if (customVideo) customMedia.push(customVideo);
  return customMedia.sort((a, b) => a.position - b.position);
};

export const getCustomMediaDescription = (media: CustomMedia) =>
  media._mediaType === 'video' ? media.overlayText : media.description;

export const getCustomMediaUrl = (media: CustomMedia) =>
  media._mediaType === 'video' ? media.overlayImageUrl : media.imageUrl;

import type { QueryFunctionContext } from '@tanstack/react-query';
import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

const LocalizationProductSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  category: z.string().nullable(),
  subcategory: z.string().nullable(),
  categoryLabel: z.string().nullable(),
  description: z.string(),
  images: z.array(z.string()),
});

const LocalizationSchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  product: LocalizationProductSchema,
  country: z.string(),
  subdivision: z.string(),
  label: z.string(),
  categoryLabel: z.string().nullable(),
  description: z.string().nullable(),
  lineage: z.string().nullable(),
  images: z.array(z.string()).nullable(),
  updatedTime: z.string().datetime(),
  createdTime: z.string().datetime(),
  version: z.number().nonnegative(),
});
const LocalizationBodySchema = z.object({ data: LocalizationSchema });

export type Localization = z.infer<typeof LocalizationSchema>;
export type LocalizationProduct = z.infer<typeof LocalizationProductSchema>;
export type LocalizationBody = z.infer<typeof LocalizationBodySchema>;

export const fetchLocalization = async ({
  queryKey,
}: QueryFunctionContext<
  [string, { localizationId: string; productId: string }]
>) => {
  const { productId, localizationId } = queryKey[1];
  const path = brandServerPaths.localization(productId, localizationId);
  const { data } = await brandsApi.get(path);

  const parsedData = parseData(LocalizationBodySchema, data);
  return parsedData.data;
};

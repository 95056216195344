import type * as ReactTable from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import type { FetchProductsResponse } from '@jane/ad-manager/data-access';
import { useFetchProductFilters } from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';

import type { ProductTableWithMeta } from './ProductTable';
import { useProductTableColumns } from './useProductTableColumns';

interface Opts {
  columnFilters: ReactTable.ColumnFiltersState;
  productCount: number;
  productData: FetchProductsResponse['products'];
  setColumnFilters: ReactTable.OnChangeFn<ReactTable.ColumnFiltersState>;
  setSorting: ReactTable.OnChangeFn<ReactTable.SortingState>;
  sorting: ReactTable.SortingState;
}
export const useProductTable = ({
  columnFilters,
  productData,
  productCount,
  sorting,
  setColumnFilters,
  setSorting,
}: Opts): ProductTableWithMeta => {
  const { selectedBrand } = useSelectedBrandContext();
  const filtersQuery = useFetchProductFilters({ brandId: selectedBrand?.id });
  const { data: filtersQueryData } = filtersQuery;

  const columns = useProductTableColumns({ filtersQueryData, productCount });

  return useReactTable({
    columns,
    defaultColumn: {
      size: 200,
    },
    state: { columnFilters, sorting },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    data: productData,
    getRowId: (rowData) => rowData.id.toString(),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });
};

import { Flex, Typography } from '@jane/shared/reefer';

import type { StoreSearchMode } from './StoreSelectModal';

export const NoStoresFound = ({
  searchMode,
}: {
  searchMode: StoreSearchMode;
}) => (
  <Flex flexDirection="column" alignItems="center" mt={128}>
    <Typography variant="header-bold">No Stores available</Typography>
    <Typography>
      As you search for
      {searchMode === 'storeId' ? ' IDs' : ' store names'}, stores will be
      displayed here.
    </Typography>
  </Flex>
);

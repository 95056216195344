import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { Flex } from '@jane/shared/reefer';

import { TitleValuePair } from '../TitleValuePair';

export type ReadonlyValuesProps = Pick<
  EditFormProduct,
  'dosage' | 'amount' | 'thcDosageMilligrams' | 'cbdDosageMilligrams' | 'status'
>;

export const ReadonlyValues = ({
  dosage,
  amount,
  thcDosageMilligrams,
  cbdDosageMilligrams,
  status,
}: ReadonlyValuesProps) => {
  return (
    <Flex flexDirection="column" gap={24}>
      <Flex flexDirection="row">
        <TitleValuePair title="Dosage" value={dosage} />
        <TitleValuePair title="Amount" value={amount} />
      </Flex>
      <Flex flexDirection="row">
        <TitleValuePair title="THC Dosage" value={thcDosageMilligrams} />
        <TitleValuePair title="CBD Dosage" value={cbdDosageMilligrams} />
      </Flex>
    </Flex>
  );
};

import type { MouseEvent } from 'react';
import { useState } from 'react';

import type { ReeferBaseProps } from '../../utils/baseProps.types';
import { Link } from '../link/link';

export interface ReadMoreProps extends ReeferBaseProps {
  /** Number of characters to display before truncating, defaults to 250 */
  characters?: number;

  /** Text content of the ReadMore component */
  content: string;

  /** Label to show less text, defaults to 'Show less' */
  less?: string;

  /** Label to show all text, defaults to 'Read more' */
  more?: string;
}

/**
 * `ReadMore` component truncates text to a specified number of lines,
 *  giving the option to show more or less text
 */
export const ReadMore = ({
  'data-testid': testId,
  characters = 250,
  className,
  content,
  id,
  less = 'Show less',
  more = 'Read more',
  style,
}: ReadMoreProps) => {
  const [expanded, setExpanded] = useState(false);

  const isTruncated = content.length > characters;

  const toggleLines = (e: MouseEvent) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className={className} data-testid={testId} id={id} style={style}>
      <span>
        {isTruncated && !expanded ? content.substring(0, characters) : content}
      </span>
      {isTruncated && (
        <span>
          {expanded ? ' ' : '...'}
          <Link onClick={toggleLines}>{!expanded ? more : less}</Link>
        </span>
      )}
    </div>
  );
};

import type {
  ColumnFiltersState,
  Row,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import isEqual from 'lodash/isEqual';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useController } from 'react-hook-form';

import type {
  FetchProductsResponse,
  Product,
} from '@jane/ad-manager/data-access';
import { Button, Flex, TrashIcon, Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import type { FlatAdSubmissionForm } from '../../useAdBuilderForm';
import { ProductImage } from './ProductImage';
import { ProductTableModal } from './ProductTable/ProductTableModal';
import { useProductTable } from './ProductTable/useProductTable';
import { useProductTableQuery } from './ProductTable/useProductTableQuery';

const TableHeaderCell = ({ children }: { children?: ReactNode }) => (
  <Table.HeaderCell>
    <Typography color="grays-mid">{children}</Typography>
  </Table.HeaderCell>
);

const productIdsToSelectionState = (
  productIds: string[]
): RowSelectionState => {
  return productIds.reduce<RowSelectionState>((acc, pid) => {
    acc[pid] = true;
    return acc;
  }, {});
};

const productSelectionStateToRows = (
  selectionState: RowSelectionState,
  productRowsCacheById: Record<string, Row<Product>>
) =>
  Object.keys(selectionState)
    .filter((pid) => productRowsCacheById[pid])
    .map((pid) => productRowsCacheById[parseInt(pid)]);

export const SHOW_ALL_THRESHOLD = 5;

export const ProductSelector = () => {
  const {
    field: { value: productIds, onChange: onChangeProductIds },
  } = useController<FlatAdSubmissionForm, 'productIds'>({
    name: 'productIds',
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [searchIds, setSearchIds] = useState<string[]>([]);

  const productRowsCacheRef = useRef<Record<string, Row<Product>>>({});
  const currentProductIdsRef = useRef<string[]>();
  const hasProcessedInitialLoad = useRef<boolean>(false);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const productsQuery = useProductTableQuery({
    searchIds,
    columnFilters,
    sorting,
  });

  const productData = useMemo(() => {
    if (productsQuery.error || !productsQuery.data || productsQuery.isLoading)
      return [];

    return productsQuery.data.pages.reduce(
      (acc, page) => [...acc, ...page.products],
      [] as FetchProductsResponse['products']
    );
  }, [productsQuery.data, productsQuery.error, productsQuery.isLoading]);

  const productTable = useProductTable({
    columnFilters,
    productCount: productsQuery.data?.pages[0].meta.total ?? 0,
    productData,
    setColumnFilters,
    setSorting,
    sorting,
  });

  const currentProductRowsById = productTable.getRowModel().rowsById;
  const currentProductSelectionState = productTable.getState().rowSelection;
  currentProductIdsRef.current = Object.keys(currentProductSelectionState);

  productRowsCacheRef.current = {
    ...productRowsCacheRef.current,
    ...currentProductRowsById,
  };

  const productRowsCacheById = productRowsCacheRef.current;

  const formProductSelectionState = productIdsToSelectionState(productIds);
  const formProductSelectionRows = productSelectionStateToRows(
    formProductSelectionState,
    productRowsCacheById
  );

  const selectionStateHasChanged = !isEqual(
    formProductSelectionState,
    currentProductSelectionState
  );

  const handleOnSave = () => {
    const currentProductSelectionRows = productSelectionStateToRows(
      currentProductSelectionState,
      productRowsCacheById
    );
    const currentProductIds =
      currentProductSelectionRows?.map((row) => row.id) || [];

    onChangeProductIds(currentProductIds);
    setModalOpen(false);
  };

  const handleOnRemoveClick = (
    row: Row<Product>,
    currentProductIds: string[]
  ) => {
    const updatedProductIds = currentProductIds.filter(
      (currentProductId) => currentProductId !== row.id
    );
    const updatedProductSelectionState =
      productIdsToSelectionState(updatedProductIds);

    productTable.setRowSelection(updatedProductSelectionState);
    onChangeProductIds(updatedProductIds);
  };

  useEffect(() => {
    if (
      hasProcessedInitialLoad.current ||
      Object.keys(currentProductRowsById).length === 0
    )
      return;

    const initialSelectionState = productIdsToSelectionState(productIds);
    productTable.setRowSelection(initialSelectionState);

    hasProcessedInitialLoad.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds, currentProductRowsById]);

  useEffect(() => {
    const currentProducts = productTable.getRowModel();
    const currentProductSelections = productTable.getState().rowSelection;

    const hasNoSearchIds = searchIds.length === 0;
    const hasPreviousProductsSelected =
      Object.keys(currentProductSelections).length > 0;
    const hasProductResults = currentProducts.rows.length > 0;
    if (hasNoSearchIds && (hasProductResults || hasPreviousProductsSelected)) {
      // Clear previous products selection and fetch all products
      productTable.setRowSelection({});
    }

    if (hasNoSearchIds) return;

    // Pre-select products when using By Product IDs search
    const initialSelectionState = productIdsToSelectionState(searchIds);
    productTable.setRowSelection(initialSelectionState);
  }, [searchIds, productTable]);

  const formProductSelectionRowsToDisplay = showAll
    ? formProductSelectionRows
    : formProductSelectionRows?.slice(0, SHOW_ALL_THRESHOLD);

  const showProductsButtonLabel = () => {
    if (showAll) return 'Show fewer';
    if (productIds.length > SHOW_ALL_THRESHOLD)
      return `Show all ${productIds.length} products`;
    return 'Show all products';
  };

  return (
    <>
      <Button
        variant="tertiary"
        label="Select products"
        onClick={() => {
          setModalOpen(true);
          productTable.resetColumnFilters();
        }}
      />
      {formProductSelectionRowsToDisplay &&
        formProductSelectionRowsToDisplay.length > 0 && (
          <div data-testid={'manually-selected-products-table'}>
            <Table style={{ overflow: 'scroll' }}>
              <colgroup>
                <col style={{ width: '50%', paddingRight: '24px' }} />
                <col
                  style={{
                    width: '160px',
                    whiteSpace: 'nowrap',
                    paddingRight: '24px',
                  }}
                />
                <col
                  style={{
                    width: '72px',
                    whiteSpace: 'nowrap',
                    paddingRight: '24px',
                  }}
                />
              </colgroup>
              <Table.Head fixed={false}>
                <Table.Row>
                  <TableHeaderCell>ITEM</TableHeaderCell>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>CATEGORY</TableHeaderCell>
                  <TableHeaderCell>LINEAGE</TableHeaderCell>
                  <TableHeaderCell />
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {formProductSelectionRowsToDisplay &&
                  formProductSelectionRowsToDisplay
                    .filter((row) => row)
                    .map((row) => (
                      <Table.Row
                        key={row.id}
                        data-testid={`selected-products-table-row-${row.id}`}
                      >
                        <Table.Cell>
                          <Flex alignItems="center" gap={16}>
                            <ProductImage product={row.original} />
                            <Typography variant="body-bold">
                              {row.original.name}
                            </Typography>
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>{row.getValue('id')}</Table.Cell>
                        <Table.Cell>{row.getValue('kind')}</Table.Cell>
                        <Table.Cell>{row.getValue('category')}</Table.Cell>
                        <Table.Cell>
                          <Button.Icon
                            ariaLabel="remove-product-selection"
                            variant="minimal"
                            icon={<TrashIcon />}
                            onClick={() => handleOnRemoveClick(row, productIds)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
            <Flex>
              <Button
                variant="tertiary"
                label={showProductsButtonLabel()}
                onClick={() => setShowAll(!showAll)}
                disabled={productIds.length <= SHOW_ALL_THRESHOLD}
                mt={24}
              ></Button>
            </Flex>
          </div>
        )}
      <ProductTableModal
        onDiscardChanges={(open) => {
          setModalOpen(open);
          if (selectionStateHasChanged) {
            productTable.setRowSelection(formProductSelectionState || {});
          }
        }}
        fetchMore={productsQuery.fetchNextPage}
        setSearchIds={setSearchIds}
        open={modalOpen}
        table={productTable}
        onSave={handleOnSave}
        hasChanges={selectionStateHasChanged}
      />
    </>
  );
};

import { CheckboxField, Flex, Typography } from '@jane/shared/reefer';

import type { ExtraColumnOptions } from './StoreSelectModal';

interface Props {
  extraColumn?: ExtraColumnOptions;
  onChangeSelectAll: (x: boolean) => void;
  selectAllChecked: boolean;
  selectAllIndeterminate: boolean;
}

export const SelectHeader = ({
  selectAllChecked,
  selectAllIndeterminate,
  onChangeSelectAll,
  extraColumn,
}: Props) => (
  <Flex justifyContent="space-between" alignItems="center" mt={24} mb={8}>
    <Flex alignItems="center">
      <CheckboxField
        label="Select all"
        labelHidden
        name="select_all"
        checked={selectAllChecked}
        indeterminate={selectAllIndeterminate}
        onChange={onChangeSelectAll}
      />
      <Typography ml={72} mt={24} mb={16} variant="caps" color="grays-mid">
        Name
      </Typography>
    </Flex>
    {extraColumn ? (
      <Typography mt={24} mb={16} variant="caps" color="grays-mid">
        {extraColumn.label}
      </Typography>
    ) : null}
  </Flex>
);

import clsx from 'clsx';
import type { ElementType } from 'react';

import type { TableHeadProps } from '../table.types';
import styles from './tableSection.module.css';

interface TableSectionProps extends TableHeadProps {
  element: ElementType;
}

/**
 * Internal component used to create Table.Head & Table.Foot
 */
export function TableSection({
  className,
  children,
  'data-testid': testId,
  element: Element,
  fixed = true,
  id,
  style,
}: TableSectionProps) {
  return (
    <Element
      className={clsx(className, styles.tableSection, {
        [styles.tableSection__fixed]: fixed,
      })}
      data-testid={testId}
      id={id}
      style={style}
    >
      {children}
    </Element>
  );
}

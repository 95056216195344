// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dcd1_QYOmkoj1uRphr58 {\n  overflow: auto;\n}\n\n.Dcd1_QYOmkoj1uRphr58 > li:last-of-type > button {\n  padding-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/typeAhead/typeAheadResults/typeAheadResults.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".typeAheadResults_list {\n  overflow: auto;\n}\n\n.typeAheadResults_list > li:last-of-type > button {\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeAheadResults_list": "Dcd1_QYOmkoj1uRphr58"
};
export default ___CSS_LOADER_EXPORT___;

import type * as ReactTable from '@tanstack/react-table';

import type { Product } from '@jane/ad-manager/data-access';
import { Flex, Link, SortUpIcon } from '@jane/shared/reefer';

export const SortableHeader = ({
  column,
  children,
}: ReactTable.HeaderContext<Product, string> & {
  children: React.ReactNode;
}) => (
  <Link variant="minimal" onClick={column.getToggleSortingHandler()}>
    <Flex>
      {children}
      {column.getIsSorted() && (
        <SortUpIcon rotate={column.getIsSorted() === 'asc' ? 'up' : 'down'} />
      )}
    </Flex>
  </Link>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BmY5yZS51sIFi97ghYWy {\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  width: auto;\n  justify-content: flex-start;\n}\n\n.dhEqLHqcTxkQUnGBtoaK {\n  width: 100%;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/buttonToggle/buttonToggle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,8BAA8B;AAChC","sourcesContent":[".buttonToggle {\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  width: auto;\n  justify-content: flex-start;\n}\n\n.buttonToggle__full {\n  width: 100%;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonToggle": "BmY5yZS51sIFi97ghYWy",
	"buttonToggle__full": "dhEqLHqcTxkQUnGBtoaK"
};
export default ___CSS_LOADER_EXPORT___;

import styled from '@emotion/styled';
import { useState } from 'react';

import type { ProductBrand } from '@jane/ad-manager/types';
import {
  ChevronDownIcon,
  Flex,
  Modal,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';

import { BrandSearch } from './BrandSearch';

const Container = styled.div<{ hasBoxShadow: boolean }>(({ hasBoxShadow }) => ({
  width: '100%',
  ...(hasBoxShadow && {
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  }),
}));

const SelectBrandButton = styled.div(() => ({
  cursor: 'pointer',
  width: 'fit-content',
}));

interface Props {
  brand: ProductBrand;
  hasBoxShadow: boolean;
  setBrand: (brand: ProductBrand) => void;
}
export const BrandSelectHeader = ({ brand, setBrand, hasBoxShadow }: Props) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const onRequestClose = () => {
    setSearchOpen(false);
  };

  const selectBrand = (brand: ProductBrand) => {
    setBrand(brand);
    onRequestClose();
  };

  return (
    <Container hasBoxShadow={hasBoxShadow}>
      <SelectBrandButton
        onClick={() => setSearchOpen(true)}
        role="button"
        aria-label="select new brand"
      >
        <Flex p={24} alignItems="center" gap={24}>
          <PartnerLogo
            name={brand.name}
            variant="brand"
            size="sm"
            image={brand.logoUrl}
          />
          <Flex alignItems="center">
            <Typography variant="header-bold" as="h1">
              {brand.name}
            </Typography>
            <ChevronDownIcon />
          </Flex>
        </Flex>
      </SelectBrandButton>
      <Modal appId="root" open={searchOpen} onRequestClose={onRequestClose}>
        <Modal.Header title="Select brand" />
        <Modal.Content>
          <BrandSearch selectedBrand={brand} selectBrand={selectBrand} />
        </Modal.Content>
      </Modal>
    </Container>
  );
};

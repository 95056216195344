// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yJKhkv7H6hUywGSNugjm {\n  display: flex;\n  align-items: center;\n  float: left;\n  text-align: left;\n  cursor: unset;\n  font-weight: normal;\n}\n\n.pwPXobgm0g1opWzJjWWV {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/tableHeaderCell/tableHeaderCell.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".tableHeaderCell_label {\n  display: flex;\n  align-items: center;\n  float: left;\n  text-align: left;\n  cursor: unset;\n  font-weight: normal;\n}\n\n.tableHeaderCell_label__sorted {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderCell_label": "yJKhkv7H6hUywGSNugjm",
	"tableHeaderCell_label__sorted": "pwPXobgm0g1opWzJjWWV"
};
export default ___CSS_LOADER_EXPORT___;

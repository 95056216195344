import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchInfiniteBrands } from '@jane/catalog-cms/data-access';
import type { FetchInfiniteBrandsParams } from '@jane/catalog-cms/data-access';

export const infiniteBrandsQueryKey = (
  filters: FetchInfiniteBrandsParams = {}
): [string, string, FetchInfiniteBrandsParams] => {
  return ['brands', 'infiniteBrands', filters];
};

export const useInfiniteBrands = (filters: FetchInfiniteBrandsParams = {}) => {
  const brandsQuery = useInfiniteQuery({
    queryKey: infiniteBrandsQueryKey(filters),
    queryFn: fetchInfiniteBrands,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.metadata.nextPage === null
        ? undefined
        : lastPage.metadata.nextPage;
    },
  });

  let brands = undefined;

  if (brandsQuery.data) {
    const { pages } = brandsQuery.data;
    brands = pages.flatMap((body) => body.brands);
  }

  const totalBrandCount = brandsQuery.data?.pages[0].metadata.totalResults;

  return { ...brandsQuery, data: brands, totalBrandCount };
};

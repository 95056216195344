import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';

import { useMediaFormUtil } from '@jane/ad-manager/hooks';
import { validationMessages, validators } from '@jane/ad-manager/util';
import { CheckIcon, Flex, Form, effects } from '@jane/shared/reefer';

import { MaybeHidden } from './MaybeHidden';

interface Props {
  colorHex: string;
  isSelected: boolean;
}

export const BrandColorSwatch = styled.div<{
  color: string;
  selectedColor?: boolean;
}>(({ theme, color, selectedColor }) => ({
  background: color,
  height: '48px',
  width: '48px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  border: `1px solid ${theme.colors.grays.light}`,
  cursor: selectedColor ? 'auto' : 'pointer',
  ...(selectedColor && {
    svg: { position: 'absolute', top: '12px', left: '12px' },
  }),
}));

const getIconColor = (color: string) => {
  if (color.length === 4) {
    // convert 3 digit hex code to 6
    color =
      '#' +
      color
        .slice(1)
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
  }
  const [r, g, b] = effects.hexToRgb(color);
  return r * 0.299 + g * 0.587 + b * 0.114 > 127.5
    ? 'grays-black'
    : 'grays-light';
};

export const ColorPicker = ({ colorHex, isSelected }: Props) => {
  const theme = useTheme();
  const [selectedColor, setSelectedColor] = useState(colorHex);

  const {
    brand: { gold, tangerine, ember, sunset, grape, purple },
    tertiary: { pacific, seafoam, palm, emerald },
    grays: { white, light, mid, black },
  } = theme.colors;

  const colorOptionsBrand = [
    gold.main,
    tangerine.main,
    ember.main,
    sunset.main,
    grape.main,
    purple.main,
    pacific.main,
    seafoam.main,
    palm.main,
    emerald.main,
  ];
  const colorOptionsGrays = [white, light, mid, black];

  const formatBrandColor = (color: string) => {
    return color.charAt(0) === '#' ? color : '#' + color;
  };

  /** Color is not a custom hex value */
  const isPredefinedColor = (color: string) =>
    colorOptionsBrand.includes(color.toUpperCase()) ||
    colorOptionsGrays.includes(color.toUpperCase());

  const [customColor, setCustomColor] = useState(
    !isPredefinedColor(colorHex) ? colorHex : '#FFFFFF'
  );

  const { setColorHex } = useMediaFormUtil();

  const updateBrandColor = (color: string) => {
    setSelectedColor(color);
    setColorHex(color);
  };

  const isSelectedColor = (color: string) =>
    color.toLowerCase() === selectedColor.toLowerCase();

  return (
    <MaybeHidden isHidden={!isSelected}>
      <Flex gap={64} height="168px">
        <Flex gap={24} width="120px" flexWrap="wrap" height="120px">
          {colorOptionsGrays.map((option) => (
            <BrandColorSwatch
              data-testid="preset-color"
              selectedColor={isSelectedColor(option)}
              key={option}
              color={option}
              onClick={() => updateBrandColor(option)}
            >
              {isSelectedColor(option) && (
                <CheckIcon color={getIconColor(option)} />
              )}
            </BrandColorSwatch>
          ))}
        </Flex>
        <Flex gap={24} width="336px" height="120px" flexWrap="wrap">
          {colorOptionsBrand.map((option) => (
            <BrandColorSwatch
              data-testid="preset-color"
              selectedColor={isSelectedColor(option)}
              key={option}
              onClick={() => updateBrandColor(option)}
              color={option}
            >
              {isSelectedColor(option) && (
                <CheckIcon color={getIconColor(option)} />
              )}
            </BrandColorSwatch>
          ))}
        </Flex>
        <Flex flexDirection="column" gap={16}>
          <BrandColorSwatch
            color={customColor}
            selectedColor={isSelectedColor(customColor)}
            onClick={() => updateBrandColor(customColor)}
          >
            {isSelectedColor(customColor) &&
              !isPredefinedColor(customColor) && (
                <CheckIcon color={getIconColor(customColor)} />
              )}
          </BrandColorSwatch>
          <Form.TextField
            defaultValue={customColor}
            name="customColor"
            label=""
            type="text"
            onChange={(value) => {
              setCustomColor(formatBrandColor(value));
              updateBrandColor(formatBrandColor(value));
            }}
            validate={(value: string) =>
              validators.isHexValue(formatBrandColor(value)) ||
              validationMessages.invalidColorHexCode
            }
            width="232px"
          />
        </Flex>
      </Flex>
    </MaybeHidden>
  );
};

import { z } from 'zod';

import { parseData } from '@jane/shared/util';

import { brandServerPaths } from './brandServerPaths';
import { brandsApi } from './brandsApi';

const AuthUserSchema = z.object({
  id: z.string(),
  email: z.string(),
  roles: z.array(z.string()),
});

const WhoamiResponseSchema = z.object({
  data: z.object({ user: AuthUserSchema }),
});

export type AuthUser = z.infer<typeof AuthUserSchema>;
export type WhoamiResponseBody = z.infer<typeof WhoamiResponseSchema>;

/**
 * Attempts a login with email and password. This fetch function
 * is not designed to be used with React Query
 */
export const login = (email: string, password: string) => {
  return brandsApi.post(brandServerPaths.login(), { email, password });
};

/**
 * Fetches the current user based on the session cookie.
 */
export const fetchUser = async () => {
  const { data } = await brandsApi.get(brandServerPaths.whoami());
  const parsedData = parseData(WhoamiResponseSchema, data);
  return parsedData.data.user;
};

/**
 * Logout the current user, will invalidate user's session cookie on success.
 */
export const logout = () => {
  return brandsApi.post(brandServerPaths.logout(), null);
};

const PasswordResetResponseSchema = z.object({
  data: z.string(),
});

/**
 * Requests a password reset for provided email
 */
export const resetPassword = async (email: string) => {
  const { data } = await brandsApi.post(brandServerPaths.passwordReset(), {
    email,
  });
  const parsedData = parseData(PasswordResetResponseSchema, data);

  return parsedData.data;
};

/**
 * Update users password
 */

export const updatePassword = async ({
  token,
  password,
}: {
  password: string;
  token: string;
}) => {
  return brandsApi.post(brandServerPaths.updatePassword(token), {
    password,
  });
};

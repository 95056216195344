import clsx from 'clsx';

import type { TableRowProps } from '../table.types';
import styles from './tableRow.module.css';

export function TableRow({
  children,
  className,
  'data-testid': testId,
  id,
  onClick,
  selected = false,
  style,
}: TableRowProps) {
  return (
    <tr
      className={clsx(className, styles.tableRow, {
        [styles.tableRow__selected]: selected,
        [styles.tableRow__clickable]: !!onClick,
      })}
      data-testid={testId}
      id={id}
      onClick={onClick}
      style={style}
    >
      {children}
    </tr>
  );
}

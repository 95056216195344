import { useContext } from 'react';

import { BrandThemeEditorContext } from '@jane/ad-manager/providers';
import { ButtonToggle } from '@jane/shared/reefer';

export const HeaderViewToggle = () => {
  const { forceMobile, setForceMobile } = useContext(BrandThemeEditorContext);

  return (
    <ButtonToggle
      value={forceMobile ? 'true' : 'false'}
      onChange={(v) => setForceMobile(v === 'true')}
      full={false}
    >
      <ButtonToggle.Button label="Mobile" value="true" />
      <ButtonToggle.Button label="Desktop" value="false" />
    </ButtonToggle>
  );
};

import { useInfiniteQuery } from '@tanstack/react-query';

import { brandServerApi } from '../brandServerApi';
import type { FetchJamStoresResponse } from './types';
import { FetchJamStoresResponseSchema } from './types';

export interface FetchStoresParams {
  enabledPlacements?: string[];
  ids?: string[];
  name?: string;
  page?: number;
  perPage?: number;
}

const FETCH_STORES_STALE_TIME_MS = 1000 * 60 * 5;

export const fetchStores = async (
  params: FetchStoresParams,
  signal?: AbortSignal
) => {
  const urlParams = new URLSearchParams();
  const { page, perPage, name, ids, enabledPlacements } = params;

  if (page) urlParams.set('page', page.toString());
  if (perPage) urlParams.set('per_page', perPage.toString());
  if (name) urlParams.set('name', name);
  if (ids) urlParams.set('ids', ids.join(','));
  if (enabledPlacements && enabledPlacements.length > 0) {
    enabledPlacements.forEach((placement) =>
      urlParams.append('enabled_placements[]', placement)
    );
  }

  const response = await brandServerApi.get<FetchJamStoresResponse>(
    `/jam/stores?${urlParams}`,
    { signal }
  );
  FetchJamStoresResponseSchema.parse(response);
  return response;
};

export const useInfiniteStores = (params: FetchStoresParams) =>
  useInfiniteQuery<FetchJamStoresResponse>({
    getNextPageParam: (firstPage, allPages) => {
      const hasNextPage =
        firstPage?.meta.page < firstPage?.meta.number_of_pages;
      return hasNextPage ? firstPage?.meta.page + 1 : undefined;
    },
    queryFn: async ({ pageParam = 1, signal }) => {
      const data = await fetchStores(
        {
          ...params,
          page: pageParam,
        },
        signal
      );

      return {
        ...data,
        pageParam,
      };
    },
    queryKey: ['stores', params],
    useErrorBoundary: true,
    staleTime: FETCH_STORES_STALE_TIME_MS,
  });

import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

import { spacing } from '@jane/shared/reefer-emotion';

import { SkeletonMenu } from './skeletonMenu';

const StyledPreviewWrapper = styled.div(({ theme }) => ({
  ...spacing({ mb: 40, mt: 40 }),
  display: 'flex',
  justifyContent: 'center',
  width: 'calc(100% - 400px)',
}));

const StyledViewModeOuterWrapper = styled.div<{ forceMobile: boolean }>(
  ({ theme, forceMobile }) => ({
    ...spacing({ p: forceMobile ? 16 : 0 }),
    backgroundColor: theme.colors.grays.white,
    borderRadius: theme.borderRadius.xl,
    boxShadow: `0 2px 16px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05)`,
    display: 'flex',
    width: forceMobile ? '375px' : '100%',
  })
);

const StyledViewModeInnerWrapper = styled.div<{ forceMobile: boolean }>(
  ({ theme, forceMobile }) => ({
    borderRadius: theme.borderRadius.xl,
    height: forceMobile ? '812px' : undefined,
    overflow: 'hidden',
    width: '100%',
    transform: 'translateZ(0)', // CU-85zryw22p: fix rounded top corners in safari
  })
);

export const Preview = ({
  children,
  forceMobile,
}: PropsWithChildren<{
  forceMobile: boolean;
}>) => {
  return (
    <StyledPreviewWrapper>
      <StyledViewModeOuterWrapper forceMobile={forceMobile}>
        <StyledViewModeInnerWrapper forceMobile={forceMobile}>
          <main
            id="content"
            role="main"
            style={{ height: '100%', width: '100%' }}
          >
            {children}
            <SkeletonMenu forceMobile={forceMobile} />
          </main>
        </StyledViewModeInnerWrapper>
      </StyledViewModeOuterWrapper>
    </StyledPreviewWrapper>
  );
};

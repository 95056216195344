import type * as ReactTable from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import type {
  Product,
  useFetchProductFilters,
} from '@jane/ad-manager/data-access';
import { CheckboxField, Flex, Typography } from '@jane/shared/reefer';

import { ProductImage } from '../ProductImage';
import { SortableHeader } from './SortableHeader';

export const useProductTableColumns = ({
  filtersQueryData,
  productCount,
}: {
  filtersQueryData: ReturnType<typeof useFetchProductFilters>['data'];
  productCount: number;
}) => {
  const columnHelper = createColumnHelper<Product>();

  return useMemo<ReactTable.ColumnDef<Product, string>[]>(() => {
    const selectColumn = columnHelper.display({
      id: 'select',
      header: ({ table }) => {
        return (
          <CheckboxField
            {...{
              label: 'select-all-box',
              labelHidden: true,
              name: 'select-all-box',
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomePageRowsSelected(),
              onClick: table.getToggleAllPageRowsSelectedHandler(),
            }}
          />
        );
      },
      cell: ({ row }) => {
        return (
          <CheckboxField
            {...{
              label: `select-row-${row.id}`,
              labelHidden: true,
              name: `select-row-${row.id}`,
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onClick: row.getToggleSelectedHandler(),
            }}
          />
        );
      },
      size: 72,
    });

    const idColumn = columnHelper.accessor(
      (row) => capitalize(row.id.toString()),
      {
        id: 'id',
        enableColumnFilter: false,
        header: (props) => (
          <SortableHeader {...props}>
            <Typography color="grays-mid">ID</Typography>
          </SortableHeader>
        ),
      }
    );

    const nameColumn = columnHelper.accessor('name', {
      header: (props) => (
        <SortableHeader {...props}>
          <Typography>{productCount} PRODUCTS</Typography>
        </SortableHeader>
      ),
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <Flex alignItems="center" gap={16}>
            <ProductImage product={row.original} />
            <Typography>{row.original.name}</Typography>
          </Flex>
        );
      },
      size: 244,
    });

    const kindColumn = columnHelper.accessor((row) => capitalize(row.kind), {
      id: 'kind',
      header: (props) => (
        <SortableHeader {...props}>
          <Typography color="grays-mid">CATEGORY</Typography>
        </SortableHeader>
      ),
      meta: {
        filterValues: filtersQueryData?.filters.kinds,
        filterLabel: 'Category',
      },
    });

    const categoryColumn = columnHelper.accessor(
      (row) => (row.category ? capitalize(row.category) : ''),
      {
        id: 'category',
        header: (props) => (
          <SortableHeader {...props}>
            <Typography color="grays-mid">LINEAGE</Typography>
          </SortableHeader>
        ),
        meta: {
          filterValues: filtersQueryData?.filters.categories,
          filterLabel: 'Lineage',
        },
      }
    );

    return [selectColumn, nameColumn, idColumn, kindColumn, categoryColumn];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersQueryData]);
};

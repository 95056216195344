import type { ZodError } from 'zod';

import type { ApiBrandThemeOutgoing } from './types';

export const makeSaveDraftErrorMessage = (
  error: ZodError<ApiBrandThemeOutgoing>
) => {
  const formatted = error.format();
  if (formatted.pbt_custom_video_attributes?.video_url?._errors.length) {
    return 'Please provide a URL for your video.';
  }
  return 'Encountered an unexpected error while saving.';
};

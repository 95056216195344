// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uTYsSqDF4LqZK6j_NT8o {\n  padding-left: var(--drawer-mobile-padding-horiz);\n  padding-right: var(--drawer-mobile-padding-horiz);\n  padding-top: var(--drawer-padding-vert);\n  padding-bottom: var(--drawer-padding-vert);\n  text-align: center;\n}\n\n@media (min-width: 768px) {\n  .uTYsSqDF4LqZK6j_NT8o {\n    padding-left: var(--drawer-desktop-padding-horiz);\n    padding-right: var(--drawer-desktop-padding-horiz);\n  }\n}\n\n.P0soWa2Uc0sQTXcUfmyt {\n  box-shadow: var(--shadows-divider);\n}\n\n.K1ProBQ6vMXsdX6shHvD {\n  margin-top: var(--drawer-padding-vert);\n  margin-bottom: var(--drawer-padding-vert);\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/drawer/drawerHeader/drawerHeader.module.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,iDAAiD;EACjD,uCAAuC;EACvC,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE;IACE,iDAAiD;IACjD,kDAAkD;EACpD;AACF;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;EACtC,yCAAyC;AAC3C","sourcesContent":[".drawerHeader {\n  padding-left: var(--drawer-mobile-padding-horiz);\n  padding-right: var(--drawer-mobile-padding-horiz);\n  padding-top: var(--drawer-padding-vert);\n  padding-bottom: var(--drawer-padding-vert);\n  text-align: center;\n}\n\n@media (min-width: 768px) {\n  .drawerHeader {\n    padding-left: var(--drawer-desktop-padding-horiz);\n    padding-right: var(--drawer-desktop-padding-horiz);\n  }\n}\n\n.drawerHeader_withDivider {\n  box-shadow: var(--shadows-divider);\n}\n\n.drawerHeader_title {\n  margin-top: var(--drawer-padding-vert);\n  margin-bottom: var(--drawer-padding-vert);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerHeader": "uTYsSqDF4LqZK6j_NT8o",
	"drawerHeader_withDivider": "P0soWa2Uc0sQTXcUfmyt",
	"drawerHeader_title": "K1ProBQ6vMXsdX6shHvD"
};
export default ___CSS_LOADER_EXPORT___;

import { useState } from 'react';
import { useController } from 'react-hook-form';

import { Button, Flex } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../../useAdBuilderForm';
import { CategorySelectorModal } from './CategorySelectorModal';
import { SelectedCategories } from './SelectedCategories';

export const CategorySelector = () => {
  const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);
  const {
    field: { onChange: onChangeProductCategories, value: productCategories },
  } = useController<FlatAdSubmissionForm, 'productCategories'>({
    name: 'productCategories',
  });
  return (
    <>
      <Flex flexDirection="column" gap={24}>
        <Button
          variant="tertiary"
          label="Select categories"
          onClick={() => setOpenCategoryModal(true)}
        />
        <SelectedCategories />
      </Flex>
      <CategorySelectorModal
        selectedCategories={productCategories}
        setSelectedCategories={onChangeProductCategories}
        open={openCategoryModal}
        setOpen={setOpenCategoryModal}
      />
    </>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { CategoryLabelParams } from '@jane/catalog-cms/data-access';
import { fetchCategoryLabels } from '@jane/catalog-cms/data-access';

const categoryLabelQueryKey = (
  brandUuids?: string[]
): [string, CategoryLabelParams] => {
  return ['categoryLabels', { brandUuids }];
};

/**
 * Fetches a list of available category labels (AKA brand category)
 */

export const useCategoryLabels = (brand_uuids?: string[]) => {
  const categoryLabelsQuery = useQuery({
    queryKey: categoryLabelQueryKey(brand_uuids),
    queryFn: fetchCategoryLabels,
  });

  if (categoryLabelsQuery.isError) {
    throw categoryLabelsQuery.error;
  }
  return categoryLabelsQuery;
};

import type * as ReactTable from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type {
  ApiFlightDetails,
  ApiFlightSummary,
} from '@jane/ad-manager/data-access';
import { useUpdateFlight } from '@jane/ad-manager/data-access';
import { useColumnVisibilityInStorage } from '@jane/ad-manager/hooks';
import { endOfDayInPacificTimeWithUTCTimezone } from '@jane/ad-manager/util';
import { useToast } from '@jane/shared/reefer';

import { getColumns } from './GetColumns';

export type RowActions = {
  onDuplicateRow: (row: ReactTable.Row<ApiFlightSummary>) => void;
  onSetActive: (flightId: number, isActive: boolean) => void;
};
type Props = Omit<
  ReactTable.TableOptions<ApiFlightSummary>,
  'getCoreRowModel' | 'columns'
> &
  Pick<RowActions, 'onDuplicateRow'> & {
    onFlightUpdated: () => Promise<unknown>;
  };

export const useFlightsTable = ({
  onDuplicateRow,
  onFlightUpdated,
  ...props
}: Props) => {
  const [loadingFlightIds, setLoadingFlightIds] = useState<number[]>([]);
  const toast = useToast();
  const { mutate: updateFlight } = useUpdateFlight({
    onSuccess: async (updatedFlight: ApiFlightDetails) => {
      await onFlightUpdated();

      toast.add({ label: 'Flight Updated' });

      // remove flight row from "loading" list
      setLoadingFlightIds(
        loadingFlightIds.filter(
          (loadingFlightId) => loadingFlightId !== updatedFlight.id
        )
      );
    },
    onError: (err) =>
      toast.add({
        variant: 'error',
        label: `Flight update error: ${err.message}`,
      }),
  });

  const onSetActive = async (flightIdForUpdate: number, isActive: boolean) => {
    setLoadingFlightIds([...loadingFlightIds, flightIdForUpdate]);
    const endDate = endOfDayInPacificTimeWithUTCTimezone();
    updateFlight({
      id: flightIdForUpdate,
      is_active: isActive,
      end_date: endDate,
      is_read_only: !isActive,
    });
  };

  const navigate = useNavigate();
  const columns = getColumns({
    navigate,
    onDuplicateRow,
    onSetActive,
    loadingFlightIds,
  });

  const [sorting, setSorting] = useState<ReactTable.SortingState>([
    { desc: true, id: 'id' },
  ]);

  const [columnVisibility, setColumnVisibility] =
    useColumnVisibilityInStorage();

  return useReactTable({
    columns,
    onColumnVisibilityChange: setColumnVisibility,
    state: { columnVisibility, sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...props,
  });
};

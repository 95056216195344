import { useQuery } from '@tanstack/react-query';

import { fetchBrands } from '@jane/catalog-cms/data-access';
import type { FetchBrandsParams } from '@jane/catalog-cms/data-access';

/**
 * Fetches a list of available product brands
 */

export const brandsQueryKey = (
  brandIds: FetchBrandsParams
): [string, FetchBrandsParams] => {
  return ['brands', brandIds];
};

export const useBrands = (params: FetchBrandsParams = {}) => {
  const brandsQuery = useQuery({
    queryKey: brandsQueryKey(params),
    queryFn: fetchBrands,
  });

  if (brandsQuery.isError) {
    throw brandsQuery.error;
  }

  return brandsQuery;
};

import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

// TODO: Deprecate images attribute clickup ticket #862k4c4ew
const UpdateLocalizationParamsSchema = z.object({
  categoryLabel: z.string().nullable(),
  description: z.string().nullable(),
  images: z.array(z.string()).nullable(),
  lineage: z.string().nullable(),
  name: z.string().nullable(),
  productUUID: z.string().uuid(),
  localizationId: z.string().uuid(),
});

export type UpdateLocalizationParams = z.infer<
  typeof UpdateLocalizationParamsSchema
>;

const UpdateLocalizationResponseSchema = z.object({
  time: z.string().datetime(),
});
export type UpdateLocalizationResponseBody = z.infer<
  typeof UpdateLocalizationResponseSchema
>;

export const updateLocalization = async ({
  productUUID,
  localizationId,
  ...updateParams
}: UpdateLocalizationParams) => {
  const { data } = await brandsApi.put(
    brandServerPaths.localization(productUUID, localizationId),
    updateParams
  );
  const parsedData = parseData(UpdateLocalizationResponseSchema, data);
  return parsedData;
};

import type { Product } from '@jane/ad-manager/data-access';
import { Image } from '@jane/shared/reefer';

export const ProductImage = ({ product }: { product: Product }) => {
  const imageSrc = product.photos[0]?.urls.small;
  return (
    <Image
      height="48px"
      width="48px"
      border
      borderRadius="12px"
      src={imageSrc}
      altText={product.name}
    />
  );
};

import { FlightDetailSection } from './FlightDetailSection';

type Props =
  | {
      mode: 'auto';
    }
  | {
      categories: string[];
      mode: 'category';
    }
  | {
      mode: 'manual';
      productNames: string[];
    };
export const ProductDetails = (props: Props) => {
  if (props.mode === 'auto') {
    return (
      <FlightDetailSection
        headerLabel="Products"
        rows={[[{ name: 'Select Products', value: 'Automatically' }]]}
      />
    );
  }
  if (props.mode === 'category') {
    return (
      <FlightDetailSection
        headerLabel="Products"
        rows={[
          [{ name: 'Select Products', value: 'Automatically by category' }],
          [
            {
              name: 'Categories',
              value: props.categories.join(', '),
            },
          ],
        ]}
      />
    );
  }

  return (
    <FlightDetailSection
      headerLabel="Products"
      rows={[
        [{ name: 'Select Products', value: 'Manually' }],
        [
          {
            name: 'Products',
            value: props.productNames.join(', '),
          },
        ],
      ]}
    />
  );
};

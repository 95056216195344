import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Flex,
  Modal,
  SearchField,
  SearchIcon,
  Typography,
} from '@jane/shared/reefer';

import { CategoryList } from './CategoryList';
import { updateSelectedCategories } from './util/updateSelectedCategories';

export type CategoryOption = {
  label: string;
  subItems?: CategoryOption[];
  value: string;
};

export type CategorySelectInputModalProps = {
  categoryOptions: CategoryOption[];
  onDirty?: (dirtyState: boolean) => void;
  onSave: (selectedCategories: CategoryOption[]) => void;
  selectedCategories: CategoryOption[];
};

export const CategorySelectInputModal = ({
  categoryOptions,
  onSave,
  onDirty,
  selectedCategories,
}: CategorySelectInputModalProps) => {
  const [categoryQuery, setCategoryQuery] = useState('');
  const [modalCategories, setModalCategories] =
    useState<CategoryOption[]>(selectedCategories);

  const filteredCategories = useMemo(() => {
    return categoryOptions.filter((category) => {
      if (category.label.toLowerCase().includes(categoryQuery.toLowerCase())) {
        return true;
      } else {
        return category.subItems?.some((subCategory) =>
          subCategory.label.toLowerCase().includes(categoryQuery.toLowerCase())
        );
      }
    });
  }, [categoryOptions, categoryQuery]);

  // Update internal selected categories
  useEffect(() => {
    setModalCategories(selectedCategories);
  }, [selectedCategories]);

  const handleCategoryChange = (value: string) => {
    onDirty && onDirty(true);
    setModalCategories((previouslySelected) => {
      return updateSelectedCategories(
        value,
        previouslySelected,
        categoryOptions
      );
    });
  };

  const selectAllCategories = () => {
    onDirty && onDirty(true);
    const allCategories: CategoryOption[] = [];

    categoryOptions.forEach((category) => {
      category?.subItems && category.subItems.length > 0
        ? allCategories.push(...category.subItems)
        : allCategories.push(category as CategoryOption);
    });
    setModalCategories(allCategories);
  };

  const subCategoryCount = modalCategories.filter(
    (category) => !category.subItems
  ).length;

  const categoryCount = modalCategories.length - subCategoryCount;

  const selectedCategoriesText = () => {
    const categoryText = `${categoryCount} ${
      categoryCount === 1 ? 'category' : 'categories'
    }`;
    const noSelectableSubcategories = filteredCategories.every(
      (category) => category?.subItems && category.subItems.length < 1
    );

    if (noSelectableSubcategories) return categoryText;

    return `${categoryText}, ${subCategoryCount} ${
      subCategoryCount === 1 ? 'subcategory' : 'subcategories'
    } selected`;
  };

  return (
    <>
      <Modal.Header
        title="Select categories"
        actions={
          <Button
            variant="primary"
            label="Save"
            onClick={() => onSave(modalCategories)}
          />
        }
      />
      <Modal.Content>
        <SearchField
          placeholder="Search"
          label="categorySearch"
          name="categorySearch"
          startIcon={<SearchIcon color="grays-black" />}
          mb={24}
          value={categoryQuery}
          onChange={setCategoryQuery}
        />
        {filteredCategories.map((category, index) => (
          <CategoryList
            key={category.value}
            isLastCategory={index === filteredCategories.length - 1}
            category={category}
            onChange={handleCategoryChange}
            query={categoryQuery}
            selectedCategories={modalCategories}
          />
        ))}
      </Modal.Content>
      <Modal.Footer>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography color="grays-mid">{selectedCategoriesText()}</Typography>
          <Flex gap={16}>
            <Button
              variant="tertiary"
              label="Select all"
              onClick={selectAllCategories}
            />
            <Button
              variant="tertiary"
              label="Select none"
              onClick={() => setModalCategories([])}
            />
          </Flex>
        </Flex>
      </Modal.Footer>
    </>
  );
};

import { Tab } from './tab/tab';
import type { TabProps } from './tab/tab';
import { Tabs as BaseTabs } from './tabs';
import type { TabsProps } from './tabs';

export type { TabProps, TabsProps };

export const Tabs = Object.assign(BaseTabs, {
  Tab,
});

import type { Weekday, WeekdayMin } from '@jane/ad-manager/types';

export const ORDERED_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export const ALL_DAY_SCHEDULE = { endTime: '11:59 PM', startTime: '12:00 AM' };

export const WEEKDAY_TO_WEEKDAY_MIN: Record<Weekday, WeekdayMin> = {
  Friday: 'FR',
  Monday: 'MO',
  Saturday: 'SA',
  Sunday: 'SU',
  Thursday: 'TH',
  Tuesday: 'TU',
  Wednesday: 'WE',
} as const;

import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  Card,
  CheckIcon,
  Flex,
  JaneLogo,
  Link,
  Typography,
  useDesktopMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

interface PublicLayoutProps {
  children: ReactNode;
}

const LoginBackground = styled.main<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => {
    return {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: isDesktop ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
      background: theme.colors.brand.purple.main,
    };
  }
);

const FlexFixedChildren = styled(Flex)({ '& > *': { flex: '0 0 auto' } });

export const PublicLayout = ({ children }: PublicLayoutProps) => {
  const location = useLocation();

  const isDesktop = useDesktopMediaQuery({});
  const isTablet = useTabletMediaQuery({});
  const cardWidth = isDesktop ? '852px' : '100%';
  const cardPadding = isTablet ? 64 : 32;

  return (
    <LoginBackground isDesktop={isDesktop}>
      <FlexFixedChildren
        flexDirection="column"
        gap={40}
        alignItems="center"
        justifyContent="center"
        py={32}
      >
        <Card width={cardWidth}>
          <Card.Content background="grays-white">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={40}
              px={cardPadding}
              pt={isTablet ? 32 : 16} //Different padding to accommodate Jane Logo padding
              pb={cardPadding}
            >
              <Flex flexDirection="column" alignItems="center">
                <JaneLogo size={isTablet ? 'lg' : 'md'} />
                <Typography variant="caps-bold" mt={isTablet ? -16 : -8}>
                  BRAND DASHBOARD
                </Typography>
              </Flex>
              {location.state?.passwordReset && <ResetPasswordBanner />}
              {children}
            </Flex>
          </Card.Content>
        </Card>
        <Flex flexDirection="column" gap={12} alignItems="center">
          <Typography variant="body" color="grays-white">
            Not a partner with Jane? Get in touch to get started.
          </Typography>
          <Link href="mailto:info@iheartjane.com" color="grays-white">
            Contact us
          </Link>
        </Flex>
      </FlexFixedChildren>
    </LoginBackground>
  );
};

const ResetPasswordBanner = () => {
  return (
    <Banner
      variant="success"
      label="If an account with that email address exists, you will receive an email at that address with a reset link."
      full
      icon={<CheckIcon color="success-dark" />}
    />
  );
};

export const sanitizeDescription = (desc: string) => {
  return Array.from(
    desc
      // remove leading newlines only
      .replace(/(^\n+)/, '')
      // remove trailing whitespace
      .trimEnd()
  )
    .filter(
      (el, i, arr) =>
        // allow a maximum of 2 sequential newlines
        !(arr[i] === '\n' && arr[i + 1] === '\n' && arr[i + 2] === '\n')
    )
    .join('');
};

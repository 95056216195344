import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { Box, Modal, SearchField, Typography } from '@jane/shared/reefer';

import { BrandCheckbox } from './brandCheckbox';

export interface Brand {
  id: string;
  logoUrl?: string | null;
  name: string;
}

export interface BrandSelectModalProps {
  /** Array of brands rendered as checkbox items */
  brands: Brand[];
  /** JSX passed to the modal header */
  headerActions?: React.ReactNode;
  /** Boolean check before fetching the next page */
  isFetchingNextPage: boolean;
  /** Action fired on brand checkbox click */
  onChange: (selectedBrands: Brand[]) => void;
  /** Action fired on fetching the next page of brand data */
  onNextPage: () => void;
  /** Action when a user enters a query into the searchfield */
  onQuery: (query: string) => void;
  /** Array of brands displayed as selected at the top of the list */
  selectedBrands: Brand[];
  /** Optional title, defaults to "Select brands" */
  title?: string;
}

export const BrandSelectModal = ({
  brands,
  headerActions,
  isFetchingNextPage,
  onChange,
  onNextPage,
  onQuery,
  selectedBrands,
  title = 'Select brands',
}: BrandSelectModalProps) => {
  const wrapperRef = useRef(null);
  const { ref } = useInView({
    root: wrapperRef.current,
    threshold: 0,
    onChange: (inView) => {
      if (inView && !isFetchingNextPage) {
        onNextPage();
      }
    },
  });

  const handleBrandClick = (brand: Brand) => {
    // We are de-selecting a brand, so we filter it out
    const newSelectedBrands = selectedBrands.filter(
      (selectedBrand) => selectedBrand.id !== brand.id
    );

    // We are selecting a brand since our newly selected brand was not found in the filter operation and our lengths remain the same
    if (selectedBrands.length === newSelectedBrands.length) {
      newSelectedBrands.push(brand);
    }

    onChange(newSelectedBrands);
  };

  const isSelected = (brand: Brand) =>
    selectedBrands.some((selectedBrand) => selectedBrand.id === brand.id);

  return (
    <>
      <Modal.Header title={title} actions={headerActions} />
      <Modal.Content ref={wrapperRef}>
        <SearchField
          borderRadius="sm"
          label="Search Brands"
          name="brand-search-field"
          onChange={onQuery}
          placeholder="Search for a brand"
          mb={24}
        />
        {selectedBrands.length > 0 && (
          <Box>
            <Typography variant="body-bold" mb={16}>
              Selected
            </Typography>
            <Box
              as="ul"
              background="transparent-black-five"
              borderRadius="lg"
              overflow="hidden"
              title="Selected brands"
            >
              {selectedBrands.map((brand, i) => (
                <BrandCheckbox
                  key={brand.id}
                  brand={brand}
                  onClick={() => handleBrandClick(brand)}
                  selected
                  inSelectedGroup
                  data-testid={`select-brand-modal-selected-${brand.id}`}
                  divider={i > 0}
                />
              ))}
            </Box>
            {/* expand divider to cover modal */}
            <Modal.ContentDivider />
          </Box>
        )}
        <Box as="ul" title="All brands">
          {brands?.map((brand, i) => (
            <BrandCheckbox
              key={brand.id}
              brand={brand}
              onClick={() => handleBrandClick(brand)}
              selected={isSelected(brand)}
              data-testid={`select-brand-modal-${brand.id}`}
              divider={i > 0}
              ref={i === brands.length - 1 ? ref : undefined}
            />
          ))}
        </Box>
      </Modal.Content>
    </>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  AssetsModal,
  DescriptionModal,
  HeaderPublishControls,
  HeaderViewToggle,
  LogoModal,
} from '@jane/ad-manager/components';
import { useGetLatest } from '@jane/ad-manager/data-access';
import { BrandThemeEditorProvider } from '@jane/ad-manager/providers';
import type {
  BrandPageState,
  EditorAlert,
  ProductBrand,
} from '@jane/ad-manager/types';
import { makeBrandThemeStub } from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Banner,
  ErrorIcon,
  Loading,
  Modal,
  useToast,
} from '@jane/shared/reefer';

import { BrandThemeEditorMenu } from './BrandThemeEditorMenu';

export const BrandThemeEditor = () => {
  const { state }: Partial<BrandPageState> = useLocation();
  const { id } = useParams();
  const brandId = state?.brand.id || Number(id) || undefined;
  const brandName = state?.brand.name;
  const normalizedBrandName = state?.brand.normalizedName;
  const latest = useGetLatest(brandId);
  const toast = useToast();
  const navigate = useNavigate();
  const [editingBrandDescription, setEditingBrandDescription] =
    useState<boolean>(false);
  const [editingBrandThemeAssets, setEditingBrandThemeAssets] =
    useState<boolean>(false);
  const [editingBrandLogo, setEditingBrandLogo] = useState(false);
  const [forceMobile, setForceMobile] = useState<boolean>(true);
  const [alert, setAlert] = useState<EditorAlert | null>(null);

  useEffect(() => {
    if (latest.isError) {
      toast.add({
        label: 'An error occurred while retrieving brand theme information',
        variant: 'error',
      });
      navigate(brandsPaths.brandThemes());
    }
  }, [latest.isError, navigate, toast]);

  useEffect(() => {
    if (!brandId) {
      navigate(brandsPaths.brandThemes());
    }
  }, [brandId, brandName, navigate]);

  const isDataNull = latest.data == null;
  const isMissingMinInfo = useMemo(
    () => latest.isSuccess && isDataNull && brandName == null,
    [latest.isSuccess, isDataNull, brandName]
  );
  useEffect(() => {
    if (isMissingMinInfo) {
      toast.add({
        label: 'A brand theme does not yet exist for this brand.',
        variant: 'error',
      });
      navigate(brandsPaths.brandThemes());
    }
  }, [isMissingMinInfo, navigate, toast]);

  if (latest.isLoading || latest.isError || isMissingMinInfo) {
    return <Loading color="purple" />;
  }

  const brandTheme =
    latest.data?.productBrandTheme ||
    makeBrandThemeStub({
      id: brandId as number,
      name: brandName as string,
      normalizedName: normalizedBrandName as string,
    });

  const onDismiss = () => {
    const brand: ProductBrand = {
      id: brandTheme.productBrandId,
      name: brandTheme.productBrandName,
      logoUrl: brandTheme.logoUrl,
      normalizedName: brandTheme.productBrandNormalizedName ?? null,
    };
    const state: BrandPageState = { state: { brand } };
    navigate(brandsPaths.brandThemes(), state);
  };
  const mediaTags = latest.data?.mediaTags || [];

  return (
    <BrandThemeEditorProvider
      forceMobile={forceMobile}
      setForceMobile={setForceMobile}
    >
      <Modal
        appId="root"
        onRequestClose={onDismiss}
        open={true}
        variant="full-screen"
      >
        <Modal.Header
          title="Edit brand page"
          centerContent={<HeaderViewToggle />}
          actions={
            <HeaderPublishControls
              brandTheme={brandTheme}
              setAlert={setAlert}
            />
          }
        />
        <Modal.Content>
          {alert && (
            <Banner
              label={alert.label}
              variant={alert.variant}
              full
              icon={<ErrorIcon />}
              onDismiss={() => setAlert(null)}
            />
          )}
          <BrandThemeEditorMenu
            brandTheme={brandTheme}
            mediaTags={mediaTags}
            forceMobile={forceMobile}
            setEditingBrandDescription={setEditingBrandDescription}
            setEditingBrandLogo={setEditingBrandLogo}
            setEditingBrandThemeAssets={setEditingBrandThemeAssets}
          />
        </Modal.Content>
      </Modal>
      <AssetsModal
        open={editingBrandThemeAssets}
        setOpen={setEditingBrandThemeAssets}
        brandTheme={brandTheme}
        mediaTags={mediaTags}
      />
      <DescriptionModal
        open={editingBrandDescription}
        setOpen={setEditingBrandDescription}
        brandTheme={brandTheme}
      />
      <LogoModal
        open={editingBrandLogo}
        onClose={() => setEditingBrandLogo(false)}
        brandTheme={brandTheme}
      />
    </BrandThemeEditorProvider>
  );
};

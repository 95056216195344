export type MediaType = 'image' | 'video';

interface MediaCompliance {
  hide_in_state: string;
}

export interface BrandMediaTag {
  id: number;
  tag: string;
}

export interface BrandMediaTagWithMediaCompliances extends BrandMediaTag {
  media_compliances: MediaCompliance[];
}
export interface MediaTagOption extends BrandMediaTag {
  selected: boolean;
}

interface BaseMedia {
  _destroy?: boolean;
  _mediaType: MediaType;
  brandMediaTags: BrandMediaTag[];
  id: number;
  position: number;
}

export interface CustomImage extends BaseMedia {
  _mediaType: 'image';
  ctaText?: string;
  ctaUrl?: string;
  description?: string;
  imageUrl: string;
}

export interface CustomVideo extends BaseMedia {
  _mediaType: 'video';
  overlayImageUrl: string;
  overlayText?: string;
  videoUrl: string;
}

export type CustomMedia = CustomImage | CustomVideo;

export const isAVideo = (asset: CustomMedia): asset is CustomVideo =>
  asset._mediaType === 'video';

export const isAnImage = (asset: CustomMedia): asset is CustomImage =>
  asset._mediaType === 'image';

export interface BrandTheme {
  colorHex: string;
  customImageUrl?: string;
  customImages: Array<CustomImage>;
  customVideo?: CustomVideo;
  description?: string;
  draft: boolean;
  logoUrl?: string;
  productBrandId: number;
  productBrandName: string;
  productBrandNormalizedName?: string;
  rank?: number;
  updatedAt?: Date;
}

import { useProductFilters } from '@jane/catalog-cms/hooks';
import { Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

/**
 * Header cell for sortable columns in products list.
 */

export const SortableHeaderCell = ({
  text,
  sortField,
  width,
}: {
  sortField: string;
  text: string;
  width?: string;
}) => {
  const { sortBy, filters } = useProductFilters();

  // brand server products api does not use a sort direction, instead it uses a
  // reverse option set to true or false. We need to translate those values to
  // 'asc' and 'desc' which are accepted by reefer table.
  const direction =
    filters.reverse === 'true' ? ('desc' as const) : ('asc' as const);

  const sortOpts = {
    active: filters.sortField === sortField,
    direction,
    key: sortField,
    onClick: (key: string) => sortBy(key),
  };

  return (
    <Table.HeaderCell width={width} sort={sortOpts}>
      <Typography variant="caps" as="span">
        {text}
      </Typography>
    </Table.HeaderCell>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qmDpvSSQv_8zLl9q48dG {\n  align-items: center;\n  background-color: var(--tag-background-color);\n  border-radius: var(--border-radius-lg);\n  color: var(--tag-color);\n  display: flex;\n  height: fit-content;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n  padding-top: var(--padding-top);\n  width: fit-content;\n}\n\n.MO0tyHdYYwlsWffDKv2k {\n  border-radius: var(--border-radius-sm);\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/tag/tag.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,6CAA6C;EAC7C,sCAAsC;EACtC,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,qCAAqC;EACrC,iCAAiC;EACjC,mCAAmC;EACnC,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".tag {\n  align-items: center;\n  background-color: var(--tag-background-color);\n  border-radius: var(--border-radius-lg);\n  color: var(--tag-color);\n  display: flex;\n  height: fit-content;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  padding-bottom: var(--padding-bottom);\n  padding-left: var(--padding-left);\n  padding-right: var(--padding-right);\n  padding-top: var(--padding-top);\n  width: fit-content;\n}\n\n.tag__small {\n  border-radius: var(--border-radius-sm);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "qmDpvSSQv_8zLl9q48dG",
	"tag__small": "MO0tyHdYYwlsWffDKv2k"
};
export default ___CSS_LOADER_EXPORT___;

import { useQuery } from '@tanstack/react-query';

import type { FetchProductsSearchParams } from '@jane/catalog-cms/data-access';
import { fetchProductsSearch } from '@jane/catalog-cms/data-access';

const duplicateProductSearchQueryKey = (
  term?: string
): [string, string, FetchProductsSearchParams] => {
  return ['products', 'search', { term: term }];
};

export const useDuplicateProductSearch = (term?: string) => {
  const query = useQuery({
    queryKey: duplicateProductSearchQueryKey(term),
    queryFn: fetchProductsSearch,
  });

  if (query.isError) {
    throw query.error;
  }

  return query;
};

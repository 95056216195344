// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aF3rfsSnJCE6XQFEw1kr {\n  vertical-align: middle;\n}\n\n.aF3rfsSnJCE6XQFEw1kr td:first-of-type,\n.aF3rfsSnJCE6XQFEw1kr th:first-of-type {\n  padding-left: 24px;\n}\n\n.aF3rfsSnJCE6XQFEw1kr td:last-of-type,\n.aF3rfsSnJCE6XQFEw1kr th:last-of-type {\n  padding-right: 24px;\n}\n\n.s0ZQv5nxIUX1Uj5RETuv td {\n  background-color: var(--colors-grays-ultralight);\n  outline: none;\n}\n\n.ySusu655ng6_RajbzuFn {\n  cursor: pointer;\n}\n.ySusu655ng6_RajbzuFn:hover {\n  background-color: var(--colors-grays-light);\n}\n.ySusu655ng6_RajbzuFn:hover td {\n  background-color: inherit;\n  box-shadow: none !important;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/tableRow/tableRow.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,gDAAgD;EAChD,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;AACA;EACE,2CAA2C;AAC7C;AACA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".tableRow {\n  vertical-align: middle;\n}\n\n.tableRow td:first-of-type,\n.tableRow th:first-of-type {\n  padding-left: 24px;\n}\n\n.tableRow td:last-of-type,\n.tableRow th:last-of-type {\n  padding-right: 24px;\n}\n\n.tableRow__selected td {\n  background-color: var(--colors-grays-ultralight);\n  outline: none;\n}\n\n.tableRow__clickable {\n  cursor: pointer;\n}\n.tableRow__clickable:hover {\n  background-color: var(--colors-grays-light);\n}\n.tableRow__clickable:hover td {\n  background-color: inherit;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "aF3rfsSnJCE6XQFEw1kr",
	"tableRow__selected": "s0ZQv5nxIUX1Uj5RETuv",
	"tableRow__clickable": "ySusu655ng6_RajbzuFn"
};
export default ___CSS_LOADER_EXPORT___;

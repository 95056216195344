import { Modal, Typography } from '@jane/shared/reefer';

import { AdBuilderSectionContainer } from '../AdBuilderSectionContainer';
import { FlightDateRange } from './FlightDateRange';
import { FrequencyCapping } from './FrequencyCapping';
import { SpendAllocation } from './SpendAllocation';

export const AdSchedulingSection = () => {
  return (
    <AdBuilderSectionContainer data-testid="frequency-capping-scheduling-section">
      <Typography variant="header-bold" mb={24} branded>
        Frequency capping and schedule
      </Typography>
      <FrequencyCapping />
      <Modal.ContentDivider color="grays-light" />
      <FlightDateRange />
      <Modal.ContentDivider color="grays-light" />
      <SpendAllocation />
    </AdBuilderSectionContainer>
  );
};

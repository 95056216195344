import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { shortDateFormat } from '@jane/catalog-cms/util';
import { Typography } from '@jane/shared/reefer';

export type ProductHistoryProps = Pick<
  EditFormProduct,
  'createdTime' | 'updatedTime'
>;

export const ProductHistory = ({
  createdTime,
  updatedTime,
}: ProductHistoryProps) => {
  return (
    <>
      <Typography variant="body-bold" as="h4">
        History
      </Typography>
      <Typography>
        {`Created on ${shortDateFormat(new Date(createdTime))}`}
      </Typography>
      <Typography color="grays-mid">
        {`Edited on ${shortDateFormat(new Date(updatedTime))}`}
      </Typography>
    </>
  );
};

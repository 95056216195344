// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hF4wvoYlFIK3wzYMcKDl {\n  height: auto;\n  margin-top: 0;\n  overflow: hidden;\n  visibility: initial;\n  width: 100%;\n}\n\n.JNKrVeSQcq8Y5znDfbgw {\n  height: 0;\n  visibility: hidden;\n}\n\n.fKlqS_NXIKUniHMC38D7:not(.JNKrVeSQcq8Y5znDfbgw) {\n  margin-top: -5px; /* stylelint-disable-line */\n}\n\n@media (min-width: 1024px) {\n  .fKlqS_NXIKUniHMC38D7:not(.JNKrVeSQcq8Y5znDfbgw) {\n    margin-top: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/internal/loadingLabel/loadingLabel.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,gBAAgB,EAAE,2BAA2B;AAC/C;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".loadingLabel {\n  height: auto;\n  margin-top: 0;\n  overflow: hidden;\n  visibility: initial;\n  width: 100%;\n}\n\n.loadingLabel__loading {\n  height: 0;\n  visibility: hidden;\n}\n\n.loadingLabel_sublabel:not(.loadingLabel__loading) {\n  margin-top: -5px; /* stylelint-disable-line */\n}\n\n@media (min-width: 1024px) {\n  .loadingLabel_sublabel:not(.loadingLabel__loading) {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingLabel": "hF4wvoYlFIK3wzYMcKDl",
	"loadingLabel__loading": "JNKrVeSQcq8Y5znDfbgw",
	"loadingLabel_sublabel": "fKlqS_NXIKUniHMC38D7"
};
export default ___CSS_LOADER_EXPORT___;

import type { ReactNode } from 'react';

import {
  Button,
  ChevronDownIcon,
  Flex,
  InfoIcon,
  List,
  Popover,
  Typography,
} from '@jane/shared/reefer';

export interface NavigationDropdownProps {
  children: ReactNode;
  label: string;
}

export const NavigationDropdown = ({
  label,
  children,
}: NavigationDropdownProps) => {
  return (
    <Flex gap={16} alignItems="center">
      <Popover
        label="change state"
        target={
          <Button
            endIcon={<ChevronDownIcon size="md" />}
            variant="tertiary"
            label={
              <Typography variant="body" as="span">
                {label}
              </Typography>
            }
          />
        }
      >
        <Popover.Content>
          <nav>
            <List label="state view navigation menu">{children}</List>
          </nav>
        </Popover.Content>
      </Popover>
      <NavigationTooltip />
    </Flex>
  );
};

const NavigationTooltip = () => {
  return (
    <Popover
      label="state view navigation instructions"
      target={<InfoIcon />}
      variant="info"
      openOn="hover"
    >
      <Popover.Content>
        Choose which state view to edit or add a new state view.
      </Popover.Content>
    </Popover>
  );
};

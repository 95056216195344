import {
  MIN_CPC_PLACEMENT_TARGETED_BID,
  MIN_CPC_SKU_TARGETED_BID,
  MIN_CPC_STORE_TARGETED_BID,
  MIN_CPM_PLACEMENT_TARGETED_BID,
  MIN_CPM_SKU_TARGETED_BID,
  MIN_CPM_STORE_TARGETED_BID,
} from './validators';

type CampaignTarget = 'sku' | 'store' | 'placement';

export const campaignTarget = ({
  storeIds,
  productIds,
}: {
  productIds: string[];
  storeIds: string[];
}): CampaignTarget => {
  if (productIds.length > 0) return 'sku';
  if (storeIds.length > 0) return 'store';

  return 'placement';
};

export const minBidForCampaignType = ({
  campaignTarget,
  adType,
}: {
  adType: 'cpc' | 'cpm';
  campaignTarget: CampaignTarget;
}) => {
  const minimumBids = {
    cpc: {
      sku: MIN_CPC_SKU_TARGETED_BID,
      store: MIN_CPC_STORE_TARGETED_BID,
      placement: MIN_CPC_PLACEMENT_TARGETED_BID,
    },
    cpm: {
      sku: MIN_CPM_SKU_TARGETED_BID,
      store: MIN_CPM_STORE_TARGETED_BID,
      placement: MIN_CPM_PLACEMENT_TARGETED_BID,
    },
  } as const;
  return minimumBids[adType][campaignTarget];
};

import { useContext } from 'react';

import { useBrandsNavigate } from '@jane/brands/hooks';
import { NextProductIdContext } from '@jane/catalog-cms/providers';

/**
 * This hook returns a function that when called will navigate to the edit
 * product page of the next product in the products list, if there is a next
 * product to navigate to. Otherwise it will navigate to the product list page.
 */
export const useGoToNextProduct = () => {
  const context = useContext(NextProductIdContext);
  const nav = useBrandsNavigate();

  if (context === undefined) {
    throw new Error(
      'useUpdateNextProductId must be a child of NextProductIdProvider.'
    );
  }

  return () => {
    if (context.nextProductId) {
      context.setNextProductId(undefined);
      nav.editProduct(context.nextProductId);
      return;
    }

    nav.productList();
  };
};

import { useState } from 'react';

import type { ModalProps } from '@jane/shared/reefer';
import { Modal } from '@jane/shared/reefer';

import { ConfirmChangeModal } from './ConfirmChangeModal';

export interface ConfirmDiscardWrapperProps
  extends Omit<ModalProps, 'onRequestClose'> {
  children: React.ReactChild;
  hasChanges: boolean;
  onCloseDiscardChanges?: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  showDiscardChanges?: boolean;
}

export const ConfirmDiscardWrapper = ({
  children,
  hasChanges,
  open,
  setOpen,
  showDiscardChanges,
  onCloseDiscardChanges,
  ...modalProps
}: ConfirmDiscardWrapperProps) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const onRequestClose = () => {
    if (hasChanges) {
      setConfirmationModalOpen(true);
    } else {
      setOpen(false);
    }
  };

  const closeBothModals = () => {
    setConfirmationModalOpen(false);
    setOpen(false);
    onCloseDiscardChanges && onCloseDiscardChanges();
  };

  const handleCancel = () => {
    setConfirmationModalOpen(false);
    onCloseDiscardChanges && onCloseDiscardChanges();
  };

  return (
    <>
      <Modal
        open={open}
        onRequestClose={onRequestClose}
        appId="root"
        {...modalProps}
      >
        {children}
      </Modal>
      <ConfirmChangeModal
        open={confirmationModalOpen || !!showDiscardChanges}
        onConfirm={closeBothModals}
        onCancel={handleCancel}
        appId={modalProps.parentId || 'parent'}
        title="Discard Changes?"
        subtitle="You will lose any unsaved changes."
        confirmLabel="Discard"
        confirmButtonVariant="destructive"
      />
    </>
  );
};

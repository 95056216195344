import { useMemo } from 'react';

import { type Store } from './StoreSelectModal';
import { storeIdConversions } from './storeIdConversions';

interface Opts {
  searchFilter: string;
  stateFilter: string;
  stores: Store[];
  typeFilter: string;
}

export const useFilteredStores = ({
  stores,
  searchFilter,
  typeFilter,
  stateFilter,
}: Opts) =>
  useMemo(() => {
    let storesForUser = stores?.filter(
      (store) =>
        store.name.toLowerCase().includes(searchFilter) ||
        store.city?.toLowerCase().includes(searchFilter) ||
        storeIdConversions.asString(store.id) === searchFilter
    );

    if (typeFilter && typeFilter !== 'All types') {
      const recreationPredicate = typeFilter === 'Recreational';

      storesForUser = storesForUser?.filter(
        (store) => store.recreational === recreationPredicate
      );
    }
    if (stateFilter) {
      storesForUser = storesForUser?.filter((store) =>
        store.state?.includes(stateFilter)
      );
    }
    return storesForUser || [];
  }, [stores, searchFilter, typeFilter, stateFilter]);

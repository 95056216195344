import { STATUS_ENUM } from '@jane/catalog-cms/data-access';
import { useProductFilters } from '@jane/catalog-cms/hooks';

import Filters from '../Filters';

const STATUS_OPTIONS = STATUS_ENUM.map((val) => ({
  label: val,
  value: val.toLowerCase().replace(/\s+/g, '-'),
}));

export const Status = () => {
  const { filters, updateFilters } = useProductFilters();

  return (
    <Filters.Multiselect
      label="Status"
      options={STATUS_OPTIONS}
      currentValue={filters.status || []}
      handleChange={(filterValue) => updateFilters('status', filterValue)}
    />
  );
};
